import { FC } from 'react'
import ReactDatePicker, { CalendarContainer, DatePickerProps } from 'react-datepicker'
import classNames from 'classnames'

import { useSessionLanguage } from '../context/SessionSettingsContext'
import { dateFnsLocales } from '../locale/Locale'
import { CalendarHeader } from './CalendarHeader'

import styles from './Calendar.module.scss'

export const YEAR_ITEM_NUMBER = 12

export const Calendar: FC<DatePickerProps> = (props) => {
  const { showMonthYearPicker, showYearPicker, selectsRange } = props

  const locale = useSessionLanguage()

  return (
    <ReactDatePicker
      {...props}
      locale={dateFnsLocales[locale]}
      calendarContainer={({ children }) => {
        return (
          <CalendarContainer className={styles.calendarContainer}>{children}</CalendarContainer>
        )
      }}
      inline
      calendarClassName={styles.customHeader}
      weekDayClassName={() => styles.weekDay}
      monthClassName={() => styles.month}
      yearClassName={() => styles.year}
      yearItemNumber={YEAR_ITEM_NUMBER}
      dayClassName={() => classNames(styles.day, selectsRange && styles.dayRange)}
      calendarStartDay={1}
      renderCustomHeader={(props) => {
        return (
          <CalendarHeader
            showMonthYearPicker={showMonthYearPicker}
            showYearPicker={showYearPicker}
            {...props}
          />
        )
      }}
    />
  )
}
