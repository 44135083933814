import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextH1 } from '../../ui/Typography/Typography'

import styles from './PendingClassificationTest.module.scss'

export const PendingClassificationTest = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.card}>
      <TextH1>{t('Profile.Classification Review Pending')}</TextH1>
      <p>{t('Profile.Your application will be reviewed within 48 hours')}</p>
    </div>
  )
}
