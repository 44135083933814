import { IconProps } from '../IconProps'

export const JapanFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 79'
      fill='none'
    >
      <g clipPath='url(#clip0_394_1095)'>
        <path
          d='M39 79C60.5391 79 78 61.3153 78 39.5C78 17.6848 60.5391 0 39 0C17.4609 0 0 17.6848 0 39.5C0 61.3153 17.4609 79 39 79Z'
          fill='#F0F0F0'
        />
        <path
          d='M39 56.6739C48.3648 56.6739 55.9565 48.9849 55.9565 39.5C55.9565 30.0152 48.3648 22.3262 39 22.3262C29.6352 22.3262 22.0436 30.0152 22.0436 39.5C22.0436 48.9849 29.6352 56.6739 39 56.6739Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_1095'>
          <rect width='78' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
