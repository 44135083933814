import React, { useRef } from 'react'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { dateAsUTC } from '../../utils/date.utils'
import { DatePicker, DatePickerValue } from '../datePicker/DatePicker'
import { DeepNonNullable } from '../types/DeepNonNullable'
import type { DeepRequired } from '../types/DeepRequired'
import type { TypedFieldProps } from '../types/TypedFieldProps'
import type { ValidateField } from '../types/ValidateField'
import { getFieldValid } from './helpers'

import styles from './FormField.module.scss'

type FieldConfig = Omit<FormikFieldConfig, 'name'>
interface FormFieldProps extends FieldConfig {
  namePrefix?: string
  name: string
  helpText?: string
  highlighted?: boolean
  value?: DatePickerValue
  onChange?(value: string | undefined | null): void
  onClick?(): void
  maxDate?: Date
  showYearDropdown?: boolean
}

export function RawFormField<FormValues>(
  props: FormFieldProps & Omit<TypedFieldProps<FormValues>, 'name'>
) {
  const { name = '', className, onChange, ...inputProps } = props

  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const value: DatePickerValue = props?.value ? props.value : getIn(context.values, name)
  const error = getIn(context.errors, name)
  const touched = getIn(context.touched, name)

  const isValid = getFieldValid(error, touched)

  const handleFieldChange = (date: Date | undefined | null) => {
    if (!date) {
      return
    }
    const isoDate = dateAsUTC(date).toISOString()
    if (onChange) {
      onChange(isoDate)
    } else {
      context.setFieldValue(name, isoDate || null)
    }
  }

  return (
    <div className={classNames('field', styles.field)} ref={fieldRef}>
      <div className={classNames('control')}>
        <DatePicker
          {...inputProps}
          value={value}
          error={!isValid ? error : undefined}
          onChange={handleFieldChange}
        />
      </div>
    </div>
  )
}

export function createFormDatePickerField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ) {
    return <RawFormField {...props} />
  }
}
