import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { useResizeEffect } from '../../../../hooks/useResizeEffect'
import { useWindowResize } from '../../../../utils/domUtils'

import styles from './HorizontalScroll.module.scss'

interface HorizontalScrollProps {
  className?: string
  innerClassName?: string
  containerClassName?: string
}

export const HorizontalScroll: FC<PropsWithChildren<HorizontalScrollProps>> = ({
  className,
  children,
  innerClassName,
  containerClassName,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const scrollContainerInnerRef = useRef<HTMLDivElement>(null)

  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false)
  const [hasScrolledToStart, setHasScrolledToStart] = useState(true)
  const [isScrollable, setIsScrollable] = useState(false)

  useWindowResize()

  const updateScrollableState = useCallback((): void => {
    if (scrollContainerRef.current && scrollContainerInnerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth
      const innerWidth = scrollContainerInnerRef.current.clientWidth

      setIsScrollable(innerWidth > containerWidth)
    }
  }, [])

  useResizeEffect(updateScrollableState)

  useEffect(() => {
    if (scrollContainerRef.current && scrollContainerInnerRef.current) {
      const scrollContainer = scrollContainerRef.current

      const handleScroll = () => {
        setHasScrolledToEnd(
          scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth
        )
        setHasScrolledToStart(scrollContainer.scrollLeft === 0)
      }

      scrollContainer.addEventListener('scroll', handleScroll)

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasScrolledToEnd])

  useEffect(() => {
    updateScrollableState()
  }, [updateScrollableState])

  return (
    <div
      className={classNames(
        styles.scrollContainerWrapper,
        className,
        isScrollable && styles.containerScrollable,
        isScrollable && !hasScrolledToEnd && styles.scrollContainerFadeEnd,
        isScrollable && !hasScrolledToStart && styles.scrollContainerFadeStart
      )}
    >
      <div
        className={classNames(styles.scrollContainer, containerClassName)}
        ref={scrollContainerRef}
      >
        <div
          className={classNames(styles.scrollContainerInner, innerClassName)}
          ref={scrollContainerInnerRef}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
