import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function UserCircledIcon(props: IconProps): JSX.Element {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='38.5'
        height='38.5'
        rx='19.25'
        stroke='var(--text-color)'
        strokeWidth='1.5'
      />
      <circle cx='20' cy='15' r='4' stroke='var(--text-color)' strokeWidth='1.75' />
      <path
        d='M28 29C28 29 27.5137 27.481 26.8571 25.3334C26.2006 23.1857 22.1012 23 20 23C17.8988 23 13.7994 23.1857 13.1429 25.3333C12.4863 27.481 12 29 12 29'
        stroke='var(--text-color)'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
    </svg>
  )
}
