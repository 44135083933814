import { IconProps } from '../IconProps'

export const ChinaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_554)'>
        <path
          d='M39 79C60.5391 79 78 61.3153 78 39.5C78 17.6848 60.5391 0 39 0C17.4609 0 0 17.6848 0 39.5C0 61.3153 17.4609 79 39 79Z'
          fill='#D80027'
        />
        <path
          d='M21.3434 24.0395L24.7102 34.5316H35.6027L26.7973 41.0275L30.1641 51.5197L21.3434 45.0393L12.5227 51.5197L15.9047 41.0275L7.08398 34.5316H17.9766L21.3434 24.0395Z'
          fill='#FFDA44'
        />
        <path
          d='M46.2363 61.1787L43.6617 57.9693L39.8531 59.466L42.0621 55.9943L39.4875 52.7695L43.4332 53.8342L45.6574 50.3625L45.8707 54.4977L49.8316 55.5623L46.0078 57.0436L46.2363 61.1787Z'
          fill='#FFDA44'
        />
        <path
          d='M51.3551 51.7666L52.5738 47.8166L49.2375 45.425L53.3203 45.3633L54.5238 41.4133L55.8492 45.3324L59.932 45.2861L56.6566 47.7549L57.9668 51.674L54.6305 49.2824L51.3551 51.7666Z'
          fill='#FFDA44'
        />
        <path
          d='M58.2563 28.9924L56.4586 32.7109L59.3836 35.5963L55.3465 35.01L53.5488 38.7131L52.8481 34.6397L48.7957 34.0533L52.4215 32.1246L51.7207 28.0358L54.6457 30.9211L58.2563 28.9924Z'
          fill='#FFDA44'
        />
        <path
          d='M46.3429 17.7287L46.0383 21.8484L49.8316 23.4068L45.8554 24.3943L45.566 28.5295L43.4179 25.0115L39.4418 25.999L42.0773 22.8359L39.914 19.3334L43.7074 20.8918L46.3429 17.7287Z'
          fill='#FFDA44'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_554'>
          <rect width='78' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
