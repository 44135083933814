import { EntitySettings, useEntitySettings } from '../global/context/EntityContext'
import { usePublicDomainsReadContext } from '../global/context/PublicDomainsContext'
import {
  isOverrideLocaleForENorTR,
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import { Locale, OverrideLocaleForZHLanguage } from '../global/locale/Locale'
import { TickmillCompaniesEnum } from '../utils/companyName.utils'

export const useHomePageUrl = (): string => {
  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()

  const [entity] = useEntitySettings()

  if (domains?.homePageUrl) {
    return domains.homePageUrl
  }

  if (isOverrideLocaleForENorTR(locale)) {
    return getHomePageByEntity(entity)
  }

  return getHomePageByLang(entity, locale)
}

const getHomePageByEntity = (entity: EntitySettings) => {
  switch (entity.entity) {
    case TickmillCompaniesEnum.TICKMILL_UK:
      return 'https://tickmill.com/uk'
    case TickmillCompaniesEnum.TICKMILL_EU:
      return 'https://tickmill.com/eu'
    case TickmillCompaniesEnum.GENERIC:
      if (window.location.hostname.endsWith('.com')) {
        return 'https://www.tickmill.com'
      }
      return 'https://www.tickmill.net'
    case TickmillCompaniesEnum.TICKMILL_PA:
    case TickmillCompaniesEnum.TICKMILL_SC:
    case TickmillCompaniesEnum.TICKMILL_AS:
      return 'https://www.tickmill.com'
    default:
      return 'https://www.tickmill.com'
  }
}

const getHomePageByLang = (
  entity: EntitySettings,
  locale: Locale | OverrideLocaleForZHLanguage
) => {
  const homePageUrl = getHomePageByEntity(entity)
  const zhToCNLanguage = overrideForZHToCNLanguage(locale)
  return `${homePageUrl}/${zhToCNLanguage}`
}
