import { IconProps } from './IconProps'

export const TransactionTransferIcon: React.FC<IconProps> = (props) => {
  const { className, size = 40 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        style={{
          fill: 'var(--icon-transfer)',
        }}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20ZM24.375 18.125C24.7202 18.125 25 17.8452 25 17.5C25 17.1548 24.7202 16.875 24.375 16.875L17.1339 16.875L17.9419 16.0669C18.186 15.8229 18.186 15.4271 17.9419 15.1831C17.6979 14.939 17.3021 14.939 17.0581 15.1831L15.1831 17.0581C14.939 17.3021 14.939 17.6979 15.1831 17.9419L17.0581 19.8169C17.3021 20.061 17.6979 20.061 17.9419 19.8169C18.186 19.5729 18.186 19.1771 17.9419 18.9331L17.1339 18.125H24.375ZM22.9419 24.8169L24.8169 22.9419C25.061 22.6979 25.061 22.3021 24.8169 22.0581L22.9419 20.1831C22.6979 19.939 22.3021 19.939 22.0581 20.1831C21.814 20.4271 21.814 20.8229 22.0581 21.0669L22.8661 21.875H15.625C15.2798 21.875 15 22.1548 15 22.5C15 22.8452 15.2798 23.125 15.625 23.125H22.8661L22.0581 23.9331C21.814 24.1771 21.814 24.5729 22.0581 24.8169C22.3021 25.061 22.6979 25.061 22.9419 24.8169Z'
        style={{
          fill: 'var(--always-white)',
        }}
      />
    </svg>
  )
}
