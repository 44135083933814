import { useMemo } from 'react';
import { useProductReadContext } from '../global/context/ProductContext';
import { RouteConfig } from '../routing/RenderRoutes';
import { useAccountReadContext } from '../utils/AccountContextContext';
import { EarningsCalendar } from './EarningsCalendar/EarningsCalendar';
import { EconomicCalendar } from './EconomicCalendar/EconomicCalendar';
import { MarketDataVendors } from './MarketDataVendors/MarketDataVendors';
import { MarketSentiment } from './MarketSentiment/MarketSentiment';
import { PlatformVendorsPage } from './PlatformVendors/PlatformVendorsPage';
import { PlatformsPage } from './Platforms/PlatformsPage';
import { Tools } from './Tools'
import { ToolsETD } from './ToolsETD';

export const useToolsRoutes = (): RouteConfig[] => {
  const visible = useToolsRoutesConditions()
  const { isDefaultCFDProductType } = useProductReadContext()

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'tools',
        outlet: <Tools />,
        condition: visible.isToolsVisible && isDefaultCFDProductType(),
        children: [
          {
            path: 'platforms/*',
            element: <PlatformsPage />,
          },
          {
            path: 'metatrader',
            element: <PlatformsPage />,
          },
					{
						path: 'earnings-calendar',
						element: <EarningsCalendar />,
					},
					{
						path: 'economic-calendar',
						element: <EconomicCalendar />,
					},
					{
						path: 'market-sentiment',
						element: <MarketSentiment />,
					},
        ],
      },
      {
        path: 'tools',
        outlet: <ToolsETD />,
        condition: visible.isToolsVisible && !isDefaultCFDProductType(),
        children: [
          {
            path: 'platform-vendors',
            element: <PlatformVendorsPage />,
          },
          {
            path: 'market-data-vendors',
            element: <MarketDataVendors />,
          },
        ],
      },
    ],
    [isDefaultCFDProductType, visible.isToolsVisible]
  )
}

interface RoutesConditions {
  isToolsVisible: boolean
}

export const useToolsRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isToolsVisible = useMemo(
    () =>
      isDefaultCFDProductType()
        ? !!account?.visibilityConfiguration.hasTraderRoom
        : !!account?.visibilityConfiguration.hasEtdTraderRoom,
    [
      account?.visibilityConfiguration.hasEtdTraderRoom,
      account?.visibilityConfiguration.hasTraderRoom,
      isDefaultCFDProductType,
    ]
  )

  return {
    isToolsVisible,
  }
}
