import { IconProps } from '../IconProps'

export const AustraliaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 79 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_137)'>
        <path
          d='M79 39C79 60.539 61.3151 78 39.5 78C17.6849 78 0 60.539 0 39C0 39.0091 39.5 0.00441797 39.5 0C61.3151 0 79 17.461 79 39Z'
          fill='#0052B4'
        />
        <path
          d='M39.5002 0C39.498 0 39.4956 0.000157776 39.4934 0.000157776L39.5002 0Z'
          fill='#F0F0F0'
        />
        <path
          d='M39.3943 39H39.5C39.5 38.9646 39.5 38.9308 39.5 38.8956C39.4648 38.9305 39.4296 38.9653 39.3943 39Z'
          fill='#F0F0F0'
        />
        <path
          d='M39.5 20.3482C39.5 13.4859 39.5 8.98917 39.5 0.000427246H39.4934C17.6812 0.00393115 0 17.4633 0 39.0003H20.6087V27.5422L32.2136 39.0003H39.3945C39.4298 38.9655 39.465 38.9308 39.5002 38.8959C39.5002 36.2684 39.5002 33.924 39.5002 31.8064L27.895 20.3482H39.5Z'
          fill='#F0F0F0'
        />
        <path
          d='M19.9837 5.08691C13.8058 8.5602 8.66991 13.6311 5.1521 19.7308V39H15.4565V15.261V15.2609H39.5C39.5 12.0521 39.5 8.99514 39.5 5.08691H19.9837Z'
          fill='#D80027'
        />
        <path
          d='M39.4999 34.2041L25.4662 20.3481H20.6088C20.6088 20.3479 20.6088 20.3481 20.6088 20.3481L39.4998 39H39.4999C39.4999 39 39.4999 35.6934 39.4999 34.2041Z'
          fill='#D80027'
        />
        <path
          d='M23.8226 45.7827L25.9905 50.2582L30.8859 49.1411L28.6936 53.605L32.6304 56.6874L27.7288 57.7782L27.7425 62.7391L23.8226 59.6354L19.9029 62.7391L19.9166 57.7782L15.0149 56.6874L18.9518 53.605L16.7592 49.1411L21.6549 50.2582L23.8226 45.7827Z'
          fill='#F0F0F0'
        />
        <path
          d='M59.1395 54.2609L60.2234 56.4987L62.6712 55.94L61.5749 58.172L63.5434 59.7133L61.0926 60.2587L61.0994 62.7391L59.1395 61.1872L57.1796 62.7391L57.1864 60.2587L54.7357 59.7133L56.7041 58.172L55.608 55.94L58.0556 56.4987L59.1395 54.2609Z'
          fill='#F0F0F0'
        />
        <path
          d='M49.056 30.5218L50.1398 32.7597L52.5876 32.2009L51.4915 34.4329L53.4598 35.9742L51.0091 36.5196L51.0158 39L49.056 37.4481L47.0962 39L47.1028 36.5196L44.6521 35.9742L46.6205 34.4329L45.5243 32.2009L47.9721 32.7597L49.056 30.5218Z'
          fill='#F0F0F0'
        />
        <path
          d='M59.1395 16.9565L60.2234 19.1944L62.6712 18.6358L61.5751 20.8678L63.5433 22.4089L61.0926 22.9544L61.0994 25.4349L59.1395 23.8829L57.1796 25.4349L57.1864 22.9544L54.7357 22.4089L56.7039 20.8678L55.608 18.6358L58.0556 19.1944L59.1395 16.9565Z'
          fill='#F0F0F0'
        />
        <path
          d='M67.9474 27.1305L69.0312 29.3684L71.4789 28.8096L70.3828 31.0416L72.3512 32.5828L69.9003 33.1284L69.9071 35.6087L67.9474 34.0569L65.9875 35.6087L65.9942 33.1284L63.5435 32.5828L65.5118 31.0416L64.4157 28.8096L66.8633 29.3684L67.9474 27.1305Z'
          fill='#F0F0F0'
        />
        <path
          d='M61.6492 39L62.5017 41.5908H65.2609L63.0286 43.192L63.8814 45.7826L61.6492 44.1815L59.4172 45.7826L60.2697 43.192L58.0376 41.5908H60.7966L61.6492 39Z'
          fill='#F0F0F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_137'>
          <rect width='79' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
