import { FC } from 'react'

import { IconProps } from '../IconProps'

export const TMGrayLogo: FC<IconProps> = (props) => {
  const { className, width = 28.5, height = 33 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width={width}
      height={height}
      viewBox='0 0 29 33'
      fill='none'
    >
      <path
        d='M7.95421 12.0121C7.6633 12.2061 7.42088 12.6424 7.42088 12.9818H7.51784V18.9939L0.245117 23.1636V9.58788C0.245117 8.71515 0.87542 7.64849 1.60269 7.21212L13.2391 0.327273C13.9663 -0.109091 15.1785 -0.109091 15.9057 0.327273L27.5421 7.21212C27.5906 7.21212 27.6391 7.26061 27.6875 7.30909L20.3178 11.5758L15.0815 8.52121C14.7421 8.32727 14.2572 8.32727 13.9663 8.52121L7.95421 12.0121Z'
        style={{
          fill: 'var(--icon-tertiary)',
        }}
      />
      <path
        d='M21.5787 19.9152V13.9031L28.7544 9.7334V23.3092C28.7544 24.1819 28.1241 25.2486 27.3969 25.6849L15.7605 32.5698C15.0332 33.0061 13.8211 33.0061 13.0938 32.5698L1.45747 25.6849C1.40899 25.6849 1.3605 25.6364 1.31202 25.588L8.68171 21.3213L13.9181 24.3758C14.2575 24.5698 14.7423 24.5698 15.0332 24.3758L21.0453 20.8849C21.3363 20.691 21.5787 20.2546 21.5787 19.9152Z'
        style={{
          fill: 'var(--icon-tertiary)',
        }}
      />
    </svg>
  )
}
