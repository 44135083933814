import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfettiIcon } from '../../../icons/ConfettiIcon'
import { MedalIcon } from '../../../icons/MedalIcon'
import { UltimateIcon } from '../../../icons/TierIcons'
import { Text, TextH4 } from '../../../ui/Typography/Typography'
import { useWindowResize } from '../../../utils/domUtils'

import styles from './MaxLevelBanner.module.scss'

export const MaxLevelBanner: FC = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  return (
    <div className={styles.maxLevelBanner}>
      <ConfettiIcon className={styles.confetti} width={110} />
      <UltimateIcon className={styles.icon} size={56} />
      <MedalIcon className={styles.medal} size={isMobile ? 56 : 69} />
      <div className={styles.textWrapper}>
        <TextH4 className={styles.title}>{t('IB.Dashboard.Congratulations! 🎉')}</TextH4>
        <Text className={styles.text}>{t('IB.Dashboard.You reached the maximum level')}</Text>
      </div>
    </div>
  )
}
