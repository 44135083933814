import { IconProps } from './IconProps'

export const TransactionHistoryEmptyState: React.FC<IconProps> = (props) => {
  const { className, width = 710, height = 200 } = props

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 710 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <g clipPath='url(#clip0_597_12735)'>
          <rect
            width='710'
            height='56'
            rx='12'
            style={{ fill: 'var(--ib-transaction-history-skeleton-wrapper)' }}
            fillOpacity='0.7'
          />
          <g opacity='0.1'>
            <rect
              x='32'
              y='12'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='76'
                y='22.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <rect
              x='316'
              y='12'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='360'
                y='22.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <g opacity='0.4'>
              <rect
                x='636'
                y='22.5'
                width='34'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
        </g>
      </g>
      <g opacity='0.4'>
        <g clipPath='url(#clip1_597_12735)'>
          <rect
            y='72'
            width='710'
            height='56'
            rx='12'
            style={{ fill: 'var(--ib-transaction-history-skeleton-wrapper)' }}
            fillOpacity='0.6'
          />
          <g opacity='0.1'>
            <rect
              x='32'
              y='84'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='76'
                y='94.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <rect
              x='316'
              y='84'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='360'
                y='94.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <g opacity='0.4'>
              <rect
                x='636'
                y='94.5'
                width='34'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
        </g>
      </g>
      <g opacity='0.3'>
        <g clipPath='url(#clip2_597_12735)'>
          <rect
            y='144'
            width='710'
            height='56'
            rx='12'
            style={{ fill: 'var(--ib-transaction-history-skeleton-wrapper)' }}
            fillOpacity='0.5'
          />
          <g opacity='0.1'>
            <rect
              x='32'
              y='156'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='76'
                y='166.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <rect
              x='316'
              y='156'
              width='32'
              height='32'
              rx='8'
              style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
            />
            <g opacity='0.4'>
              <rect
                x='360'
                y='166.5'
                width='75'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
          <g opacity='0.1'>
            <g opacity='0.4'>
              <rect
                x='636'
                y='166.5'
                width='34'
                height='11'
                rx='5.5'
                style={{ fill: 'var(--ib-transaction-history-skeleton-box)' }}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_597_12735'>
          <rect width='710' height='56' rx='12' style={{ fill: 'var(--always-white)' }} />
        </clipPath>
        <clipPath id='clip1_597_12735'>
          <rect y='72' width='710' height='56' rx='12' style={{ fill: 'var(--always-white)' }} />
        </clipPath>
        <clipPath id='clip2_597_12735'>
          <rect y='144' width='710' height='56' rx='12' style={{ fill: 'var(--always-white)' }} />
        </clipPath>
      </defs>
    </svg>
  )
}
