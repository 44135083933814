import { IconProps, iconColor } from './IconProps'

export function DottedCalendarIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 5H7.2002C6.08009 5 5.51962 5 5.0918 5.21799C4.71547 5.40973 4.40973 5.71547 4.21799 6.0918C4 6.51962 4 7.08009 4 8.2002V9M8 5H16M8 5V3M16 5H16.8002C17.9203 5 18.4796 5 18.9074 5.21799C19.2837 5.40973 19.5905 5.71547 19.7822 6.0918C20 6.5192 20 7.07899 20 8.19691V9M16 5V3M4 9V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07899 21 7.19691 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V9M4 9H20M16 17H16.002L16.002 17.002L16 17.002V17ZM12 17H12.002L12.002 17.002L12 17.002V17ZM8 17H8.002L8.00195 17.002L8 17.002V17ZM16.002 13V13.002L16 13.002V13H16.002ZM12 13H12.002L12.002 13.002L12 13.002V13ZM8 13H8.002L8.00195 13.002L8 13.002V13Z'
        stroke={iconColor(props)}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
