import { IconProps } from '../IconProps'

export const PolandFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 78 79'
      fill='none'
      className={className}
    >
      <g clipPath='url(#clip0_394_1669)'>
        <path
          d='M39 79C60.5391 79 78 61.3153 78 39.5C78 17.6848 60.5391 0 39 0C17.4609 0 0 17.6848 0 39.5C0 61.3153 17.4609 79 39 79Z'
          fill='#F0F0F0'
        />
        <path d='M78 39.5C78 61.3151 60.539 79 39 79C17.461 79 0 61.3151 0 39.5' fill='#D80027' />
      </g>
      <defs>
        <clipPath id='clip0_394_1669'>
          <rect width='78' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
