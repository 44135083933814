import React from 'react'

import { Text, TextHero } from '../ui/Typography/Typography'

import styles from './Tools.module.scss'

interface Props {
  title: string
  description: string
}
export const AcquityHeader: React.FC<Props> = ({ title, description }) => {
  return (
    <div className={styles.aquitySection}>
      <hr className={styles.divider} />
      <TextHero>{title}</TextHero>
      <Text>{description}</Text>
      <hr className={styles.divider} />
    </div>
  )
}
