import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'

export const ONE_WEEK: [Date, Date] = [subDays(new Date(), 7), subDays(new Date(), 1)]
export const TWO_WEEKS: [Date, Date] = [subDays(new Date(), 14), subDays(new Date(), 1)]
export const ONE_MONTH: [Date, Date] = [subMonths(new Date(), 1), subDays(new Date(), 1)]
export const ONE_YEAR: [Date, Date] = [subDays(new Date(), 365), subDays(new Date(), 1)]
export const LAST_30_DAYS: [Date, Date] = [subDays(new Date(), 30), subDays(new Date(), 1)]
export const THIS_MONTH: [Date, Date] = [startOfMonth(new Date()), subDays(new Date(), 1)]
export const LAST_MONTH: [Date, Date] = [
  startOfMonth(subMonths(new Date(), 1)),
  endOfMonth(subMonths(new Date(), 1)),
]
export const LAST_YEAR: [Date, Date] = [
  startOfYear(subYears(new Date(), 1)),
  endOfYear(subYears(new Date(), 1)),
]
export const DEFAULT_DATE_RANGE: [Date, Date] = ONE_WEEK
