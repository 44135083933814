import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { BlueInfoIcon } from '../../icons/BlueInfoIcon'
import { CircleSuccessIcon } from '../../icons/CircleSuccessIcon'
import { CircleWarningIcon } from '../../icons/CircleWarningIcon'
import { InfoNumberIcon } from '../../icons/InfoNumberIcon'
import { PlusIconPrimary } from '../../icons/PlusIconPrimary'
import { RedInfoIcon } from '../../icons/RedInfoIcon'
import { RedXIcon } from '../../icons/RedXIcon'
import { isAccountPendingDocumentVerificationStatusType } from '../../model/AccountDetailedStatus'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import {
  DocumentCategoryStatusDto,
  isAdditionalDocumentsGeneralStatus,
  isDocumentsApprovedGeneralStatus,
  isDocumentsRequiredGeneralStatus,
  isMoreDocumentsRequiredGeneralStatus,
  isRejectedDocumentsGeneralStatus,
  isVerificationPendingGeneralStatus,
} from '../../model/DocumentGeneralStatusDto'
import { DocumentStatus } from '../../model/DocumentStatus'
import { NameDto } from '../../model/NameDto'
import { TextH3, TextSmall } from '../../ui/Typography/Typography'
import {
  isAccountCompanyInfoDocumentAllowedUpload,
  isAccountDocumentManagement1MonthExpired,
  isAccountDocumentManagement2MonthExpired,
} from '../../utils/AccountAccess/accountDocumentStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { DocumentManagementCategoryModal } from './DocumentManagementCategoryModal'

import styles from './UploadDocuments.module.scss'

const getStatusForDocumentCategory = (
  documents: DocumentCategoryStatusDto[] = [],
  documentCategory: DocumentCategoryType,
  documentStatus: DocumentStatus
): boolean => {
  return documents.some(
    (document) => document.category.id === documentCategory && document.status.id === documentStatus
  )
}

export const UploadDocuments: FC = () => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)

  const navigate = useNavigate()

  const companyTypeCallback = useCallback(async () => {
    return apiClient.getGeneralStatus({ clientId: account?.id, languageId: locale })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: generalStatusData } = useFetchOne(companyTypeCallback)

  const [isIdentificationModalOpen, setIdentificationModalOpen] = useState(false)
  const [isProofOfAddressModalOpen, setProofOfAddressModalOpen] = useState(false)
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false)
  const [isAdditionalDocumentsModalOpen, setAdditionalDocumentsModalOpen] = useState(false)
  const [isPaymentsDocumentsModalOpen, setPaymentsDocumentsModalOpen] = useState(false)

  const handleIdentificationUploadClick = () => {
    if (account?.visibilityConfiguration?.hasNCI) {
      navigate('/profile/document-management/identification')
    }

    if (!account?.visibilityConfiguration?.hasNCI) {
      setIdentificationModalOpen(true)
    }
  }

  const handleIdentificationDocumentCategory = (
    documentCategory: NameDto,
    documentCategoryType: NameDto
  ) => {
    navigate('/profile/document-management/identification', {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }

  const handleProofOfAddressDocumentCategory = (
    documentCategory: NameDto,
    documentCategoryType: NameDto
  ) => {
    navigate('/profile/document-management/proof-of-address', {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }

  const handleAdditionalDocumentCategory = (
    documentCategory: NameDto,
    documentCategoryType: NameDto
  ) => {
    navigate('/profile/document-management/additional', {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }

  const handlePaymentsCategory = (documentCategory: NameDto, documentCategoryType: NameDto) => {
    navigate('/profile/document-management/payments', {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }

  const handleCompanyDocumentCategory = (
    documentCategory: NameDto,
    documentCategoryType: NameDto
  ) => {
    navigate('/profile/document-management/company-proof', {
      state: {
        documentCategory,
        documentCategoryType,
      },
    })
  }

  const handleIdentificationModalClose = () => setIdentificationModalOpen(false)
  const handleAdditionalDocumentsModalClose = () => setAdditionalDocumentsModalOpen(false)
  const handleCompanyDocumentsModalClose = () => setCompanyModalOpen(false)
  const handleProofOfAddressModalClose = () => setProofOfAddressModalOpen(false)
  const handlePaymentsModalClose = () => setPaymentsDocumentsModalOpen(false)
  const handleCompanyProofUploadClick = () => setCompanyModalOpen(true)
  const handleAdditionalDocumentsUploadClick = () => setAdditionalDocumentsModalOpen(true)
  const handleProofOfAddressUploadClick = () => setProofOfAddressModalOpen(true)
  const handlePaymentsUploadClick = () => setPaymentsDocumentsModalOpen(true)

  const isCompanyDocumentApproved = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Corporate,
    DocumentStatus.Approved
  )

  const isCompanyDocumentPending = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Corporate,
    DocumentStatus.PendingApproval
  )

  const isAdditionalDocumentApproved = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Additional,
    DocumentStatus.Approved
  )

  const isAdditionalDocumentPending = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Additional,
    DocumentStatus.PendingApproval
  )

  const isPersonalDocumentApproved = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Personal,
    DocumentStatus.Approved
  )

  const isPersonalDocumentPending = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Personal,
    DocumentStatus.PendingApproval
  )

  const isAddressDocumentApproved = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Address,
    DocumentStatus.Approved
  )

  const isAddressDocumentPending = getStatusForDocumentCategory(
    generalStatusData?.categoryStatuses,
    DocumentCategoryType.Address,
    DocumentStatus.PendingApproval
  )

  const hasActivePersonalDocument = generalStatusData?.categoryStatuses.some(
    (document) =>
      document.category.id === DocumentCategoryType.Personal &&
      (isPersonalDocumentApproved || isPersonalDocumentPending)
  )

  const hasActiveAddressDocument = generalStatusData?.categoryStatuses.some(
    (document) =>
      document.category.id === DocumentCategoryType.Address &&
      (isAddressDocumentApproved || isAddressDocumentPending)
  )

  const hasActiveCorporateDocument = generalStatusData?.categoryStatuses.some(
    (document) =>
      document.category.id === DocumentCategoryType.Corporate &&
      (isCompanyDocumentApproved || isCompanyDocumentPending)
  )

  const isCompanyDocumentUploadAllowed = isAccountCompanyInfoDocumentAllowedUpload(account)

  const isHeaderStatusPending =
    generalStatusData?.categoryStatuses.some(
      (document) => document.status.id === DocumentStatus.PendingApproval
    ) ?? false

  const documentCategories = new Set<DocumentCategoryType>()
  generalStatusData?.categoryStatuses.map((document) =>
    documentCategories.add(document.category.id)
  )

  const someDocumentsPending =
    generalStatusData?.categoryStatuses.some(
      (document) => document.status.id === DocumentStatus.PendingApproval
    ) || isAccountPendingDocumentVerificationStatusType(account?.status.id || -1)

  const requiresAdditionalDocuments =
    generalStatusData?.categoryStatuses.some(
      (document) => document.category.id === DocumentCategoryType.Additional
    ) ?? false

  const isAddressPending = isAddressDocumentPending && !isAddressDocumentApproved
  const isPersonalPending = isPersonalDocumentPending && !isPersonalDocumentApproved
  const isAdditionalPending = isAdditionalDocumentPending && !isAdditionalDocumentApproved
  const isCompanyPending = isCompanyDocumentPending && !isCompanyDocumentApproved

  const requiredDocuments = generalStatusData?.categoryStatuses
    ? generalStatusData.categoryStatuses.length
    : 2

  const hasSufficientDocuments = (): boolean => {
    const isAddressAndPersonalDocsConditionMet =
      (isAddressDocumentApproved || isAddressDocumentPending) &&
      (isPersonalDocumentApproved || isPersonalDocumentPending)

    if (requiredDocuments === 2 && isAddressAndPersonalDocsConditionMet) {
      return false
    }

    const isAdditionalDocConditionMet = isAdditionalDocumentPending || isAdditionalDocumentApproved
    const isCompanyDocConditionMet = isCompanyDocumentPending || isCompanyDocumentApproved

    if (requiredDocuments === 3) {
      if (
        requiresAdditionalDocuments &&
        isAddressAndPersonalDocsConditionMet &&
        isAdditionalDocConditionMet
      ) {
        return false
      }

      if (
        isCompanyDocumentUploadAllowed &&
        isAddressAndPersonalDocsConditionMet &&
        isCompanyDocConditionMet
      ) {
        return false
      }
    }

    if (
      requiredDocuments === 4 &&
      isCompanyDocumentUploadAllowed &&
      requiresAdditionalDocuments &&
      isAddressAndPersonalDocsConditionMet &&
      isAdditionalDocConditionMet &&
      isCompanyDocConditionMet
    ) {
      return false
    }

    if (isCompanyDocumentUploadAllowed) {
      if (requiredDocuments <= 2 && someDocumentsPending) {
        return true
      }
      if (requiredDocuments === 3 && someDocumentsPending) {
        return true
      }
      if (requiredDocuments === 4 && someDocumentsPending && requiresAdditionalDocuments) {
        return true
      }
    } else {
      if (requiredDocuments <= 1 && someDocumentsPending) {
        return true
      }
      if (
        requiredDocuments === 2 &&
        ((isPersonalPending && !isAddressPending && !isAdditionalPending && !isCompanyPending) ||
          (!isPersonalPending && isAddressPending && !isAdditionalPending && !isCompanyPending) ||
          (!isPersonalPending && !isAddressPending && isAdditionalPending && !isCompanyPending) ||
          (!isPersonalPending && !isAddressPending && !isAdditionalPending && isCompanyPending))
      ) {
        return true
      }
      if (requiredDocuments === 2 && someDocumentsPending) {
        return true
      }
      if (requiredDocuments === 3 && someDocumentsPending) {
        return true
      }
    }
    return false
  }

  const isHeaderStatusRejected =
    generalStatusData?.categoryStatuses.some(
      (document) => document.status.id === DocumentStatus.Rejected
    ) ?? false

  const isRejectedWithApprovedDoc = () => {
    const hasRejectedDocument =
      generalStatusData?.categoryStatuses.some(
        (document) => document.status.id === DocumentStatus.Rejected
      ) ?? false

    const hasApprovedDocument =
      generalStatusData?.categoryStatuses.some(
        (document) => document.status.id === DocumentStatus.Approved
      ) ?? false

    return hasRejectedDocument && hasApprovedDocument
  }

  const isPendingWithApprovedDoc = () => {
    const hasPending =
      generalStatusData?.categoryStatuses.some(
        (document) => document.status.id === DocumentStatus.PendingApproval
      ) ?? false
    const hasApprovedDocument =
      generalStatusData?.categoryStatuses.some(
        (document) => document.status.id === DocumentStatus.Approved
      ) ?? false

    return hasPending && hasApprovedDocument
  }

  const isAddressLocked = hasActiveAddressDocument || (account?.isAddressInfoLocked ?? false)
  const isPaymentInfoLocked = account?.isPaymentInfoLocked ?? false
  const isPersonalLocked =
    !isAccountDocumentManagement2MonthExpired(account) &&
    (hasActivePersonalDocument || (account?.isPersonalInfoLocked ?? false))
  const isAdditionalLocked = account?.isAdditionalInfoLocked ?? true
  const isCorporateLocked = hasActiveCorporateDocument || (account?.isCompanyInfoLocked ?? true)

  const getDocumentUploadModalHandler = (id: number) => {
    switch (id) {
      case DocumentCategoryType.Personal:
        return handleIdentificationUploadClick
      case DocumentCategoryType.Address:
        return handleProofOfAddressUploadClick
      case DocumentCategoryType.Corporate:
        return handleCompanyProofUploadClick
      case DocumentCategoryType.Additional:
        return handleAdditionalDocumentsUploadClick
      case DocumentCategoryType.Payment:
        return handlePaymentsUploadClick
      default:
        return handleAdditionalDocumentsUploadClick
    }
  }

  const isLocked = (id: number) => {
    switch (id) {
      case DocumentCategoryType.Personal:
        return isPersonalLocked
      case DocumentCategoryType.Address:
        return isAddressLocked
      case DocumentCategoryType.Corporate:
        return isCorporateLocked
      case DocumentCategoryType.Additional:
        return isAdditionalLocked
      case DocumentCategoryType.Payment:
        return isPaymentInfoLocked
    }
  }

  return (
    <>
      {isIdentificationModalOpen && (
        <DocumentManagementCategoryModal
          documentCategoryType={DocumentCategoryType.Personal}
          onSelectOption={handleIdentificationDocumentCategory}
          onClose={handleIdentificationModalClose}
        />
      )}
      {isCompanyModalOpen && (
        <DocumentManagementCategoryModal
          documentCategoryType={DocumentCategoryType.Corporate}
          onSelectOption={handleCompanyDocumentCategory}
          onClose={handleCompanyDocumentsModalClose}
        />
      )}
      {isProofOfAddressModalOpen && (
        <DocumentManagementCategoryModal
          documentCategoryType={DocumentCategoryType.Address}
          onSelectOption={handleProofOfAddressDocumentCategory}
          onClose={handleProofOfAddressModalClose}
        />
      )}
      {isAdditionalDocumentsModalOpen && (
        <DocumentManagementCategoryModal
          documentCategoryType={DocumentCategoryType.Additional}
          onSelectOption={handleAdditionalDocumentCategory}
          onClose={handleAdditionalDocumentsModalClose}
        />
      )}
      {isPaymentsDocumentsModalOpen && (
        <DocumentManagementCategoryModal
          documentCategoryType={DocumentCategoryType.Payment}
          onSelectOption={handlePaymentsCategory}
          onClose={handlePaymentsModalClose}
        />
      )}
      <div className={styles.card}>
        <CardHeader generalStatus={generalStatusData?.generalStatus} />

        {generalStatusData?.categoryStatuses.map((document, index) => {
          return (
            <DocumentUpload
              text={t(document.category.title)}
              documentStatusId={document.status.id}
              documentIndex={index + 1}
              onClick={getDocumentUploadModalHandler(document.category.id)}
              isLocked={isLocked(document.category.id)}
              key={`documentUploadCategory-${document.category.id}`}
            />
          )
        })}

        <CardMessage
          isHeaderStatusPending={isHeaderStatusPending}
          isHeaderStatusRejected={isHeaderStatusRejected}
          hasSufficientDocuments={hasSufficientDocuments()}
          requiresAdditionalDocuments={requiresAdditionalDocuments}
          isRejectedWithApprovedDoc={isRejectedWithApprovedDoc()}
          isPendingWithApprovedDoc={isPendingWithApprovedDoc()}
          documentCategories={documentCategories}
        />
      </div>
    </>
  )
}

interface PersonalDocumentUploadProps {
  text: string
  onClick(): void
  documentStatusId: number
  documentIndex: number
  isLocked?: boolean
}

const DocumentUpload: FC<PersonalDocumentUploadProps> = ({
  text,
  documentStatusId,
  documentIndex,
  onClick,
  isLocked,
}) => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const isMobile = useWindowResize()

  const is1MonthExpired = isAccountDocumentManagement1MonthExpired(account)
  const is2MonthExpired = isAccountDocumentManagement2MonthExpired(account)

  const DocIcon = () => {
    if (documentStatusId === DocumentStatus.PendingApproval) {
      return <CircleSuccessIcon color={'warning'} />
    }
    if (documentStatusId === DocumentStatus.Approved) {
      if (is1MonthExpired || is2MonthExpired) {
        return <InfoNumberIcon number={documentIndex} />
      }
      return <CircleSuccessIcon color={'successNew'} />
    }
    if (documentStatusId === DocumentStatus.Rejected) {
      return <RedXIcon />
    }
    return <InfoNumberIcon number={documentIndex} />
  }

  const DocButton = () => {
    if (isLocked && !is1MonthExpired && !is2MonthExpired) {
      return null
    }

    if (isMobile) {
      return (
        <IconButton onClick={onClick}>
          <PlusIconPrimary />
        </IconButton>
      )
    }

    return (
      <Button size='S' appearance='primary' className={styles.button} onClick={onClick}>
        <span className='text-small has-text-weight-semibold'>{t('Profile.Upload')}</span>
      </Button>
    )
  }

  return (
    <div className={styles.secondaryCard}>
      <div className='is-flex is-justify-content-space-between'>
        <div className='is-flex is-align-items-center'>
          <DocIcon />
          <TextSmall className={classNames(styles.textSecondary, styles.buttonText)}>
            {text}
          </TextSmall>
        </div>
        <div>
          <DocButton />
        </div>
      </div>
    </div>
  )
}

interface CardMessageProps {
  isHeaderStatusRejected: boolean
  isHeaderStatusPending: boolean
  requiresAdditionalDocuments: boolean
  hasSufficientDocuments: boolean
  isRejectedWithApprovedDoc: boolean
  isPendingWithApprovedDoc: boolean
  documentCategories: Set<DocumentCategoryType>
}

const CardMessage: FC<CardMessageProps> = ({
  isHeaderStatusRejected,
  isHeaderStatusPending,
  requiresAdditionalDocuments,
  hasSufficientDocuments,
  isRejectedWithApprovedDoc,
  isPendingWithApprovedDoc,
  documentCategories,
}) => {
  const { t } = useTranslation()

  const getMessage = () => {
    if (isHeaderStatusRejected && !isRejectedWithApprovedDoc) {
      return (
        <>
          <p>{t('Profile.It appears that some of the documents you uploaded were rejected')}</p>
          <p className='mt-2'>
            {t(
              'Profile.Meanwhile, please make sure that the file name of your document contains only English characters'
            )}
          </p>
        </>
      )
    }
    if (isPendingWithApprovedDoc) {
      return t('Profile.Document reviewing may take up to 24 working hours to complete')
    }
    if (isHeaderStatusRejected && isRejectedWithApprovedDoc) {
      return t('Profile.It appears that some of the documents you uploaded were rejected')
    }
    if (isHeaderStatusPending && !isPendingWithApprovedDoc) {
      return null
    }
    return t(
      'Profile.Meanwhile, please make sure that the file name of your document contains only English characters'
    )
  }

  return (
    <>
      <TextSmall className={styles.textSecondary}>{getMessage()}</TextSmall>
      {!isPendingWithApprovedDoc &&
        ((isHeaderStatusRejected && !isRejectedWithApprovedDoc) ||
          (!requiresAdditionalDocuments &&
            !isRejectedWithApprovedDoc &&
            !isPendingWithApprovedDoc) ||
          !documentCategories.size ||
          !(isHeaderStatusPending && !isPendingWithApprovedDoc) ||
          (hasSufficientDocuments && !isRejectedWithApprovedDoc && !isPendingWithApprovedDoc)) && (
          <>
            <br />
            <br />
            <TextSmall className={styles.textSecondary}>
              {t('Profile.Document reviewing may take up to 24 working hours to complete')}
            </TextSmall>
          </>
        )}
    </>
  )
}

interface CardHeaderProps {
  generalStatus?: NameDto
}

const CardHeader: FC<CardHeaderProps> = ({ generalStatus }) => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  const is1MonthExpired = isAccountDocumentManagement1MonthExpired(account)
  const is2MonthExpired = isAccountDocumentManagement2MonthExpired(account)

  const IconComponent = () => {
    if (generalStatus) {
      if (isDocumentsRequiredGeneralStatus(generalStatus.id)) {
        return <BlueInfoIcon />
      }
      if (isVerificationPendingGeneralStatus(generalStatus.id)) {
        return <CircleWarningIcon />
      }
      if (isRejectedDocumentsGeneralStatus(generalStatus.id)) {
        return <RedInfoIcon />
      }
      if (
        isDocumentsApprovedGeneralStatus(generalStatus.id) &&
        (is1MonthExpired || is2MonthExpired)
      ) {
        return <BlueInfoIcon />
      }
      if (isDocumentsApprovedGeneralStatus(generalStatus.id)) {
        return <CircleSuccessIcon color={'successNew'} />
      }
      if (isMoreDocumentsRequiredGeneralStatus(generalStatus.id)) {
        return <BlueInfoIcon />
      }
      if (isAdditionalDocumentsGeneralStatus(generalStatus.id)) {
        return <BlueInfoIcon />
      }
    }

    return <CircleWarningIcon />
  }

  const headerMessage = useMemo(() => {
    if (generalStatus) {
      if (isDocumentsRequiredGeneralStatus(generalStatus.id)) {
        return `${t('Profile.Documents Required')}!`
      }
      if (isRejectedDocumentsGeneralStatus(generalStatus.id)) {
        return `${t('Profile.Rejected Documents')}!`
      }
      if (isVerificationPendingGeneralStatus(generalStatus.id)) {
        return t('Profile.Verification Pending')
      }
      if (isAdditionalDocumentsGeneralStatus(generalStatus.id)) {
        return t('Profile.Additional Documents')
      }
      if (isMoreDocumentsRequiredGeneralStatus(generalStatus.id)) {
        return `${t('Profile.More Documents Required')}!`
      }
    }

    return `${t('Profile.Verification Pending')}!`
  }, [t, generalStatus])

  const getAdditionalMessage = (): string | null => {
    if (!generalStatus) {
      return null
    }

    if (isDocumentsRequiredGeneralStatus(generalStatus.id)) {
      return `${t('Profile.In order to activate your Client Area and start trading')}:`
    }

    if (isAdditionalDocumentsGeneralStatus(generalStatus.id)) {
      return t(
        'Profile.Please note that we are requesting additional documents related to your application. You can find more information about it in the email we sent you'
      )
    }

    if (isMoreDocumentsRequiredGeneralStatus(generalStatus.id)) {
      return `${t(
        'Profile.Please note that the following document is still waiting to be uploaded'
      )}:`
    }

    return null
  }

  return (
    <>
      <div className='is-flex is-align-items-center'>
        <IconComponent />
        <TextH3 className={styles.headerText}>{headerMessage}</TextH3>
      </div>
      <TextSmall isParagraph className={styles.additionalMessage}>
        {getAdditionalMessage()}
      </TextSmall>
    </>
  )
}
