import { useCallback } from 'react'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'
import { useFetchOne } from './useFetch'

export const useIBLink = (referralCode?: string): { ibLink?: string; isLoading: boolean } => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const domain = window.location.host

  const referralLinkCallback = useCallback(async () => {
    if (!referralCode) {
      return Promise.resolve({
        domainUrl: '',
        ibCode: '',
        landingPageName: '',
        landingPageUrl: '',
        url: '',
        shortUrl: '',
      })
    }
    return apiClient.getReferralLink(locale, domain, referralCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode, locale])

  const { data, isLoading } = useFetchOne(referralLinkCallback)
  const { url: urlLink, domainUrl, landingPageUrl } = data || {}

  const newLp = `${domainUrl}${landingPageUrl}`

  return {
    ibLink: urlLink?.replace(
      /(lp=).*(&|$)/,
      (match, p1, p2) => `${p1}${encodeURIComponent(newLp)}${p2}`
    ),
    isLoading,
  }
}
