import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { CalendarPopup } from '../../../global/calendar/CalendarPopup'
import { RangeOption } from '../../../global/calendar/RangeOptions'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { dateFnsLocales } from '../../../global/locale/Locale'
import { TabProps, Tabs } from '../../../global/tabs/Tabs'
import { DottedCalendarIcon } from '../../../icons/DottedCalendarIcon'
import {
  LAST_30_DAYS,
  LAST_MONTH,
  LAST_YEAR,
  ONE_WEEK,
  ONE_YEAR,
  THIS_MONTH,
  TWO_WEEKS,
} from '../constants/performance'

import styles from './DateFilter.module.scss'

enum DateFilterType {
  OneWeek = 'one-week',
  TwoWeeks = 'two-weeks',
  OneMonth = 'one-month',
  OneYear = 'one-year',
  Custom = 'custom',
}

interface DateFiltersProps {
  dateRange: [Date, Date]
  onDateRangeSelect: (range: [Date, Date]) => void
}

interface DateFilterOptions {
  name: string
  id: DateFilterType
  value?: [Date, Date]
}

const DATE_FORMAT = 'd MMM'

export const DateFilter: FC<DateFiltersProps> = (props) => {
  const { dateRange, onDateRangeSelect } = props
  const [startDate, endDate] = dateRange

  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const [currentTab, setCurrentTab] = useState<DateFilterType>(DateFilterType.OneWeek)

  const handleTabChange = (tab: DateFilterType): void => {
    setCurrentTab(tab)
    const selectedOption = dateFilterOptions.find((option) => option.id === tab)
    if (selectedOption?.value) {
      onDateRangeSelect(selectedOption.value)
    }
  }

  const handleCustomDateRangeSelect = (range: [Date, Date]): void => {
    onDateRangeSelect(range)
    setCurrentTab(DateFilterType.Custom)
  }

  const formatDate = (date: Date): string => {
    return format(date, DATE_FORMAT, { locale: dateFnsLocales[locale] })
  }

  const dateFilterOptions: DateFilterOptions[] = [
    {
      id: DateFilterType.OneWeek,
      name: t('IB.Dashboard.7D'),
      value: ONE_WEEK,
    },
    {
      id: DateFilterType.TwoWeeks,
      name: t('IB.Dashboard.14D'),
      value: TWO_WEEKS,
    },
    {
      id: DateFilterType.OneMonth,
      name: t('IB.Dashboard.1M'),
      value: LAST_30_DAYS,
    },
    {
      id: DateFilterType.OneYear,
      name: t('IB.Dashboard.1Y'),
      value: ONE_YEAR,
    },
    {
      id: DateFilterType.Custom,
      name: `${formatDate(startDate)} - ${formatDate(endDate)}`,
    },
  ]

  const calendarRangeOptions: RangeOption[] = [
    {
      label: t('Calendar.Week'),
      id: 'week',
      value: ONE_WEEK,
    },
    {
      label: t('Calendar.Last 30 Days'),
      id: 'last-30-days',
      value: LAST_30_DAYS,
    },
    {
      label: t('Calendar.This Month'),
      id: 'this-month',
      value: THIS_MONTH,
    },
    {
      label: t('Calendar.Last Month'),
      id: 'last-month',
      value: LAST_MONTH,
    },
    {
      label: t('Calendar.Last Year'),
      id: 'last-year',
      value: LAST_YEAR,
    },
  ]

  useEffect(() => {
    const selectedOption = dateFilterOptions.find((option) => {
      return (
        option.value?.[0].getTime() === startDate?.getTime() &&
        option.value[1].getTime() === endDate?.getTime()
      )
    })

    setCurrentTab(selectedOption?.id || DateFilterType.Custom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  return (
    <Tabs
      tabs={dateFilterOptions.map<TabProps>((option) => {
        return {
          id: option.id,
          name: option.name,
          className: styles.tab,
          renderTab:
            option.id === DateFilterType.Custom
              ? () => (
                  <CalendarPopup
                    onConfirm={handleCustomDateRangeSelect}
                    rangeOptions={calendarRangeOptions}
                    startDate={startDate}
                    endDate={endDate}
                    popupClassName={styles.calendarPopup}
                    maxDate={new Date()}
                  >
                    <div className={styles.customLabel}>
                      <DottedCalendarIcon />
                      <span>{option.name}</span>
                    </div>
                  </CalendarPopup>
                )
              : undefined,
        }
      })}
      currentTab={currentTab}
      appearance='label'
      onTabChanged={(tab) => handleTabChange(tab as DateFilterType)}
      wrapperClassName={styles.dateFilter}
      className={styles.tabs}
    />
  )
}
