import { NameDto } from './NameDto'

export enum IntervalType {
  Daily = 1,
  Monthly = 2,
  Yearly = 3,
}

export interface IBPerformanceParams {
  id: string
  from?: string
  to?: string
}

export interface IntroducingBrokerPerformanceChartDetailDto {
  startDate: string
  endDate: string
  intervalType: NameDto
  commission: number
  lots: number
  clientsCount: number
}
