import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { format, isValid } from 'date-fns'

import { CloseIcon } from '../../icons/CloseIcon'
import { NavigationRightIcon } from '../../icons/NavigationRightIcon'
import { TextH3 } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { useOnClickedOutside } from '../../utils/useOnClickedOutside'
import { Modal } from '../modal/Modal'
import { Calendar } from './Calendar'
import { CalendarFooter } from './CalendarFooter'
import { RangeOption, RangeOptions } from './RangeOptions'

import styles from './CalendarPopup.module.scss'

export interface CalendarPopupProps {
  onConfirm: (date: any) => void
  rangeOptions?: RangeOption[]
  startDate?: Date
  endDate?: Date
  maxDate?: Date
  popupClassName?: string
}

export const CalendarPopup: FC<PropsWithChildren<CalendarPopupProps>> = (props) => {
  const { onConfirm, children, rangeOptions, popupClassName, maxDate } = props

  const popupRef = useRef<HTMLDivElement>(null)

  const isMobile = useWindowResize()

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null])
  const [transformX, setTransformX] = useState<number>(0)

  const [startDate, endDate] = date || []

  const popupWrapperRef = useOnClickedOutside<HTMLDivElement>(() => {
    setIsPopupOpen(false)
  })

  const handleConfirm = (newDate?: [Date | null, Date | null]) => {
    if (!newDate && date && date[0] && !date[1]) {
      onConfirm([props.startDate, props.endDate])
    } else {
      onConfirm(newDate ?? date)
    }
    setIsPopupOpen(false)
    setModalOpen(false)
  }

  const handleDateSet = (date: [Date | null, Date | null]) => {
    setDate(date)
    if (date[0] && date[1]) {
      handleConfirm(date)
    }
  }

  void useEffect(() => {
    if (props.startDate && props.endDate) {
      setDate([props.startDate, props.endDate])
    }
  }, [props.startDate, props.endDate])

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      const { left, right } = popupRef.current.getBoundingClientRect()
      const { innerWidth } = window

      if (left < 0) {
        setTransformX(left * -1)
      } else if (right > innerWidth) {
        setTransformX(innerWidth - right)
      }
    }
  }, [isPopupOpen, popupRef])

  if (isMobile) {
    return (
      <div onClick={() => setModalOpen(true)} className={styles.calendarPopupModalWrapper}>
        {modalOpen && (
          <Modal
            pullDown
            closeModal={() => setModalOpen(false)}
            render={() => (
              <div className={styles.calendarPopupModal}>
                <CalendarPopupMobileContent
                  onClose={() => handleConfirm()}
                  setDate={handleDateSet}
                  startDate={startDate || undefined}
                  endDate={endDate || undefined}
                  maxDate={maxDate}
                />
              </div>
            )}
          />
        )}
        {children}
      </div>
    )
  }

  return (
    <div
      className={styles.calendarPopupWrapper}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsPopupOpen((prevState) => !prevState)
      }}
      ref={popupWrapperRef}
    >
      {children}

      {isPopupOpen && (
        <div
          ref={popupRef}
          className={classNames(styles.popup, popupClassName)}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          style={{ transform: `translate(${transformX}px, -100%)` }}
        >
          <CalendarPopupDesktopContent
            rangeOptions={rangeOptions}
            onConfirm={() => handleConfirm()}
            setDate={handleDateSet}
            endDate={endDate || undefined}
            startDate={startDate || undefined}
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  )
}

interface CalendarPopupDesktopContentProps {
  onConfirm: () => void
  startDate?: Date
  endDate?: Date
  setDate: (date: [Date | null, Date | null]) => void
  rangeOptions?: RangeOption[]
  maxDate?: Date
}

const CalendarPopupDesktopContent: FC<CalendarPopupDesktopContentProps> = (props) => {
  const { onConfirm, startDate, endDate, setDate, rangeOptions, maxDate } = props

  return (
    <div className={styles.calendarPopupContent}>
      <RangeOptions
        onRangeSelect={setDate}
        startDate={startDate}
        endDate={endDate}
        options={rangeOptions}
      />
      <Calendar
        selectsRange
        onChange={setDate}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        monthsShown={2}
        maxDate={maxDate}
      />
      <CalendarFooter
        isConfirmDisabled={!startDate || !endDate}
        isClearDisabled={!startDate || !endDate}
        onClear={() => setDate([null, null])}
        onConfirm={() => onConfirm()}
      />
    </div>
  )
}

interface CalendarPopupMobileContentProps {
  onClose: () => void
  startDate?: Date
  endDate?: Date
  maxDate?: Date
  setDate: (date: [Date | null, Date | null]) => void
}

const DATE_FORMAT = 'dd.MM.yyyy'

const CalendarPopupMobileContent: FC<CalendarPopupMobileContentProps> = (props) => {
  const { onClose, startDate, endDate, setDate, maxDate } = props

  const { t } = useTranslation()

  const [startDateValue, setStartDateValue] = useState('')
  const [endDateValue, setEndDateValue] = useState('')

  const getDateFromFormatted = (dateValue: string): Date | null => {
    if (dateValue.length !== DATE_FORMAT.length) {
      return null
    }

    const [day, month, year] = dateValue.split('.')

    return new Date(Number(year), Number(month) - 1, Number(day))
  }

  const handleStartDateChange = (dateValue: string) => {
    setStartDateValue(dateValue)

    const date = getDateFromFormatted(dateValue)

    if (isValid(date)) {
      setDate([date, endDate || null])
    }
  }

  const handleEndDateChange = (dateValue: string) => {
    setEndDateValue(dateValue)

    const date = getDateFromFormatted(dateValue)

    if (isValid(date)) {
      setDate([startDate || null, date])
    }
  }

  useEffect(() => {
    if (!startDate) {
      return
    }

    const formattedStartDate = format(startDate, DATE_FORMAT)

    if (formattedStartDate === startDateValue) {
      return
    }

    setStartDateValue(formattedStartDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])

  useEffect(() => {
    if (!endDate) {
      return
    }

    const formattedEndDate = format(endDate, DATE_FORMAT)

    if (formattedEndDate === endDateValue) {
      return
    }

    setEndDateValue(formattedEndDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate])

  return (
    <div className={styles.calendarPopupMobileContent}>
      <div className={styles.calendarMobileHeader}>
        <TextH3>{t('Calendar.Choose period')}</TextH3>

        <div onClick={() => onClose()}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.calendarMobilePeriod}>
        <input
          type='text'
          value={startDateValue}
          placeholder={t('Calendar.Start Date')}
          onChange={(e) => handleStartDateChange(e.target.value)}
        />
        <NavigationRightIcon />
        <input
          type='text'
          value={endDateValue}
          placeholder={t('Calendar.End Date')}
          onChange={(e) => handleEndDateChange(e.target.value)}
        />
      </div>
      <Calendar
        selectsRange
        onChange={setDate}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        monthsShown={1}
        maxDate={maxDate}
      />
    </div>
  )
}
