import { IconProps } from './IconProps'

export const TransactionDepositIcon: React.FC<IconProps> = (props) => {
  const { className, size = 40 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        style={{
          fill: 'var(--icon-positive)',
        }}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30ZM19.25 22.1893L17.5303 20.4697C17.2374 20.1768 16.7626 20.1768 16.4697 20.4697C16.1768 20.7626 16.1768 21.2374 16.4697 21.5303L18.7626 23.8232C19.446 24.5066 20.554 24.5066 21.2374 23.8232L23.5303 21.5303C23.8232 21.2374 23.8232 20.7626 23.5303 20.4697C23.2374 20.1768 22.7626 20.1768 22.4697 20.4697L20.75 22.1893V16C20.75 15.5858 20.4142 15.25 20 15.25C19.5858 15.25 19.25 15.5858 19.25 16V22.1893Z'
        style={{
          fill: 'var(--always-white)',
        }}
      />
    </svg>
  )
}
