import { IconProps } from '../IconProps'

export const HongKongFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 78'
      fill='none'
    >
      <path
        d='M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z'
        fill='#D80027'
      />
    </svg>
  )
}
