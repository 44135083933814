import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { useIntroducingBrokerById } from '../hooks/useIntroducingBrokerById'
import { Loyalty } from './Loyalty'

export const LoyaltyPage: FC = () => {
  useScrollToTop()
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const apiClient = useApiClient(ClientApiClient)

  const { data, isLoading, forceRefresh } = useIntroducingBrokerById()

  const onSubmitReward = async (rewardId: string) => {
    await apiClient.consumeLoyaltyReward(rewardId)
    addSnackbar.success({ message: t('IB.Your reward was successfully redeemed') })

    forceRefresh()
  }

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      <Loyalty
        loyaltyTiers={data?.loyaltyDetails.tiers || []}
        introducingBroker={data || null}
        onSubmitReward={onSubmitReward}
      />
    </Loading>
  )
}
