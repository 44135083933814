import { IconProps } from '../IconProps'

export const EUFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 79 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_801)'>
        <path
          d='M39.5 78C61.3153 78 79 60.5391 79 39C79 17.4609 61.3153 0 39.5 0C17.6848 0 0 17.4609 0 39C0 60.5391 17.6848 78 39.5 78Z'
          fill='#0052B4'
        />
        <path
          d='M39.5001 15.2609L40.7791 19.147H44.9173L41.5692 21.5487L42.8482 25.4349L39.5001 23.033L36.1519 25.4349L37.4309 21.5487L34.0828 19.147H38.2212L39.5001 15.2609Z'
          fill='#FFDA44'
        />
        <path
          d='M22.4986 22.2139L26.1863 24.0688L29.1126 21.1797L28.465 25.2153L32.1525 27.0704L28.065 27.7097L27.4176 31.7456L25.5387 28.1047L21.4514 28.7441L24.3777 25.8549L22.4986 22.2139Z'
          fill='#FFDA44'
        />
        <path
          d='M15.4567 39L19.3926 37.7372V33.6512L21.825 36.9571L25.7611 35.6943L23.3283 39L25.7611 42.3057L21.825 41.0431L19.3926 44.3488V40.2628L15.4567 39Z'
          fill='#FFDA44'
        />
        <path
          d='M22.4986 55.7861L24.3775 52.1451L21.4514 49.2559L25.5389 49.8955L27.4175 46.2546L28.065 50.2903L32.1524 50.9296L28.4653 52.7847L29.1126 56.8203L26.1863 53.9311L22.4986 55.7861Z'
          fill='#FFDA44'
        />
        <path
          d='M39.5001 62.7391L38.221 58.853H34.0828L37.431 56.4513L36.1519 52.5655L39.5001 54.967L42.8482 52.5655L41.5692 56.4513L44.9173 58.853H40.7789L39.5001 62.7391Z'
          fill='#FFDA44'
        />
        <path
          d='M56.5015 55.7861L52.8139 53.9312L49.8875 56.8204L50.5351 52.7845L46.8479 50.9296L50.9352 50.2903L51.5827 46.2546L53.4614 49.8955L57.5487 49.2559L54.6225 52.1454L56.5015 55.7861Z'
          fill='#FFDA44'
        />
        <path
          d='M63.5435 39L59.6075 40.2628V44.3488L57.175 41.0429L53.2394 42.3057L55.6719 39L53.2394 35.6943L57.1752 36.9571L59.6075 33.6512V37.7374L63.5435 39Z'
          fill='#FFDA44'
        />
        <path
          d='M56.5015 22.2139L54.6226 25.8549L57.5489 28.7441L53.4612 28.1045L51.5827 31.7454L50.9352 27.7097L46.8479 27.0703L50.5351 25.2153L49.8875 21.1799L52.8141 24.0689L56.5015 22.2139Z'
          fill='#FFDA44'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_801'>
          <rect width='79' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
