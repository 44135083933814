import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function CheckIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 18 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.9999 10.1996L2.4999 6.69961C2.1133 6.31301 1.4865 6.31301 1.0999 6.69961C0.713303 7.08621 0.713303 7.71301 1.0999 8.09961L5.9999 12.9996L17.2999 1.69961C17.6865 1.31301 17.6865 0.686209 17.2999 0.299609C16.9133 -0.08699 16.2865 -0.0869899 15.8999 0.29961L5.9999 10.1996Z'
        fill={props.color ? styles[props.color] : iconColor(props)}
      />
    </svg>
  )
}
