import { IconProps, iconColor } from './IconProps'

export function NavigationRightIcon(props: IconProps): JSX.Element {
  const { className } = props

  const size = props.size || 24

  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M5 12H19M19 12L13 6M19 12L13 18'
        stroke={iconColor(props)}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
