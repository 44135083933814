import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { InitialTierIcon } from '../../../icons/TierIcons'
import { ProgressLevelDto } from '../../../model/IntroducingBrokerLoyalty'
import { tierIconByType } from '../../Loyalty/Loyalty'
import { StepItem, Steps } from '../components/Steps/Steps'

export interface TiersProps {
  tiers: ProgressLevelDto
}

export const Tiers: FC<TiersProps> = (props) => {
  const { tiers } = props

  const { t } = useTranslation()

  const isInitialTier = tiers.next?.id === 1
  const isLastTier = tiers.next?.id === tiers.final?.id

  const initialTier: StepItem = {
    icon: InitialTierIcon,
    label: t('IB.Dashboard.N/A'),
  }

  const firstStep: StepItem | undefined = tiers.current
    ? {
        icon: tierIconByType[tiers.current.id],
        label: tiers.current.label || undefined,
      }
    : undefined

  const nextStep: StepItem | undefined =
    tiers.next && !isLastTier
      ? {
          icon: tierIconByType[tiers.next.id],
          label: tiers.next.label || undefined,
        }
      : undefined

  const finalStep: StepItem | undefined = tiers.final
    ? {
        icon: tierIconByType[tiers.final.id],
        label: tiers.final.label || undefined,
      }
    : undefined

  return (
    <Steps
      firstStep={isInitialTier ? initialTier : firstStep}
      nextStep={nextStep}
      finalStep={finalStep}
    />
  )
}
