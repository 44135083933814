import { MouseEvent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { isNoTabsPath } from '../../utils/path'

import styles from './Tabs.module.scss'

export interface TabProps {
  id: string
  name: string
  menuName?: string
  disabled?: boolean
  required?: boolean
  hidden?: boolean
  path?: string
  className?: string
  highlight?: boolean
  onTabClicked?: () => void
  renderTab?: (name: string, highlight?: boolean) => ReactNode
}

export interface NotiifcationTabProps {
  label: string
  id: string
}

export interface TabsProps {
  tabs: TabProps[]
  appearance?: 'large' | 'light' | 'inverse' | 'label' | 'label-large'
  currentTab?: string
  onTabChanged: (newTab: string, event: MouseEvent<HTMLLabelElement>) => void
  renderStartIcon?: (id: string) => ReactNode
  renderEndIcon?: (id: string) => ReactNode
  className?: string
  wrapperClassName?: string
  renderTab?: (name: string, highlight?: boolean) => ReactNode
}

export function defineTab(id: string, name: string, path?: string): TabProps {
  return { id, name, path }
}

export function defineMiniTab(label: string, id: string): NotiifcationTabProps {
  return { label, id }
}

export const Tabs = (props: TabsProps): JSX.Element => {
  const location = useLocation()
  const { tabs, appearance, currentTab, onTabChanged, className } = props
  const { renderStartIcon, renderEndIcon, wrapperClassName, renderTab } = props

  if (isNoTabsPath(location.pathname)) {
    return <></>
  }

  if (tabs.length < 2) {
    return <></>
  }

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <fieldset
        className={classNames(styles.tabs, className, {
          [styles.isDefault]: !appearance,
          [styles.isLight]: appearance === 'light',
          [styles.isLarge]: appearance === 'large',
          [styles.isInverse]: appearance === 'inverse',
          [styles.isLabel]: appearance === 'label',
          [styles.isLargeLabel]: appearance === 'label-large',
        })}
      >
        {tabs
          .filter((tab) => !tab.hidden)
          .map((tab) => (
            <Tab
              {...tab}
              key={tab.id}
              currentTab={currentTab}
              onTabChanged={onTabChanged}
              renderStartIcon={renderStartIcon}
              renderEndIcon={renderEndIcon}
              renderTab={tab.renderTab || renderTab}
            />
          ))}
      </fieldset>
    </div>
  )
}

function Tab(
  props: TabProps & {
    currentTab?: string
    onTabChanged: (newTab: string, event: MouseEvent<HTMLLabelElement>) => void
    renderStartIcon?: (id: string) => ReactNode
    renderEndIcon?: (id: string) => ReactNode
  }
) {
  const {
    id,
    name,
    path,
    required,
    disabled,
    highlight,
    currentTab,
    renderTab,
    renderStartIcon,
    renderEndIcon,
    onTabClicked,
    onTabChanged,
    className,
  } = props

  return (
    <label
      key={id}
      htmlFor={`tab-${id}`}
      onClick={(event) => {
        if (disabled) {
          return
        } else if (onTabClicked) {
          onTabClicked()
        } else {
          onTabChanged(path || id, event)
        }
      }}
      className={classNames('button', 'is-small', styles.label, className, {
        [styles.disabled]: disabled,
        ['selected ' + styles.selected]:
          currentTab && (currentTab === id || (path || '').includes(currentTab)),
      })}
    >
      {renderStartIcon?.(id)}
      <div>
        <input
          id={`tab-${id}`}
          type='radio'
          name='tabs'
          value={id}
          defaultChecked={currentTab === id}
          disabled={disabled}
        />
        {renderTab?.(name, highlight) ?? (
          <span className={className}>
            <span
              className={classNames(styles.tabItem, {
                [styles.isRequired]: required,
              })}
            >
              <span>{name}</span>
            </span>
          </span>
        )}
      </div>
      {renderEndIcon?.(id)}
    </label>
  )
}
