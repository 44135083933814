import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { TextSmall, TextTiny } from '../../../ui/Typography/Typography'
import { getCssVariable } from '../../../utils/getCscVariable'
import { getCurrencySymbol } from '../../../utils/transaction.utils'
import { PerformanceType } from './TypeFilter'

import styles from './Chart.module.scss'

export interface ChartDataItem {
  name: string
  value: number
}

interface ChartProps {
  data: ChartDataItem[]
  performanceType: PerformanceType
}

export const Chart: FC<ChartProps> = (props) => {
  const { data, performanceType } = props

  const isArabic = useArabicSessionLanguage()

  const chartData = useMemo<ChartDataItem[]>(() => {
    return isArabic ? data.slice().reverse() : data
  }, [data, isArabic])

  const color = useMemo<string>(() => {
    switch (performanceType) {
      case PerformanceType.Commission:
        return getCssVariable('--background-chart-yellow')
      case PerformanceType.Lots:
        return getCssVariable('--background-chart-green')
      case PerformanceType.Clients:
        return getCssVariable('--background-chart-blue')
      default:
        return getCssVariable('--text')
    }
  }, [performanceType])

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={chartData} style={{ direction: isArabic ? 'rtl' : 'ltr' }}>
        <CartesianGrid
          strokeDasharray='12 12'
          horizontal
          vertical={false}
          stroke='var(--background-divider)'
        />
        <XAxis
          fontSize={chartData.length > 10 ? 10 : 12}
          dataKey='name'
          dy={12}
          tick={{ fill: 'var(--text-neutral)' }}
        />
        <YAxis
          orientation={isArabic ? 'right' : 'left'}
          axisLine={false}
          tickLine={false}
          dx={isArabic ? 52 : -52}
          fontSize={12}
          textAnchor='start'
          tick={{ fill: 'var(--text-neutral)' }}
        />
        <Tooltip
          cursor={false}
          content={(props: TooltipProps<number, string>) => (
            <CustomTooltip {...props} performanceType={performanceType} />
          )}
        />
        <Line
          type='monotone'
          dataKey='value'
          stroke={color}
          strokeWidth={3}
          dot={false}
          activeDot={{ r: 7, stroke: color, fill: 'var(--background-modal)', strokeWidth: 3 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  performanceType: PerformanceType
}

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label, performanceType }) => {
  const { t } = useTranslation()

  const text = useMemo<string>(() => {
    switch (performanceType) {
      case PerformanceType.Commission:
        return `${t('IB.Dashboard.Commission')}: ${getCurrencySymbol('EUR')}${payload?.[0]?.value}`
      case PerformanceType.Lots:
        return `${t('IB.Dashboard.Lots')}: ${payload?.[0]?.value}`
      case PerformanceType.Clients:
        return `${t('IB.Dashboard.Clients')}: ${payload?.[0]?.value}`
      default:
        return ''
    }
  }, [performanceType, t, payload])

  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <TextSmall>{label}</TextSmall>
        <div className={styles.valueWrapper}>
          <div
            className={classNames(
              styles.circle,
              performanceType === PerformanceType.Commission && styles.yellow,
              performanceType === PerformanceType.Lots && styles.green,
              performanceType === PerformanceType.Clients && styles.blue
            )}
          ></div>
          <TextTiny>{text}</TextTiny>
        </div>
      </div>
    )
  }

  return null
}
