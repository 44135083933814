import { IconProps } from '../IconProps'

export const ChileFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 79'
      fill='none'
    >
      <g clipPath='url(#clip0_394_548)'>
        <path
          d='M39 79C60.5391 79 78 61.3153 78 39.5C78 17.6848 60.5391 0 39 0C17.4609 0 0 17.6848 0 39.5C0 61.3153 17.4609 79 39 79Z'
          fill='#F0F0F0'
        />
        <path
          d='M78 39.5C78 61.3151 60.539 79 39 79C17.461 79 0 61.3151 0 39.5C0 17.6849 39 39.5 39 39.5C39 39.5 68.5183 39.5 78 39.5Z'
          fill='#D80027'
        />
        <path
          d='M0 39.5C0 17.6849 17.461 0 39 0V39.5C39 39.5 13.5651 39.5 0 39.5Z'
          fill='#0052B4'
        />
        <path
          d='M23.2155 13.7391L25.7409 21.611H33.9131L27.3017 26.476L29.8269 34.3479L23.2155 29.4827L16.6041 34.3479L19.1294 26.476L12.5179 21.611H20.6901L23.2155 13.7391Z'
          fill='#F0F0F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_548'>
          <rect width='78' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
