import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionEntity } from '../../global/context/EntityContext'
import { APKQRCodeIcon } from '../../icons/APKQRCodeIcon'
import { AppleQRCodeIcon } from '../../icons/AppleQRCodeIcon'
import { GooglePlayQRCodeIcon } from '../../icons/GooglePlayQRCodeIcon'
import { isTickmillUKType } from '../../utils/companyName.utils'
import { TextH3, TextSmallStrong } from '../Typography/Typography'

import styles from './AppQRCode.module.scss'

export const AppQRCode = () => {
  const { t } = useTranslation()

  const entity = useSessionEntity()

  return (
    <div className='container'>
      <div className='is-flex is-align-items-center is-justify-content-space-around'>
        <div className={styles.iconBlock}>
          <div className={styles.icon}>
            <div className='has-text-left'>
              <TextH3>{t('iOS')}</TextH3>
              <div className='mt-2'>
                <AppleQRCodeIcon />
              </div>
            </div>
            <div className='has-text-left'>
              <TextH3>{t('Android')}</TextH3>
              <div className='mt-2'>
                <GooglePlayQRCodeIcon />
              </div>
            </div>
            <div className='has-text-left'>
              <TextH3>{t('AndroidApk')}</TextH3>
              <div className='mt-2'>
                <APKQRCodeIcon />
              </div>
            </div>
          </div>
          {isTickmillUKType(entity) && (
            <TextSmallStrong isParagraph className={styles.warning}>
              * {t('Only available for services related to CFD')}
            </TextSmallStrong>
          )}
        </div>
      </div>
    </div>
  )
}
