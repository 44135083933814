import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { IntroducingBrokerDetailsDto } from '../../model/IntroducingBrokerDetailsDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'

interface IntroducingBrokerById {
  data?: IntroducingBrokerDetailsDto
  isLoading: boolean
  forceRefresh: (skipLoadingFlag?: boolean) => void
}

export const useIntroducingBrokerById = (): IntroducingBrokerById => {
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(async () => {
    if (account?.clientIntroducingBroker?.id) {
      return await apiClient.getIntroducingBrokerById(locale, account?.clientIntroducingBroker?.id)
    }
  }, [locale, account?.clientIntroducingBroker?.id])
  const { data, isLoading } = useFetchOne(callback)

  return { data, isLoading, forceRefresh }
}
