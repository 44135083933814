import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { mapAcuityLocale } from '../../global/locale/Locale'
import { AcquityHeader } from '../AcquityHeader'

import styles from './MarketSentiment.module.scss'

export const MarketSentiment: React.FC = () => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const [isLoading, setIsLoading] = useState(true)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setIframeLoaded(false)

    const timer = setTimeout(() => {
      setIsLoading(false)
      setIframeLoaded(true)
    }, 8000)

    return () => clearTimeout(timer)
  }, [locale])

  const handleIframeLoad = () => {
    setIsLoading(false)
    setIframeLoaded(true)
  }

  return (
    <>
      <AcquityHeader title={t('Market Sentiment')} description={t('Visualize sentiment across')} />
      <div className={styles.responsiveIframe}>
        {isLoading && (
          <div className={styles.loadingContainer}>
            <Loading isLoading={true} />
          </div>
        )}
        <iframe
          className={classNames(styles.iframe, {
            [styles.loaded]: iframeLoaded,
          })}
          title='researchterminal'
          src={`https://dashboard.acuitytrading.com/widget/researchterminal?apikey=f3eb0d37-bce0-40e2-bf70-1ce9fbfb8d90&lang=${mapAcuityLocale(
            locale
          )}`}
          onLoad={handleIframeLoad}
        />
      </div>
    </>
  )
}
