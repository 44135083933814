import { IconProps } from '../IconProps'

export const SingaporeFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_1843)'>
        <path
          d='M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z'
          fill='#F0F0F0'
        />
        <path d='M0 39.0002C0 17.461 17.461 0 39 0C60.539 0 78 17.461 78 39.0002' fill='#D80027' />
        <path
          d='M23.7392 20.3476C23.7392 14.6655 27.7325 9.9188 33.0652 8.75414C32.2456 8.57528 31.3953 8.47824 30.5217 8.47824C23.9662 8.47824 18.6521 13.7923 18.6521 20.3478C18.6521 26.9033 23.9662 32.2174 30.5217 32.2174C31.395 32.2174 32.2454 32.1203 33.0652 31.9413C27.7325 30.7766 23.7392 26.0299 23.7392 20.3476Z'
          fill='#F0F0F0'
        />
        <path
          d='M39.0001 9.32602L39.8419 11.9166H42.566L40.362 13.5179L41.2039 16.1085L39.0001 14.5075L36.7963 16.1085L37.6381 13.5179L35.4343 11.9166H38.1582L39.0001 9.32602Z'
          fill='#F0F0F0'
        />
        <path
          d='M32.3922 14.4129L33.2339 17.0037H35.958L33.7542 18.6048L34.596 21.1954L32.3922 19.5944L30.1881 21.1954L31.0301 18.6048L28.8262 17.0037H31.5502L32.3922 14.4129Z'
          fill='#F0F0F0'
        />
        <path
          d='M45.608 14.4129L46.45 17.0037H49.1739L46.9699 18.6048L47.8119 21.1954L45.608 19.5944L43.4042 21.1954L44.246 18.6048L42.0422 17.0037H44.7661L45.608 14.4129Z'
          fill='#F0F0F0'
        />
        <path
          d='M43.0648 22.0434L43.9066 24.6342H46.6305L44.4267 26.2353L45.2686 28.8259L43.0648 27.2249L40.861 28.8259L41.7028 26.2353L39.499 24.6342H42.2228L43.0648 22.0434Z'
          fill='#F0F0F0'
        />
        <path
          d='M34.9355 22.0434L35.7772 24.6342H38.5015L36.2975 26.2353L37.1395 28.8259L34.9355 27.2249L32.7316 28.8259L33.5734 26.2353L31.3696 24.6342H34.0937L34.9355 22.0434Z'
          fill='#F0F0F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_1843'>
          <rect width='78' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
