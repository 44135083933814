import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { mapAcuityLocale } from '../../global/locale/Locale'
import { AcquityHeader } from '../AcquityHeader'

declare const window: Window & { AcuityWidgets: any }

export const EconomicCalendar: React.FC = () => {
  const { t } = useTranslation()
  const calendarWidgetRef = useRef(null)
  const locale = useSessionLanguage()

  useEffect(() => {
    window.AcuityWidgets.globals({
      apikey: 'f3eb0d37-bce0-40e2-bf70-1ce9fbfb8d90',
      locale: mapAcuityLocale(locale),
    })

    const widget = window.AcuityWidgets.CreateWidget(
      'CalendarTimeline',
      calendarWidgetRef.current,
      {
        utcOffset: new Date().getTimezoneOffset() * -1,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        secondBackgroundColor: 'rgba(248, 249, 250, 1)',
        primaryFontColor: 'rgba(48, 48, 48, 1)',
        secondaryFontColor: 'rgba(89, 89, 89, 1)',
        selectedAreaPeriodColor: 'rgba(238, 239, 239, 1)',
        advancedSettingsFontColor: 'rgba(55, 54, 54, 1)',
        scrollButtonColor: 'rgba(200, 200, 200, 1)',
        currentPeriodColor: 'rgba(57, 181, 74, 1)',
        timelineEventBackgroundColor: 'rgba(248, 249, 250, 1)',
        selectedTimelineEventFontColor: 'rgba(255, 255, 255, 1)',
        lowVolatilityColor: 'rgba(57, 181, 74, 1)',
        mediumVolatilityColor: 'rgba(98, 33, 234, 1)',
        highVolatilityColor: 'rgba(192, 0, 0, 1)',
        volatilityLineColor: 'rgba(204, 205, 206, 1)',
        shadowColor: 'rgba(208, 208, 208, 1)',
        forecastValueColor: 'rgba(192, 0, 0, 1)',
        previousValueColor: 'rgba(46, 49, 146, 1)',
      }
    )
    widget.mount()
  }, [locale])

  return (
    <>
      <AcquityHeader
        title={t('Economic Calendar')}
        description={t(
          'Economic data releases shape daily market trends. Traders rely on their economic calendars to track key reports, gaining insights into emerging opportunities and managing risks amid market volatility. From GDP growth rates and employment figures to interest rate decisions and inflation data, each release provides crucial information that can influence trading strategies and portfolio adjustments.'
        )}
      />
      <div id='calendar-timeline' ref={calendarWidgetRef} />
    </>
  )
}
