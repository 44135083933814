import { FC } from 'react'

import { AustraliaFlagIcon } from '../../../../icons/Flags/AustraliaFlagIcon'
import { CanadaFlagIcon } from '../../../../icons/Flags/CanadaFlagIcon'
import { ChileFlagIcon } from '../../../../icons/Flags/ChileFlagIcon'
import { ChinaFlagIcon } from '../../../../icons/Flags/ChinaFlagIcon'
import { CostaRicaFlagIcon } from '../../../../icons/Flags/CostaRicaFlagIcon'
import { EUFlagIcon } from '../../../../icons/Flags/EUFlagIcon'
import { HongKongFlagIcon } from '../../../../icons/Flags/HongKongFlagIcon'
import { JapanFlagIcon } from '../../../../icons/Flags/JapanFlagIcon'
import { MalaysiaFlagIcon } from '../../../../icons/Flags/MalaysiaFlagIcon'
import { PolandFlagIcon } from '../../../../icons/Flags/PolandFlagIcon'
import { SingaporeFlagIcon } from '../../../../icons/Flags/SingaporeFlagIcon'
import { SouthAfricaFlagIcon } from '../../../../icons/Flags/SouthAfricaFlagIcon'
import { SwissFlagIcon } from '../../../../icons/Flags/SwissFlagIcon'
import { UKFlagIcon } from '../../../../icons/Flags/UKFlagIcon'
import { USFlagIcon } from '../../../../icons/Flags/USFlagIcon'
import { IconProps } from '../../../../icons/IconProps'
import { CurrencyType } from '../../../../model/WalletDto'

interface CurrencyCodeFlagIconProps extends IconProps {
  currencyType: CurrencyType
}

export const CurrencyCodeFlagIcon: FC<CurrencyCodeFlagIconProps> = (props) => {
  const { currencyType, size = 40, className } = props

  if (currencyType === 'USD') {
    return <USFlagIcon size={size} className={className} />
  } else if (currencyType === 'GBP') {
    return <UKFlagIcon size={size} className={className} />
  } else if (currencyType === 'EUR') {
    return <EUFlagIcon size={size} className={className} />
  } else if (currencyType === 'PLN') {
    return <PolandFlagIcon size={size} className={className} />
  } else if (currencyType === 'CHF') {
    return <SwissFlagIcon size={size} className={className} />
  } else if (currencyType === 'HKD') {
    return <HongKongFlagIcon size={size} className={className} />
  } else if (currencyType === 'SGD') {
    return <SingaporeFlagIcon size={size} className={className} />
  } else if (currencyType === 'AUD') {
    return <AustraliaFlagIcon size={size} className={className} />
  } else if (currencyType === 'CAD') {
    return <CanadaFlagIcon size={size} className={className} />
  } else if (currencyType === 'JPY') {
    return <JapanFlagIcon size={size} className={className} />
  } else if (currencyType === 'CNH') {
    return <ChinaFlagIcon size={size} className={className} />
  } else if (currencyType === 'MYR') {
    return <MalaysiaFlagIcon size={size} className={className} />
  } else if (currencyType === 'ZAR') {
    return <SouthAfricaFlagIcon size={size} className={className} />
  } else if (currencyType === 'CRC') {
    return <CostaRicaFlagIcon size={size} className={className} />
  } else if (currencyType === 'CLP') {
    return <ChileFlagIcon size={size} className={className} />
  }

  return null
}
