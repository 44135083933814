import { IconProps } from '../IconProps'

export const USFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_394_2245)'>
        <path
          d='M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z'
          fill='#F0F0F0'
        />
        <path
          d='M37.3044 39H78C78 35.48 77.5308 32.0699 76.6565 28.826H37.3044V39Z'
          fill='#D80027'
        />
        <path
          d='M37.3044 18.6522H72.2759C69.8885 14.7565 66.836 11.3131 63.2769 8.47827H37.3044V18.6522Z'
          fill='#D80027'
        />
        <path
          d='M39 78.0001C48.1786 78.0001 56.6149 74.8277 63.2769 69.5219H14.7231C21.3851 74.8277 29.8215 78.0001 39 78.0001Z'
          fill='#D80027'
        />
        <path
          d='M5.72415 59.3478H72.2758C74.1925 56.2203 75.6789 52.8017 76.6565 49.1738H1.34351C2.3211 52.8017 3.80751 56.2203 5.72415 59.3478Z'
          fill='#D80027'
        />
        <path
          d='M18.0655 6.0904H21.6196L18.3137 8.4921L19.5765 12.3782L16.2708 9.97653L12.9651 12.3782L14.0558 9.02104C11.1452 11.4456 8.59402 14.2862 6.49167 17.4513H7.63044L5.52612 18.9801C5.19827 19.527 4.88384 20.0826 4.5825 20.6464L5.58736 23.7391L3.71262 22.377C3.2466 23.3644 2.82034 24.3739 2.4372 25.4045L3.54428 28.8122H7.63044L4.32458 31.2139L5.58736 35.1L2.28165 32.6983L0.301488 34.137C0.103289 35.7302 0 37.353 0 39H39C39 17.461 39 14.9218 39 0C31.2957 0 24.1137 2.23488 18.0655 6.0904ZM19.5765 35.1L16.2708 32.6983L12.9651 35.1L14.2278 31.2139L10.922 28.8122H15.0081L16.2708 24.926L17.5334 28.8122H21.6196L18.3137 31.2139L19.5765 35.1ZM18.3137 19.853L19.5765 23.7391L16.2708 21.3374L12.9651 23.7391L14.2278 19.853L10.922 17.4513H15.0081L16.2708 13.5651L17.5334 17.4513H21.6196L18.3137 19.853ZM33.5656 35.1L30.2599 32.6983L26.9542 35.1L28.217 31.2139L24.9111 28.8122H28.9973L30.2599 24.926L31.5225 28.8122H35.6087L32.3028 31.2139L33.5656 35.1ZM32.3028 19.853L33.5656 23.7391L30.2599 21.3374L26.9542 23.7391L28.217 19.853L24.9111 17.4513H28.9973L30.2599 13.5651L31.5225 17.4513H35.6087L32.3028 19.853ZM32.3028 8.4921L33.5656 12.3782L30.2599 9.97653L26.9542 12.3782L28.217 8.4921L24.9111 6.0904H28.9973L30.2599 2.20426L31.5225 6.0904H35.6087L32.3028 8.4921Z'
          fill='#0052B4'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_2245'>
          <rect width='78' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
