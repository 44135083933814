import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { format } from 'date-fns'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { dateFnsLocales } from '../../../global/locale/Locale'
import {
  IntervalType,
  IntroducingBrokerPerformanceChartDetailDto,
} from '../../../model/IntroducingBrokerPerformance'
import { useWindowResize } from '../../../utils/domUtils'
import { HorizontalScroll } from '../components/HorizontalScroll/HorizontalScroll'
import { SectionHeader } from '../components/SectionHeader/SectionHeader'
import { Chart, ChartDataItem } from './Chart'
import { DateFilter } from './DateFilter'
import { PerformanceType, TypeFilter } from './TypeFilter'

import styles from './QuickPerformance.module.scss'

interface QuickPerformanceProps {
  isLoading: boolean
  className?: string
  ibPerformanceData: IntroducingBrokerPerformanceChartDetailDto[]
  dateRange: [Date, Date]
  setDateRange: (dateRange: [Date, Date]) => void
}

export const QuickPerformance: FC<QuickPerformanceProps> = (props) => {
  const { isLoading, className, ibPerformanceData, dateRange, setDateRange } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const locale = useSessionLanguage()

  const [performanceType, setPerformanceType] = useState<PerformanceType>(
    PerformanceType.Commission
  )

  const getValueByPerformanceType = useCallback(
    (item: IntroducingBrokerPerformanceChartDetailDto): number => {
      switch (performanceType) {
        case PerformanceType.Commission:
          return item.commission
        case PerformanceType.Lots:
          return item.lots
        case PerformanceType.Clients:
          return item.clientsCount
        default:
          return 0
      }
    },
    [performanceType]
  )

  const chartData = useMemo<ChartDataItem[]>(() => {
    return ibPerformanceData.map((item, index) => {
      const date = index === 0 ? item.startDate : item.endDate

      const dateFormat = item.intervalType.id === IntervalType.Daily ? 'd MMM' : 'MMM yy'

      const name = format(new Date(date), dateFormat, { locale: dateFnsLocales[locale] })

      return {
        name: name,
        value: getValueByPerformanceType(item),
      }
    })
  }, [ibPerformanceData, getValueByPerformanceType, locale])

  return (
    <div className={classNames(styles.quickPerformance, className)}>
      <SectionHeader
        title={t('IB.Dashboard.Quick performance')}
        seeMoreLinkProps={{ to: '/dashboard/introducing-broker/reports' }}
        iconMobileOnly
      />
      <div className={styles.filters}>
        <TypeFilter activeTab={performanceType} onTabChanged={setPerformanceType} />
        {!isMobile && <DateFilter dateRange={dateRange} onDateRangeSelect={setDateRange} />}
      </div>
      <div className={styles.chart}>
        <Loading isLoading={isLoading} showLoadingIcon>
          <Chart data={chartData} performanceType={performanceType} />
        </Loading>
      </div>
      {isMobile && (
        <HorizontalScroll containerClassName={styles.mobileDatesContainer}>
          <DateFilter dateRange={dateRange} onDateRangeSelect={setDateRange} />
        </HorizontalScroll>
      )}
    </div>
  )
}
