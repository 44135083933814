import { IconProps } from '../IconProps'

export const CanadaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 79 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_453)'>
        <path
          d='M39.5 78C61.3153 78 79 60.5391 79 39C79 17.4609 61.3153 0 39.5 0C17.6848 0 0 17.4609 0 39C0 60.5391 17.6848 78 39.5 78Z'
          fill='#F0F0F0'
        />
        <path
          d='M79 39C79 23.538 69.8864 10.1781 56.6738 3.86983V74.13C69.8864 67.8219 79 54.462 79 39Z'
          fill='#D80027'
        />
        <path
          d='M0 39C0 54.462 9.11355 67.8219 22.3261 74.1302V3.86983C9.11355 10.1781 0 23.538 0 39Z'
          fill='#D80027'
        />
        <path
          d='M46.3696 44.0869L53.2391 40.6956L49.8045 39V35.6087L42.9348 39L46.3696 32.2174H42.9348L39.5 27.1304L36.0652 32.2174H32.6304L36.0652 39L29.1956 35.6087V39L25.761 40.6956L32.6304 44.0869L30.9131 47.4782H37.7827V52.5651H41.2174V47.4782H48.087L46.3696 44.0869Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_453'>
          <rect width='79' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
