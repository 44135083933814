import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LoyaltyDashboardDetailsDto } from '../../../model/IntroducingBrokerLoyalty'
import {
  ProgressBarsBlock,
  ProgressBarsBlockItem,
} from '../components/ProgressBarsBlock/ProgressBarsBlock'

interface ProgressBarsProps {
  loyaltyData?: LoyaltyDashboardDetailsDto
}

export const ProgressBars: FC<ProgressBarsProps> = (props) => {
  const { loyaltyData } = props

  const { t } = useTranslation()

  const getLotsLabel = useCallback(
    (amount?: number) => {
      if (!amount) {
        return ''
      }

      return amount === 1
        ? `${amount} ${t('IB.Dashboard.Lot')}`
        : `${amount} ${t('IB.Dashboard.Lots')}`
    },
    [t]
  )

  const getClientLabel = useCallback(
    (amount?: number) => {
      if (!amount) {
        return ''
      }

      return amount === 1
        ? `${amount} ${t('IB.Dashboard.Client')}`
        : `${amount} ${t('IB.Dashboard.Clients')}`
    },
    [t]
  )

  const items: ProgressBarsBlockItem[] = useMemo(() => {
    const lotsPercent = ((loyaltyData?.lots.current || 0) / (loyaltyData?.lots.required || 0)) * 100
    const activeClientsPercent =
      ((loyaltyData?.activeClients.current || 0) / (loyaltyData?.activeClients.required || 0)) * 100
    const volumeRatioPercent =
      ((loyaltyData?.volumeRatio.current || 0) / (loyaltyData?.volumeRatio.required || 0)) * 100

    return [
      {
        title: t('IB.Dashboard.Lots'),
        label: getLotsLabel(loyaltyData?.lots.required),
        tooltip: getLotsLabel(loyaltyData?.lots.current),
        sections: [
          {
            percent: lotsPercent,
          },
        ],
      },
      {
        title: t('IB.Dashboard.Active Clients (90 Days)'),
        label: getClientLabel(loyaltyData?.activeClients.required),
        tooltip: getClientLabel(loyaltyData?.activeClients.current),
        sections: [
          {
            percent: activeClientsPercent,
          },
        ],
      },
      {
        title: t('IB.Dashboard.Volume'),
        label: loyaltyData?.volumeRatio.required
          ? `${loyaltyData.volumeRatio.required}% ${t('IB.Dashboard.Volume')}`
          : '',
        tooltip: loyaltyData?.volumeRatio.current
          ? `${loyaltyData.volumeRatio.current}% ${t('IB.Dashboard.Volume')}`
          : '',
        sections: [
          {
            percent: volumeRatioPercent,
          },
        ],
      },
    ]
  }, [loyaltyData, t, getLotsLabel, getClientLabel])

  return <ProgressBarsBlock items={items} />
}
