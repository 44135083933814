import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TabProps, Tabs } from '../../../global/tabs/Tabs'

import styles from './TypeFilter.module.scss'

export enum PerformanceType {
  Commission = 'commission',
  Lots = 'lots',
  Clients = 'clients',
}

interface TypeFiltersProps {
  activeTab: PerformanceType
  onTabChanged: (tab: PerformanceType) => void
}

export const TypeFilter: FC<TypeFiltersProps> = (props) => {
  const { activeTab, onTabChanged } = props

  const { t } = useTranslation()

  const typeFilterTabs: TabProps[] = [
    {
      id: PerformanceType.Commission,
      name: t('IB.Dashboard.Commission'),
      className: styles.tab,
    },
    {
      id: PerformanceType.Lots,
      name: t('IB.Dashboard.Lots'),
      className: styles.tab,
    },
    {
      id: PerformanceType.Clients,
      name: t('IB.Dashboard.Clients'),
      className: styles.tab,
    },
  ]

  return (
    <Tabs
      tabs={typeFilterTabs}
      currentTab={activeTab}
      appearance='label'
      onTabChanged={(tab, event) => {
        event.preventDefault()
        onTabChanged(tab as PerformanceType)
      }}
      wrapperClassName={styles.typeFilters}
      className={styles.tabs}
      renderStartIcon={(id) => {
        return (
          <span
            className={classNames(
              styles.ball,
              id === PerformanceType.Commission && styles.yellow,
              id === PerformanceType.Lots && styles.green,
              id === PerformanceType.Clients && styles.blue
            )}
          ></span>
        )
      }}
    />
  )
}
