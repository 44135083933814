import { IconProps } from '../IconProps'

export const SwissFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 78'
      fill='none'
    >
      <g clipPath='url(#clip0_394_2039)'>
        <path
          d='M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z'
          fill='#D80027'
        />
        <path
          d='M59.3477 32.2175H45.7825V18.6522H32.2172V32.2175H18.6521V45.7827H32.2172V59.3478H45.7825V45.7827H59.3477V32.2175Z'
          fill='#F0F0F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_2039'>
          <rect width='78' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
