import { useCallback } from 'react'

import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { TransactionDto } from '../../../model/TransactionDto'
import { WalletDto, WalletTypeEnum } from '../../../model/WalletDto'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { prefixFilter } from '../../../utils/filter.utils'
import { useFetchOne } from '../../../utils/useFetch'

export interface WalletWithLatestTransactions {
  wallet: WalletDto
  latestTransactions: TransactionDto[]
}

interface UseWalletsWithLatestTransactionsValue {
  walletsWithLatestTransactions: WalletWithLatestTransactions[]
  isLoading: boolean
}

export const useWalletsWithLatestTransactions = (): UseWalletsWithLatestTransactionsValue => {
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    const wallets = await apiClient.getWallets({
      walletTypeId: 10,
      pageSize: 2,
      pageIndex: 1,
    })

    const transactions = await Promise.all(
      wallets.map((wallet) => {
        return apiClient.getTransactions({
          ca_search_WalletId: wallet.id,
          ca_search_ClientWalletType: prefixFilter({
            prefix: account?.id,
            value: WalletTypeEnum.IB.toString(),
          }),
          caller: 'ca',
          pageSize: 3,
          pageIndex: 1,
          languageId: locale,
        })
      })
    )

    return wallets.map<WalletWithLatestTransactions>((wallet, index) => {
      return {
        wallet: wallet,
        latestTransactions: transactions[index].items || [],
      }
    })
  }, [apiClient, account, locale])

  const { data: walletsWithLatestTransactions = [], isLoading: isWalletsLoading } =
    useFetchOne(callback)

  return {
    walletsWithLatestTransactions: walletsWithLatestTransactions,
    isLoading: isWalletsLoading,
  }
}
