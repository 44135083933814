import { IconProps } from './IconProps'

export const TransactionWithdrawIcon: React.FC<IconProps> = (props) => {
  const { className, size = 40 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        style={{
          fill: 'var(--icon-transaction)',
        }}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10ZM20.75 17.8107L22.4697 19.5303C22.7626 19.8232 23.2374 19.8232 23.5303 19.5303C23.8232 19.2374 23.8232 18.7626 23.5303 18.4697L21.2374 16.1768C20.554 15.4934 19.446 15.4934 18.7626 16.1768L16.4697 18.4697C16.1768 18.7626 16.1768 19.2374 16.4697 19.5303C16.7626 19.8232 17.2374 19.8232 17.5303 19.5303L19.25 17.8107V24C19.25 24.4142 19.5858 24.75 20 24.75C20.4142 24.75 20.75 24.4142 20.75 24V17.8107Z'
        style={{
          fill: 'var(--always-white)',
        }}
      />
    </svg>
  )
}
