import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { GiftIcon } from '../../../icons/GiftIcon'
import {
  IntroducingBrokerDetailsDto,
  loyaltyBonusStatus,
} from '../../../model/IntroducingBrokerDetailsDto'
import { LoyaltyDashboardDetailsDto } from '../../../model/IntroducingBrokerLoyalty'
import { Text } from '../../../ui/Typography/Typography'
import { SectionHeader } from '../components/SectionHeader/SectionHeader'
import { MaxLevelBanner } from './MaxLevelBanner'
import { ProgressBars } from './ProgressBars'
import { Tiers } from './Tiers'

import styles from './LoyaltyProgram.module.scss'

interface LoyaltyProgramProps {
  isLoading: boolean
  loyaltyData?: LoyaltyDashboardDetailsDto
  ibData?: IntroducingBrokerDetailsDto
  isConsumeLoyaltyRewardLoading: boolean
  onClaimClick: (rewardId: string) => void
}

export const LoyaltyProgram: FC<LoyaltyProgramProps> = (props) => {
  const { isLoading, loyaltyData, ibData, isConsumeLoyaltyRewardLoading, onClaimClick } = props

  const { t } = useTranslation()

  const rewardId = useMemo<string | undefined>(() => {
    const reward = ibData?.loyaltyDetails.redeemableRewards.find(
      (x) => x.tierId === loyaltyData?.tiers.current?.id
    )

    if (reward && reward.loyaltyBonusStatus.id === loyaltyBonusStatus.Approved) {
      return reward.id
    }

    return undefined
  }, [ibData, loyaltyData])

  const hasReachedMaxLevel = useMemo<boolean>(() => {
    return (
      !!loyaltyData?.tiers.current &&
      !!loyaltyData.tiers.final &&
      loyaltyData.tiers.current.id === loyaltyData.tiers.final.id
    )
  }, [loyaltyData])

  const handleClaimClick = () => {
    if (rewardId) {
      onClaimClick(rewardId)
    }
  }

  return (
    <div className={styles.loyaltyProgram}>
      <SectionHeader
        title={t('IB.Dashboard.Loyalty Program')}
        seeMoreLinkProps={{ to: '/dashboard/introducing-broker/loyalty-program' }}
        tooltipContent={t('IB.Dashboard.Eligibility check is being done on weekly basis')}
      />
      <Loading isLoading={isLoading} showLoadingIcon>
        {loyaltyData?.tiers &&
          (hasReachedMaxLevel ? <MaxLevelBanner /> : <Tiers tiers={loyaltyData.tiers} />)}
        {loyaltyData && <ProgressBars loyaltyData={loyaltyData} />}
        <div className={styles.buttons}>
          <Button.New
            className={classNames(styles.button, styles.checkButton)}
            size='L'
            appearance='secondary'
            state='disabled'
            fullWidth
            disabled
          >
            <Text>{t('IB.Dashboard.Checking eligibility')}</Text>
          </Button.New>
          <Button
            size='L'
            appearance='primary'
            state={rewardId ? 'normal' : 'disabled'}
            fullWidth
            onClick={handleClaimClick}
            renderLeftIcon={() => <GiftIcon color='alwaysWhite' />}
            className={classNames(styles.button, {
              [styles.claimButtonActive]: !!rewardId,
            })}
            loading={isConsumeLoyaltyRewardLoading}
          >
            <Text>{t('IB.Dashboard.Claim rewards')}</Text>
          </Button>
        </div>
      </Loading>
    </div>
  )
}
