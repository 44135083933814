import { useCallback } from 'react'

import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { LoyaltyDashboardDetailsDto } from '../../../model/IntroducingBrokerLoyalty'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'

interface UseIBLoyaltyValue {
  data: LoyaltyDashboardDetailsDto | null
  isLoading: boolean
}

export const useIBLoyalty = (): UseIBLoyaltyValue => {
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    if (!account?.clientIntroducingBroker?.id) {
      return null
    }

    const iBPerformance = await apiClient.getIBLoyalty({
      id: account.clientIntroducingBroker.id,
      languageId: locale,
    })

    return iBPerformance
  }, [account, apiClient, locale])

  const { data, isLoading } = useFetchOne(callback)

  return {
    data: data || null,
    isLoading: isLoading,
  }
}
