import { useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'

import { IntroducingBrokerPerformanceChartDetailDto } from '../../../model/IntroducingBrokerPerformance'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { DEFAULT_DATE_RANGE } from '../constants/performance'

interface UseIBPerformanceValue {
  data: IntroducingBrokerPerformanceChartDetailDto[]
  isLoading: boolean
  dateRange: [Date, Date]
  setDateRange: (dateRange: [Date, Date]) => void
}

const API_DATE_FORMAT = 'yyyy-MM-dd'

export const useIBPerformance = (): UseIBPerformanceValue => {
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const [dateRange, setDateRange] = useState<[Date, Date]>(DEFAULT_DATE_RANGE)

  const [from, to] = useMemo<[string, string]>(() => {
    return [format(dateRange[0], API_DATE_FORMAT), format(dateRange[1], API_DATE_FORMAT)]
  }, [dateRange])

  const callback = useCallback(async () => {
    if (!account?.clientIntroducingBroker?.id) {
      return []
    }

    const iBPerformance = await apiClient.getIBPerformance({
      id: account.clientIntroducingBroker.id,
      from: from,
      to: to,
    })

    return iBPerformance
  }, [account, apiClient, from, to])

  const { data, isLoading } = useFetchOne(callback)

  return {
    data: data || [],
    isLoading: isLoading,
    dateRange: dateRange,
    setDateRange: setDateRange,
  }
}
