import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HowIBWorksModal } from '../Profile/IntroducingBroker/HowIBWorksModal'
import { HowMultiTierWorksModal } from '../Profile/IntroducingBroker/HowMultiTierWorksModal'
import { CopyLink } from '../global/copyLink/CopyLink'
import { SelectField } from '../global/field/SelectField'
import { TextField } from '../global/field/TextField'
import { Modal } from '../global/modal/Modal'
import { CopyIcon } from '../icons/CopyIcon'
import { NoResults } from '../ui/Table/NoResults/NoResults'
import { useIBLink } from '../utils/useIBLink'
import { useIBReferralCodes } from '../utils/useIBReferralCodes'

import styles from './IBNoData.module.scss'

interface Props {
  title: string
}

export const IBNoData: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation()
  const { referralCodes, ibCode, mtCode, hideSelectors } = useIBReferralCodes()
  const [selectedCode, setSelectedCode] = useState(ibCode || mtCode)
  const [howIBWorks, setHowIBWorks] = useState(false)
  const [howMIBWorks, setHowMIBWorks] = useState(false)

  const ibLink = useIBLink(selectedCode)
  useEffect(() => setSelectedCode(ibCode || mtCode), [ibCode, mtCode])

  return (
    <NoResults
      title={title}
      subtitle={t(
        'IB.Invite traders to join Tickmill by using your personal link and get a permanent commission on every trade they make.'
      )}
      children={
        <>
          {howIBWorks && (
            <Modal
              closeModal={() => setHowIBWorks(false)}
              render={({ closeModal }) => (
                <HowIBWorksModal onCancel={closeModal} ibCode={ibCode || mtCode} />
              )}
            />
          )}
          {howMIBWorks && (
            <Modal
              closeModal={() => setHowMIBWorks(false)}
              render={({ closeModal }) => (
                <HowMultiTierWorksModal onCancel={closeModal} ibCode={mtCode} />
              )}
            />
          )}
          {!hideSelectors && (
            <SelectField
              options={referralCodes.map(({ code }) => ({
                value: code,
                label: code,
              }))}
              allowCopy
              className={styles.input}
              onChange={(v) => setSelectedCode(v.target.value)}
              value={selectedCode}
              label={t('IB.Your Referral Code')}
            />
          )}
          {!hideSelectors && (
            <TextField
              className={styles.input}
              interactiveText
              label={t('IB.Your personal link')}
              type='text'
              value={ibLink.ibLink}
              disabled
              renderIcon={
                <CopyLink label={`${t('Copied')}!`} value={ibLink.ibLink}>
                  <CopyIcon size={18} color='textSecondary' />
                </CopyLink>
              }
            />
          )}
        </>
      }
      link={
        (selectedCode === ibCode && {
          text: t('IB.How IB works?'),
          onClick: () => setHowIBWorks(true),
        }) ||
        (selectedCode === mtCode && {
          text: t('IB.MultiTier.How Multi-tier works'),
          onClick: () => setHowMIBWorks(true),
        }) ||
        undefined
      }
    />
  )
}
