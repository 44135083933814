import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSnackbar } from '../../../global/context/SnackbarContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'

interface UseConsumeLoyaltyRewardParams {
  onComplete?: () => void
}

interface UseConsumeLoyaltyRewardValue {
  consumeLoyaltyReward: (rewardId: string) => Promise<void>
  isLoading: boolean
}

export const useConsumeLoyaltyReward = ({
  onComplete,
}: UseConsumeLoyaltyRewardParams = {}): UseConsumeLoyaltyRewardValue => {
  const apiClient = useApiClient(ClientApiClient)
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const consumeLoyaltyReward = async (rewardId: string) => {
    setIsLoading(true)

    await apiClient.consumeLoyaltyReward(rewardId)

    addSnackbar.success({ message: t('IB.Your reward was successfully redeemed') })

    onComplete?.()

    setIsLoading(false)
  }

  return {
    consumeLoyaltyReward,
    isLoading,
  }
}
