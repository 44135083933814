import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

enum ButtonAppearance {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryNew = 'secondaryNew',
  Plain = 'plain',
  Link = 'link',
  Tertiary = 'tertiary',
  Selectable = 'selectable',
}

enum ButtonState {
  Normal = 'normal',
  Focus = 'focus',
  Disabled = 'disabled',
  Static = 'static',
}

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined
  appearance?:
    | 'primary'
    | 'secondary'
    | 'secondaryNew'
    | 'plain'
    | 'link'
    | 'tertiary'
    | 'selectable'
  state?: 'normal' | 'focus' | 'disabled' | 'static' | undefined
  size?: 'XS' | 'S' | 'M' | 'L'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  renderLeftIcon?: () => ReactNode
  loading?: boolean
  renderRightIcon?: () => ReactNode
  onMouseOver?: MouseEventHandler<HTMLButtonElement>
  onMouseOut?: MouseEventHandler<HTMLButtonElement>
}

export const Button: FC<PropsWithChildren<ButtonProps>> & {
  New: FC<PropsWithChildren<ButtonProps>>
} = ({
  type,
  className,
  appearance,
  state,
  size,
  onClick,
  disabled,
  loading,
  fullWidth,
  renderLeftIcon,
  renderRightIcon,
  onMouseOut,
  onMouseOver,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classNames('button', className, styles.btn, {
        [styles.tertiary]: appearance === ButtonAppearance.Tertiary,
        [styles.isPlain]: appearance === ButtonAppearance.Plain,
        [styles.primary]: appearance === ButtonAppearance.Primary,
        [styles.secondary]: appearance === ButtonAppearance.Secondary,
        [styles.secondaryNew]: appearance === ButtonAppearance.SecondaryNew,
        [styles.link]: appearance === ButtonAppearance.Link,
        [styles.selectable]: appearance === ButtonAppearance.Selectable,
        [styles.focus]: state === ButtonState.Focus,
        [styles.disabled]: state === ButtonState.Disabled || disabled || loading,
        [styles.static]: state === ButtonState.Static,
        [styles.fullWidth]: fullWidth,
        [styles[`size-${size}`]]: !!size,
      })}
      type={type}
      disabled={loading || disabled}
    >
      <span className={classNames(styles.contentWrapper)}>
        {loading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingSpinner} />
          </div>
        )}
        {renderLeftIcon && <span className={styles.sideIcon}>{renderLeftIcon()}</span>}
        <span>{children}</span>
        {renderRightIcon && <span className={styles.sideIcon}>{renderRightIcon()}</span>}
      </span>
    </button>
  )
}

Button.New = (props) => {
  const { appearance, ...rest } = props
  const newAppearance = appearance === 'secondary' ? 'secondaryNew' : appearance
  return <Button appearance={newAppearance} {...rest} />
}
