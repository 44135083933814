import { useCallback } from 'react'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { OldLegalDocumentDto } from '../../model/LegalDocumentDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

interface IntroducingBrokerLegalDocumentsParams {
  legalDocumentsSchemes: string
}

interface IntroducingBrokerLegalDocuments {
  data?: OldLegalDocumentDto[]
  isLoading: boolean
}

export const useIntroducingBrokerLegalDocuments = (
  props: IntroducingBrokerLegalDocumentsParams
): IntroducingBrokerLegalDocuments => {
  const { legalDocumentsSchemes } = props

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const legalDocumentsCallback = useCallback(async () => {
    return apiClient.getIntroducingBrokerLegalDocuments(locale, legalDocumentsSchemes)
  }, [legalDocumentsSchemes, locale, apiClient])

  const { data, isLoading } = useFetchOne(legalDocumentsCallback)

  return {
    data,
    isLoading,
  }
}
