import { InstrumentCommissionDto } from './InstrumentCommissionDto'
import { NameDto } from './NameDto'
import { CurrencyType } from './WalletDto'

export interface IntroducingBrokerDetailsDto {
  instrumentCommissions?: InstrumentCommissionDto[]
  id: string
  name: string | null
  status: IntroducingBrokerStatus
  client?: Client
  parent?: null | { id: string; name: string | null }
  registeredAt: string
  multiLevelRegisteredAt?: string | null
  multiLevelPercentage?: number | null
  multiTierRegisteredAt: string | null
  defaultProgramRegisteredAt: string | null
  commissionCalculationStatus: NameDto<number>
  profile?: IBProfile
  assignee?: AssigneeDto | null
  ibUserGetsClients?: boolean
  sibAssignee?: AssigneeDto
  sibIsAutoAssigned: boolean | null
  loyaltyDetails: LoyaltyDetails
  loyaltyProgramEnabled: boolean
  loyaltyProgramEligible: boolean
  restrictions?: NameDto[] | null
  tradingAccountsCount: number
  isLastMultiTierLevel: boolean
  hasMultiLevelCommission: boolean
}

export interface ManualAdjustmentDto {
  introducingBrokerId: string
}

export interface AssigneeDto {
  id: string
  name: string
  status: NameDto<number>
}

export interface IntroducingBrokerReferralDetailsDto {
  isDefaultCommissionPlan: boolean
  mainIbScheme: NameDto<IntroducingBrokerReferralTypes>
  code: string
}

export enum IntroducingBrokerReferralTypes {
  Default = 0,
  MultiTier = 1,
}

export enum loyaltyBonusStatus {
  Created = 1,
  Approved = 2,
  Rejected = 3,
  Reversal = 4,
  Reversed = 5,
  Redeemed = 6,
}
export interface LoyaltyDetails {
  loyaltyLots: number
  currentTier?: LoyaltyTierDto | null
  nextTier?: LoyaltyTierDto
  nextSubTier: LoyaltyNextSubTierDto | null
  loyaltyRewards: LoyaltyRewardDto[]
  redeemableRewards: LoyaltyRewardDto[]
  tiers: LoyaltyTierDto[]
  loyaltyLotsToNextTier: number
}

export type LoyaltyTier =
  | 'Default'
  | 'Bronze'
  | 'Silver'
  | 'Gold'
  | 'Platinum'
  | 'Diamond'
  | 'Elite'
  | 'Elite Plus'
  | 'Elite Plus 1'
  | 'Elite Plus 2'
  | 'Elite Plus 3'
  | 'Elite Plus 4'
  | 'Elite Plus 5'
  | 'Elite Plus 6'
  | 'Elite Plus 7'
  | 'Elite Plus 8'
  | 'Elite Plus 9'
  | 'Elite Plus 10'
  | 'Elite Plus 11'
  | 'Elite Plus 12'
  | 'Elite Plus 13'
  | 'Elite Plus 14'
  | 'Elite Plus 15'
  | 'Elite Plus 16'
  | 'Elite Plus 17'
  | 'Elite Plus 18'
  | 'Legend'
  | 'Ultimate'

export interface LoyaltyTierDto {
  id: number
  name: LoyaltyTier | null
  htmlPrizeText: string | null
  label: string | null
  minLots: number
  maxLots: number
  amount: number
  shouldNotify: boolean
  order: number
  groupId: number
  imageUri: string | null
}

export interface LoyaltyTierClientDto {
  id: number
  name: LoyaltyTier | null
  htmlPrizeText: string | null
  label: string | null
  minLots: number
  maxLots: number
  amount: number
  shouldNotify: boolean
  order: number
  groupId: number
  imageUri: string | null
  ibReward: LoyaltyRewardDto
}

interface LoyaltyRewardDto {
  id: string
  tierId: number
  walletId: string
  currencyId: CurrencyType | null
  amount: number
  transactionId: string | null
  loyaltyBonusStatus: NameDto<number>
}

interface LoyaltyNextSubTierDto {
  id: number
  name: string | null
  htmlPrizeText: string | null
  lots: number
  available: boolean
}

export interface Client {
  id: string
  name: string | null
  introducingBroker: IntroducingBroker
  tickmillCompany: TickmillCompany
  teams: { id: string; team: string | null }[]
}

interface TickmillCompany {
  id: number
  name: string
}

interface IntroducingBroker {
  name: string | null
  id: string
}

export interface IntroducingBrokerStatus {
  name: string | null
  id: number
}

export interface IBProfile {
  accountGroups: {
    id: string
    name: string | null
    currency: NameDto<string>
  }[]
  standardCommissions: []
  id: string
  name: string | null
  description: string | null
  tickmillCompany: { id: number; name: string | null }
}
