import React, { forwardRef, useImperativeHandle, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { useTooltipMove } from './useTooltipMove'

import styles from './Tooltip.module.scss'

export type TooltipDirection =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft'

interface TooltipProps {
  content?: string | null
  direction?: TooltipDirection
  children?: React.ReactNode
  autoHide?: number
  disabled?: boolean
  className?: string
  wrapperClassName?: string
}

const variants = {
  open: {
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.25,
    },
  },
}

export const Tooltip = forwardRef((props: TooltipProps, ref) => {
  const { content, direction = 'top', children, disabled, className, wrapperClassName } = props
  const { autoHide } = props

  const [active, setActive] = useState(false)

  const { boxRef, newDirection } = useTooltipMove(direction)

  useImperativeHandle(ref, () => ({
    activate() {
      if (!active && !disabled && !!content) {
        setActive(true)
        if (autoHide) {
          setTimeout(() => setActive(false), autoHide)
        }
      }
    },
  }))

  const showTip = () => {
    if (disabled || !content) {
      return
    }
    setActive(true)
  }

  const hideTip = () => {
    if (disabled || !content) {
      return
    }
    setActive(false)
  }

  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      className={classNames(styles.tooltipWrapper, wrapperClassName)}
    >
      {children}
      <motion.div
        animate={active ? 'open' : 'closed'}
        initial='closed'
        variants={variants}
        className={classNames(styles.tooltip, styles[newDirection], className, {
          [styles.active]: active,
        })}
        ref={boxRef}
      >
        <span>{content}</span>
      </motion.div>
    </div>
  )
})
