import { IconProps } from '../IconProps'

export const CostaRicaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 79 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_618)'>
        <path
          d='M39.5 79C61.3153 79 79 61.3153 79 39.5C79 17.6848 61.3153 0 39.5 0C17.6848 0 0 17.6848 0 39.5C0 61.3153 17.6848 79 39.5 79Z'
          fill='#F0F0F0'
        />
        <path
          d='M76.5431 25.7609H2.45687C0.869154 30.0401 0 34.6683 0 39.5C0 44.3317 0.869154 48.9599 2.45687 53.2391H76.5433C78.131 48.9599 79 44.3317 79 39.5C79 34.6683 78.131 30.0401 76.5431 25.7609Z'
          fill='#D80027'
        />
        <path
          d='M39.5 0C27.528 0 16.8008 5.32695 9.55701 13.7391H69.4429C62.1992 5.32695 51.4721 0 39.5 0Z'
          fill='#0052B4'
        />
        <path
          d='M69.443 65.2609H9.55701C16.8008 73.6731 27.528 79 39.5 79C51.4721 79 62.1992 73.6731 69.443 65.2609Z'
          fill='#0052B4'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_618'>
          <rect width='79' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
