import { IconProps } from '../IconProps'

export const SouthAfricaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
      viewBox='0 0 79 79'
      fill='none'
    >
      <g clipPath='url(#clip0_394_1923)'>
        <path
          d='M39.5 79C61.3153 79 79 61.3153 79 39.5C79 17.6848 61.3153 0 39.5 0C17.6848 0 0 17.6848 0 39.5C0 61.3153 17.6848 79 39.5 79Z'
          fill='#F0F0F0'
        />
        <path
          d='M11.5693 67.4308C-3.85642 52.005 -3.85642 26.995 11.5693 11.5692C11.5682 11.5709 34.3479 39.5 34.3479 39.5L11.5693 67.4308Z'
          fill='black'
        />
        <path
          d='M34.3479 39.5L5.23888 19.8292C4.3364 21.3979 3.54053 23.0353 2.85669 24.7301L17.5934 39.5002L2.85715 54.271C3.54038 55.9646 4.33547 57.6009 5.23734 59.1685L34.3479 39.5Z'
          fill='#FFDA44'
        />
        <path
          d='M78.6656 34.3479H34.3468L11.5689 11.5698C9.11432 14.0244 6.98178 16.8003 5.23885 19.8293L24.8714 39.5L5.2373 59.1682C6.98024 62.198 9.1137 64.9749 11.5689 67.4301L34.3468 44.6522H78.6656C78.8852 42.9657 79 41.2462 79 39.5C79 37.7538 78.8852 36.0344 78.6656 34.3479Z'
          fill='#6DA544'
        />
        <path
          d='M15.4509 70.8341C22.1118 75.954 30.4495 79 39.4999 79C57.75 79 73.1066 66.6221 77.6392 49.8044H36.4808L15.4509 70.8341Z'
          fill='#0052B4'
        />
        <path
          d='M77.6392 29.1956C73.1066 12.3778 57.75 0 39.4999 0C30.4495 0 22.1118 3.04597 15.4509 8.16585L36.4807 29.1956H77.6392Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_1923'>
          <rect width='79' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
