import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { FileData } from '../../../global/fileUpload/FileUpload'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { CountryPreferenceStrippedDto } from '../../../model/CountryDto'
import { NameDto } from '../../../model/NameDto'
import { TestSectionDto, TestSectionsDto } from '../../../model/TestSectionsDto'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { getCleanedAnswers } from '../../../ui/TestFields/TestFields'
import { isTickmillSC } from '../../../utils/companyName.utils'
import {
  AddressLettersPattern,
  CityStatePattern,
  ZipCodePattern,
} from '../../../utils/formValidation'
import { YearlyKycPersonalInfoForm } from './YearlyKycPersonalInfoForm'
import { YearlyKycTestForm } from './YearlyKycTestForm'

import styles from './YearlyKycUpdateDetailsForm.module.scss'

const areAllMandatoryQuestionsAnswered = (
  accountKycTest: TestSectionsDto,
  selectedAnswers: { [key: string]: string },
  freeAnswers: { [key: string]: string }
): boolean => {
  let employedAnswerId: string | null = null
  let otherAnswerId: string | null = null

  accountKycTest.sections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.widget.name === 'employment_status') {
        const employedAnswer = question.answers.find((answer) => answer.key === 'employed')
        if (employedAnswer) {
          employedAnswerId = employedAnswer.id
        }
      }

      if (question.widget.name === 'trading_source_fund') {
        const otherAnswer = question.answers.find((answer) => answer.key === 'other')
        if (otherAnswer) {
          otherAnswerId = otherAnswer.id
        }
      }
    })
  })

  for (const section of accountKycTest.sections) {
    for (const question of section.questions) {
      const questionId = question.question.id
      const answerKey = question.widget.name
      const isOtherTradingInfoSelected = selectedAnswers['trading_source_fund'] === otherAnswerId
      const isEmployedAnswered = selectedAnswers['employment_status'] === employedAnswerId

      const isQuestionMandatory =
        question.isMandatory ||
        (answerKey === 'employment_type' && isEmployedAnswered) ||
        (answerKey === 'trading_source_fund_other' && isOtherTradingInfoSelected)

      if (isQuestionMandatory && !selectedAnswers[answerKey] && !freeAnswers[questionId]) {
        return false
      }
    }
  }

  return true
}

export interface YearlyKycUpdateDetailsFormValues {
  clientId: string
  emailAddress: string
  countryCode: string
  countryName: string
  phoneNumber: string
  state: string
  city: string
  street: string
  // streetHouseNo: string
  // streetHouseNoNotAvailable?: boolean
  zipCode: string
  taxIdentificationNumber: string
  kycUpdateTestId: string
  taxIdAvailable: boolean

  selectedAnswers: { [key: string]: string }
  freeAnswers: { [key: string]: string }

  documents: FileData[]
  accountKycTest: TestSectionsDto | undefined
  countries: CountryPreferenceStrippedDto[]
  taxIdUnavailableReasonId?: number
}

export const YearlyKycUpdateDetailsFormUI: React.FC<
  FormikProps<YearlyKycUpdateDetailsFormValues> & OuterProps
> = (props) => {
  const {
    values,
    account,
    handleSubmit,
    isSubmitting,
    initialValues,
    setFieldError,
    errors,
    tinUnavailableReasons,
    submitCount,
    isValid,
  } = props

  const { t } = useTranslation()
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)

  useEffect(() => {
    if (props.account?.isDormantUpdateNeeded && !account?.isKYCUpdateNeeded) {
      const isAnyFieldChanged =
        initialValues.street !== values.street ||
        initialValues.city !== values.city ||
        initialValues.zipCode !== values.zipCode ||
        initialValues.state !== values.state ||
        initialValues.countryCode !== values.countryCode ||
        initialValues.phoneNumber !== values.phoneNumber

      setIsSubmitEnabled(submitCount === 0 || isAnyFieldChanged)
      return
    }

    if (!props.accountKycTest) {
      return
    }

    const isFormValid = areAllMandatoryQuestionsAnswered(
      props.accountKycTest,
      values.selectedAnswers,
      values.freeAnswers
    )

    const isProofOfAddressNeeded = proofOfAddressNeeded()
    const isDocumentUploaded =
      !isProofOfAddressNeeded ||
      (values.documents && values.documents.some((doc) => doc && doc.fileName && !doc.error))

    setIsSubmitEnabled(isFormValid && (!isProofOfAddressNeeded || isDocumentUploaded))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.accountKycTest,
    values.selectedAnswers,
    values.freeAnswers,
    values.documents,
    values.street,
    // values.streetHouseNo,
    values.city,
    values.zipCode,
    values.state,
    values.countryCode,
    values.phoneNumber,
    submitCount,
  ])

  const handleSubmitForm = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const proofOfAddressNeeded = () =>
    initialValues.street !== values.street ||
    initialValues.city !== values.city ||
    initialValues.zipCode !== values.zipCode ||
    initialValues.state !== values.state

  if (
    account?.isKYCUpdateNeeded &&
    proofOfAddressNeeded() &&
    !('documents' in errors) &&
    !values.documents.filter((x) => !!x?.fileName).filter((x) => !x.error).length
  ) {
    setFieldError('documents', '')
  }

  return (
    <Loading isLoading={isSubmitting} showLoadingIcon text={t('Wallet.Submitting')}>
      <div>
        <Form onSubmit={handleSubmitForm}>
          {!props.productRegistrationRequirement && (
            <PageHeader hasExtraMarginTop title={t('Profile.Are your details up to date?')} />
          )}
          <div className={styles.wrapper}>
            {account && (
              <KycUpdateDetailsForm
                account={account}
                proofOfAddressNeeded={proofOfAddressNeeded}
                tinUnavailableReasons={tinUnavailableReasons}
                productRegistrationRequirement={props.productRegistrationRequirement}
              />
            )}
          </div>
          <div className={styles.submitWrapper}>
            <Button
              appearance='primary'
              size='L'
              type='submit'
              disabled={!isSubmitEnabled || !isValid}
            >
              {t('Submit')}
            </Button>
          </div>
        </Form>
      </div>
    </Loading>
  )
}

interface KycUpdateDetailsFormProps {
  account: AccountDetailedDto | undefined
  proofOfAddressNeeded: () => boolean
  productRegistrationRequirement?: boolean
  tinUnavailableReasons: NameDto[]
}

const KycUpdateDetailsForm: React.FC<KycUpdateDetailsFormProps> = (props) => {
  const { account, proofOfAddressNeeded, tinUnavailableReasons } = props
  const { isDormantUpdateNeeded, isKYCUpdateNeeded } = account || {}

  if (isKYCUpdateNeeded || props.productRegistrationRequirement) {
    return (
      <React.Fragment>
        <div className={styles.section}>
          <YearlyKycPersonalInfoForm
            account={account}
            proofOfAddressNeeded={proofOfAddressNeeded}
            tinUnavailableReasons={tinUnavailableReasons}
            productRegistrationRequirement={props.productRegistrationRequirement}
          />
        </div>

        {(isKYCUpdateNeeded || props.productRegistrationRequirement) && (
          <div className={styles.section}>
            <YearlyKycTestForm />
          </div>
        )}
      </React.Fragment>
    )
  }

  if (isDormantUpdateNeeded) {
    return (
      <div className={styles.section}>
        <YearlyKycPersonalInfoForm
          account={account}
          proofOfAddressNeeded={proofOfAddressNeeded}
          tinUnavailableReasons={tinUnavailableReasons}
          productRegistrationRequirement={props.productRegistrationRequirement}
        />
      </div>
    )
  }

  return null
}

interface OuterProps {
  account: AccountDetailedDto | undefined
  countries: CountryPreferenceStrippedDto[]
  accountKycTest: TestSectionsDto | undefined
  onSubmit(values: YearlyKycUpdateDetailsFormValues): void
  tinUnavailableReasons: NameDto[]
  productRegistrationRequirement?: boolean
}

const initialState = ({ account }: { account: AccountDetailedDto | undefined }) => {
  const phoneNumbers = account?.phoneNumbers ?? []
  const primaryPhoneNumber = phoneNumbers.find((phoneNumber) => phoneNumber.isPrimary)
  const [addresses] = account?.addresses || []
  const [email] = account?.emails || []

  return {
    clientId: account?.id || '',
    emailAddress: email?.address,
    countryCode: primaryPhoneNumber?.countryCode || '',
    countryName: addresses?.country?.name,
    phoneNumber: primaryPhoneNumber?.number || '',
    state: addresses?.state || '',
    // streetHouseNo: addresses.streetHouseNo ?? '',
    // streetHouseNoNotAvailable: !!addresses.streetHouseNo,
    city: addresses?.city || '',
    street: addresses?.street || '',
    zipCode: addresses?.postalCode || '',
    taxIdentificationNumber: account?.taxId || '',
    kycUpdateTestId: '',
    taxIdUnavailableReasonId: account?.taxIdAvailabilityStatus?.id,
  }
}

const dataToSubmit = (
  values: YearlyKycUpdateDetailsFormValues,
  sections: readonly TestSectionDto[]
): YearlyKycUpdateDetailsFormValues => {
  const cleanSelectedAnswers = getCleanedAnswers(values.selectedAnswers, sections)
  const cleanFreeAnswers = getCleanedAnswers(values.freeAnswers, sections)

  return {
    ...values,
    selectedAnswers: cleanSelectedAnswers,
    freeAnswers: cleanFreeAnswers,
  }
}

export const YearlyKycUpdateDetailsForm = withFormik<OuterProps, YearlyKycUpdateDetailsFormValues>({
  mapPropsToValues: ({ account, countries, accountKycTest }) => {
    return {
      ...initialState({ account }),
      accountKycTest: account?.isKYCUpdateNeeded ? accountKycTest : undefined,
      selectedAnswers: {},
      freeAnswers: {},
      documents: [],
      countries: countries || [],
      taxIdAvailable: !!account?.taxId,
    }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(dataToSubmit(values, props.accountKycTest?.sections || []))
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<YearlyKycUpdateDetailsFormValues> = {}
    if (!values?.countryCode) {
      errors.countryCode = t('Validation.Required')
    }

    if (!values?.phoneNumber) {
      errors.phoneNumber = t('Validation.Required')
    }

    if (!values?.state) {
      errors.state = t('Validation.Required')
    }

    if (values.state && !CityStatePattern.test(values.state)) {
      errors.state = t('Validation.Invalid state')
    }

    if (!values?.city) {
      errors.city = t('Validation.Required')
    }

    if (values.city && !CityStatePattern.test(values.city)) {
      errors.city = t('Validation.Invalid city')
    }

    if (!values?.street) {
      errors.street = t('Validation.Required')
    }

    if (values.street && !AddressLettersPattern.test(values.street)) {
      errors.street = t('Validation.Address Line contains unsupported characters')
    }

    // if (values.streetHouseNo && !AddressLettersPattern.test(values.streetHouseNo)) {
    //   errors.streetHouseNo = t('Validation.Address Line contains unsupported characters')
    // }
    //
    // if (!values.streetHouseNoNotAvailable && !values.streetHouseNo) {
    //   errors.streetHouseNo = t('Validation.Required')
    // }
    //
    // if (values.streetHouseNo.length > 20) {
    //   errors.streetHouseNo = t('Validation.Max {{amount}} characters allowed', {
    //     amount: 20,
    //   })
    // }

    if (!values?.zipCode) {
      errors.zipCode = t('Validation.Required')
    }

    if (values?.zipCode.length > 32) {
      errors.zipCode = t('Validation.Maximum character limit is 32')
    }

    if (values.zipCode && !ZipCodePattern.test(values.zipCode)) {
      errors.zipCode = t('Validation.Invalid postal code')
    }

    if (!isTickmillSC(props.account)) {
      // if (!values?.taxIdentificationNumber && !!values.taxIdAvailable) {
      //   errors.taxIdentificationNumber = t('Validation.Required')
      // }

      // if (!values?.taxIdUnavailableReasonId && !values.taxIdAvailable) {
      //   errors.taxIdUnavailableReasonId = t('Validation.Required')
      // }

      if (!values.taxIdentificationNumber) {
        errors.taxIdentificationNumber = t('Validation.Required')
      }
    }

    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(YearlyKycUpdateDetailsFormUI)
