import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { mapAcuityLocale } from '../../global/locale/Locale'
import { AcquityHeader } from '../AcquityHeader'

declare const window: Window & { AcuityWidgets: any }

export const EarningsCalendar: React.FC = () => {
  const { t } = useTranslation()
  const calendarWidgetRef = useRef(null)
  const locale = useSessionLanguage()

  useEffect(() => {
    window.AcuityWidgets.globals({
      apikey: '9d89f8b5-367f-4e92-b7cf-12919b431d45',
      locale: mapAcuityLocale(locale),
    })

    const widget = window.AcuityWidgets.CreateWidget('acuitycalendar', calendarWidgetRef.current, {
      primaryBackgroundColor: 'rgba(255, 255, 255, 1)',
      secondaryBackgroundColor: 'rgba(233, 233, 233, 1)',
      accentColor: 'rgba(57, 181, 74, 1)',
      controlsColor1: 'rgba(181, 181, 181, 1)',
      controlsColor2: 'rgba(173, 173, 173, 1)',
      controlsColor3: 'rgba(48, 48, 48, 1)',
      primaryFontColor: 'rgba(48, 48, 48, 1)',
      secondaryFontColor: 'rgba(48, 48, 48, 1)',
      earningsColor: 'rgba(98, 33, 234, 1)',
      earningsAccentColor: 'rgba(98, 33, 234, 1)',
      dividendsColor: 'rgba(204, 0, 0, 1)',
      dividendsAccentColor: 'rgba(204, 0, 0, 1)',
      callsWebcastsColor: 'rgba(57, 181, 74, 1)',
      callsWebcastsAccentColor: 'rgba(57, 181, 74, 1)',
      stockSplitsColor: 'rgba(252, 191, 0, 1)',
      stockSplitsAccentColor: 'rgba(252, 191, 0, 1)',
      mergersAndAcquisitionsColor: 'rgba(48, 48, 48, 1)',
      positiveColor: 'rgba(57, 181, 74, 1)',
      negativeColor: 'rgba(204, 0, 0, 1)',
      negativeColor2: 'rgba(204, 0, 0, 1)',
      lowImpactColor: 'rgba(57, 181, 74, 1)',
      lowImpactAccentColor: 'rgba(57, 181, 74, 1)',
      highImpactColor: 'rgba(204, 0, 0, 1)',
      highImpactAccentColor: 'rgba(204, 0, 0, 1)',
      watchlistName: 'TickmillStocks',
      enableOneCalendarType: true,
      ctaBackgroundColor: 'rgba(57, 181, 74, 1)',
      ctaTextColor: 'rgba(255, 255, 255, 1)',
      settingId: 1281,
    })
    widget.mount()
  }, [locale])

  return (
    <>
      <AcquityHeader title={t('Earnings Calendar')} description={t('Use the earnings calendar')} />
      <div id='acuity-calendar' ref={calendarWidgetRef} />
    </>
  )
}
