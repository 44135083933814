import { FC, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { getTransactionBadgeLabel } from '../../../Traders-Room/Transaction-History/TransactionBadgeStatus'
import { Loading } from '../../../global/Loading/Loading'
import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { TransactionDepositIcon } from '../../../icons/TransactionDepositIcon'
import { TransactionTransferIcon } from '../../../icons/TransactionTransferIcon'
import { TransactionWithdrawIcon } from '../../../icons/TransactionWithdrawIcon'
import { Tooltip } from '../../../ui/Popups/Tooltip/Tooltip'
import { TextSmall, TextSmallStrong, TextStrong } from '../../../ui/Typography/Typography'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { formatDate } from '../../../utils/date.utils'
import {
  TransactionType,
  getIBTransactionFieldsByTransactionType,
} from '../../../utils/transaction.utils'
import { EmptyTransactionHistory } from '../EmptyTransactionHistory/EmptyTransactionHistory'
import { HorizontalScroll } from '../components/HorizontalScroll/HorizontalScroll'
import { SectionHeader } from '../components/SectionHeader/SectionHeader'
import { WalletWithLatestTransactions } from '../hooks/useWalletsWithLatestTransactions'

import styles from './TransactionHistory.module.scss'

interface IBTransactionHistoryProps {
  walletsWithLatestTransactions: WalletWithLatestTransactions[]
  isLoading: boolean
  className?: string
}

const GREEN_TYPES: TransactionType[] = [
  TransactionType.IBCommission,
  TransactionType.LoyaltyRewardPayout,
  TransactionType.MIBCommission,
  TransactionType.MonthlyInterestTransfer,
  TransactionType.MultiTierIBCommission,
  TransactionType.WalletDeposit,
]

const GREY_TYPES: TransactionType[] = [
  TransactionType.WalletWithdrawal,
  TransactionType.Reversed,
  TransactionType.IBCashBacks,
  TransactionType.WithdrawToPaymentAgent,
]

const BLUE_TYPES: TransactionType[] = [
  TransactionType.TransferFromTradingAccount,
  TransactionType.TransferToTradingAccount,
  TransactionType.TradingAccountBalanceConversion,
]

export const isTransactionPositive = (transactionType: TransactionType): boolean => {
  return GREEN_TYPES.includes(transactionType)
}

const getIconByTransactionTypeName = (transactionType: TransactionType): ReactNode => {
  if (GREEN_TYPES.includes(transactionType)) {
    return <TransactionDepositIcon />
  } else if (GREY_TYPES.includes(transactionType)) {
    return <TransactionWithdrawIcon />
  } else if (BLUE_TYPES.includes(transactionType)) {
    return <TransactionTransferIcon />
  } else {
    return <TransactionTransferIcon />
  }
}

export const TransactionHistory: FC<IBTransactionHistoryProps> = (props) => {
  const { walletsWithLatestTransactions, isLoading, className } = props

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { formatMoney } = useFormatNumber()
  const [auth] = useContext(AuthSessionContext)

  const dateFormat = auth?.dateFormatType?.name
  const hasAnyTransactions = walletsWithLatestTransactions.some(
    (walletWithLatestTransaction) => walletWithLatestTransaction.latestTransactions.length
  )

  return (
    <div className={classNames(styles.ibTransactionHistory, className)}>
      <SectionHeader
        title={t('IB.Dashboard.Transaction history')}
        seeMoreLinkProps={{ to: '/dashboard/introducing-broker/transaction-history' }}
      />

      <Loading isLoading={isLoading} showLoadingIcon>
        {walletsWithLatestTransactions.length && hasAnyTransactions ? (
          <HorizontalScroll
            containerClassName={styles.transactionsContainer}
            innerClassName={styles.transactionsContainerScroll}
          >
            {walletsWithLatestTransactions.slice(0, 2).map((walletWithLatestTransaction) => {
              const { wallet, latestTransactions } = walletWithLatestTransaction

              if (!latestTransactions.length) {
                return null
              }

              return (
                <div className={styles.transactionsWrapper} key={wallet.id}>
                  <TextStrong>{wallet.name}</TextStrong>

                  {latestTransactions.slice(0, 3).map((transaction) => {
                    const fields = transaction
                      ? getIBTransactionFieldsByTransactionType(
                          transaction.type.id,
                          transaction,
                          isArabic,
                          auth?.id
                        )
                      : undefined

                    const transactionStatus = getTransactionBadgeLabel(transaction.state.id, t)
                    const isPositive = isTransactionPositive(
                      transaction.walletTransactions[0].directionType.id
                    )
                    return (
                      <div className={styles.transaction} key={transaction.id}>
                        <div className={styles.transactionIcon}>
                          {getIconByTransactionTypeName(transaction.type.id)}
                        </div>
                        <div className={styles.transactionDetails}>
                          <div className={styles.transactionDetailsCol}>
                            <Tooltip
                              content={transaction.type.name}
                              wrapperClassName={styles.tooltipWrapper}
                              direction='topLeft'
                            >
                              <TextSmallStrong className={styles.transactionName}>
                                {transaction.type.name}
                              </TextSmallStrong>
                            </Tooltip>
                            <TextSmall>
                              {formatDate(transaction.createdDate, { formatType: dateFormat })}
                            </TextSmall>
                          </div>
                          <div className={styles.transactionDetailsCol}>
                            {fields && (
                              <TextStrong className={classNames(isPositive && styles.amountGreen)}>
                                <span>{isPositive ? '+' : '-'}</span>
                                {formatMoney(fields.amount, fields.currency)}
                              </TextStrong>
                            )}

                            <Tooltip
                              content={transactionStatus}
                              wrapperClassName={classNames(styles.tooltipWrapper, styles.alignEnd)}
                            >
                              <TextSmall className={styles.transactionName}>
                                {transactionStatus}
                              </TextSmall>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </HorizontalScroll>
        ) : (
          <EmptyTransactionHistory />
        )}
      </Loading>
    </div>
  )
}
