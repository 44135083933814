import styles from './Icons.module.scss'

export function FundWalletIcon() {
  return (
    <svg
      width='242'
      height='235'
      viewBox='0 0 242 235'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5334_203089)'>
        <path
          d='M64.7179 42.123L3.0858 81.0368C1.95757 81.7492 1.62272 83.2377 2.33789 84.3614L95.0541 230.052C95.7692 231.176 97.2636 231.509 98.3918 230.797L160.024 191.883C161.152 191.171 161.487 189.682 160.772 188.558L68.0557 42.868C67.3405 41.7442 65.8462 41.4107 64.7179 42.123Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M62.5384 53.0971L13.9803 83.7561C12.8521 84.4684 12.5173 85.9569 13.2324 87.0807L97.2338 219.077C97.949 220.201 99.4433 220.534 100.572 219.822L149.13 189.163C150.258 188.451 150.593 186.962 149.877 185.838L65.8761 53.842C65.1609 52.7183 63.6666 52.3847 62.5384 53.0971Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M21.2383 89.9216C22.8413 89.9216 24.1407 88.6273 24.1407 87.0306C24.1407 85.434 22.8413 84.1396 21.2383 84.1396C19.6354 84.1396 18.3359 85.434 18.3359 87.0306C18.3359 88.6273 19.6354 89.9216 21.2383 89.9216Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M25.3751 96.4382C26.978 96.4382 28.2775 95.1439 28.2775 93.5472C28.2775 91.9506 26.978 90.6562 25.3751 90.6562C23.7721 90.6562 22.4727 91.9506 22.4727 93.5472C22.4727 95.1439 23.7721 96.4382 25.3751 96.4382Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M29.5235 102.943C31.1265 102.943 32.4259 101.649 32.4259 100.052C32.4259 98.4555 31.1265 97.1611 29.5235 97.1611C27.9205 97.1611 26.6211 98.4555 26.6211 100.052C26.6211 101.649 27.9205 102.943 29.5235 102.943Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M81.5472 153.812C91.165 153.812 98.9617 146.046 98.9617 136.466C98.9617 126.886 91.165 119.12 81.5472 119.12C71.9295 119.12 64.1328 126.886 64.1328 136.466C64.1328 146.046 71.9295 153.812 81.5472 153.812Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M137.871 173.126C137.013 171.777 135.219 171.377 133.865 172.232C132.512 173.087 132.11 174.873 132.968 176.222L140.22 187.616C141.078 188.965 142.871 189.365 144.225 188.51C145.579 187.655 145.98 185.869 145.122 184.521L137.871 173.126Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M29.5235 102.943C31.1265 102.943 32.4259 101.649 32.4259 100.052C32.4259 98.4555 31.1265 97.1611 29.5235 97.1611C27.9205 97.1611 26.6211 98.4555 26.6211 100.052C26.6211 101.649 27.9205 102.943 29.5235 102.943Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M131.277 171.05C132.88 171.05 134.18 169.755 134.18 168.159C134.18 166.562 132.88 165.268 131.277 165.268C129.674 165.268 128.375 166.562 128.375 168.159C128.375 169.755 129.674 171.05 131.277 171.05Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M68.5682 17.8251L2.04102 47.6893C0.823185 48.236 0.280867 49.6626 0.829716 50.8756L71.9842 208.137C72.5331 209.35 73.9653 209.891 75.1831 209.344L141.71 179.48C142.928 178.933 143.47 177.506 142.922 176.293L71.7671 19.0317C71.2182 17.8186 69.7861 17.2784 68.5682 17.8251Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M64.8542 28.3839L12.4395 51.913C11.2216 52.4597 10.6793 53.8862 11.2282 55.0992L75.6945 197.579C76.2434 198.792 77.6756 199.333 78.8934 198.786L131.308 175.257C132.526 174.71 133.068 173.284 132.519 172.071L68.053 29.5904C67.5042 28.3774 66.072 27.8372 64.8542 28.3839Z'
          fill='#C0E3FA'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M19.1563 59.0598C20.7593 59.0598 22.0587 57.7654 22.0587 56.1688C22.0587 54.5722 20.7593 53.2778 19.1563 53.2778C17.5534 53.2778 16.2539 54.5722 16.2539 56.1688C16.2539 57.7654 17.5534 59.0598 19.1563 59.0598Z'
          fill='white'
        />
        <path
          d='M22.336 66.0827C23.939 66.0827 25.2384 64.7884 25.2384 63.1918C25.2384 61.5951 23.939 60.3008 22.336 60.3008C20.733 60.3008 19.4336 61.5951 19.4336 63.1918C19.4336 64.7884 20.733 66.0827 22.336 66.0827Z'
          fill='white'
        />
        <path
          d='M71.8715 130.925C81.4892 130.925 89.2859 123.159 89.2859 113.579C89.2859 103.999 81.4892 96.2329 71.8715 96.2329C62.2537 96.2329 54.457 103.999 54.457 113.579C54.457 123.159 62.2537 130.925 71.8715 130.925Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M122.446 157.794C121.787 156.339 120.068 155.69 118.607 156.346C117.146 157.002 116.495 158.714 117.153 160.17L122.719 172.47C123.377 173.925 125.096 174.574 126.557 173.917C128.019 173.261 128.669 171.55 128.011 170.094L122.446 157.794Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M25.5196 73.1174C27.1225 73.1174 28.422 71.8231 28.422 70.2264C28.422 68.6298 27.1225 67.3354 25.5196 67.3354C23.9166 67.3354 22.6172 68.6298 22.6172 70.2264C22.6172 71.8231 23.9166 73.1174 25.5196 73.1174Z'
          fill='white'
        />
        <path
          d='M116.617 154.836C118.22 154.836 119.52 153.541 119.52 151.945C119.52 150.348 118.22 149.054 116.617 149.054C115.014 149.054 113.715 150.348 113.715 151.945C113.715 153.541 115.014 154.836 116.617 154.836Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M87.2429 6.29521L17.0984 26.3296C15.8144 26.6963 15.0719 28.0305 15.4401 29.3095L63.1741 195.122C63.5423 196.401 64.8817 197.141 66.1657 196.774L136.31 176.739C137.594 176.373 138.337 175.039 137.969 173.76L90.2345 7.94698C89.8663 6.66799 88.5269 5.92847 87.2429 6.29521Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M82.0466 16.2131L26.782 31.9976C25.498 32.3643 24.7555 33.6984 25.1237 34.9774L68.371 185.205C68.7392 186.484 70.0786 187.223 71.3626 186.856L126.627 171.072C127.911 170.705 128.654 169.371 128.286 168.092L85.0383 17.8649C84.6701 16.5859 83.3307 15.8464 82.0466 16.2131Z'
          fill='#C0E3FA'
        />
        <path
          d='M32.8204 40.0642C34.4233 40.0642 35.7228 38.7698 35.7228 37.1732C35.7228 35.5766 34.4233 34.2822 32.8204 34.2822C31.2174 34.2822 29.918 35.5766 29.918 37.1732C29.918 38.7698 31.2174 40.0642 32.8204 40.0642Z'
          fill='white'
        />
        <path
          d='M34.961 47.4724C36.564 47.4724 37.8634 46.178 37.8634 44.5814C37.8634 42.9848 36.564 41.6904 34.961 41.6904C33.358 41.6904 32.0586 42.9848 32.0586 44.5814C32.0586 46.178 33.358 47.4724 34.961 47.4724Z'
          fill='white'
        />
        <path
          d='M76.7074 118.879C86.3251 118.879 94.1218 111.113 94.1218 101.533C94.1218 91.9535 86.3251 84.1875 76.7074 84.1875C67.0897 84.1875 59.293 91.9535 59.293 101.533C59.293 111.113 67.0897 118.879 76.7074 118.879Z'
          fill='white'
        />
        <path
          d='M120.38 152.522C119.938 150.987 118.331 150.099 116.79 150.54C115.249 150.98 114.358 152.581 114.8 154.115L118.533 167.084C118.975 168.619 120.583 169.506 122.123 169.066C123.664 168.626 124.555 167.025 124.113 165.49L120.38 152.522Z'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M37.0899 54.8923C38.6929 54.8923 39.9923 53.598 39.9923 52.0013C39.9923 50.4047 38.6929 49.1104 37.0899 49.1104C35.4869 49.1104 34.1875 50.4047 34.1875 52.0013C34.1875 53.598 35.4869 54.8923 37.0899 54.8923Z'
          fill='white'
        />
        <path
          d='M115.457 148.789C117.06 148.789 118.359 147.494 118.359 145.898C118.359 144.301 117.06 143.007 115.457 143.007C113.854 143.007 112.555 144.301 112.555 145.898C112.555 147.494 113.854 148.789 115.457 148.789Z'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M170.841 95.5825C180.132 95.5825 187.663 88.0808 187.663 78.8269C187.663 69.573 180.132 62.0713 170.841 62.0713C161.551 62.0713 154.02 69.573 154.02 78.8269C154.02 88.0808 161.551 95.5825 170.841 95.5825Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M170.84 91.7762C178.02 91.7762 183.841 85.9787 183.841 78.8271C183.841 71.6755 178.02 65.8779 170.84 65.8779C163.66 65.8779 157.84 71.6755 157.84 78.8271C157.84 85.9787 163.66 91.7762 170.84 91.7762Z'
          fill='#FAEAC0'
        />
        <path
          d='M192.127 132.117C199.474 132.117 205.43 126.185 205.43 118.867C205.43 111.549 199.474 105.617 192.127 105.617C184.78 105.617 178.824 111.549 178.824 118.867C178.824 126.185 184.78 132.117 192.127 132.117Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M192.127 129.106C197.804 129.106 202.406 124.522 202.406 118.867C202.406 113.212 197.804 108.628 192.127 108.628C186.45 108.628 181.848 113.212 181.848 118.867C181.848 124.522 186.45 129.106 192.127 129.106Z'
          fill='#FAEAC0'
        />
        <path
          d='M228.092 58.2408C235.439 58.2408 241.394 52.3085 241.394 44.9905C241.394 37.6726 235.439 31.7402 228.092 31.7402C220.745 31.7402 214.789 37.6726 214.789 44.9905C214.789 52.3085 220.745 58.2408 228.092 58.2408Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M228.092 55.2292C233.769 55.2292 238.371 50.6451 238.371 44.9903C238.371 39.3356 233.769 34.7515 228.092 34.7515C222.415 34.7515 217.812 39.3356 217.812 44.9903C217.812 50.6451 222.415 55.2292 228.092 55.2292Z'
          fill='#FAEAC0'
        />
        <path
          d='M226.796 109.676C232.139 109.676 236.47 105.362 236.47 100.039C236.47 94.7173 232.139 90.4028 226.796 90.4028C221.453 90.4028 217.121 94.7173 217.121 100.039C217.121 105.362 221.453 109.676 226.796 109.676Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M226.794 107.484C230.922 107.484 234.268 104.151 234.268 100.039C234.268 95.9281 230.922 92.5952 226.794 92.5952C222.666 92.5952 219.32 95.9281 219.32 100.039C219.32 104.151 222.666 107.484 226.794 107.484Z'
          fill='#FAEAC0'
        />
        <path
          d='M191.35 47.2312C196.694 47.2312 201.025 42.9167 201.025 37.5946C201.025 32.2725 196.694 27.958 191.35 27.958C186.007 27.958 181.676 32.2725 181.676 37.5946C181.676 42.9167 186.007 47.2312 191.35 47.2312Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M191.349 45.0389C195.476 45.0389 198.822 41.706 198.822 37.5946C198.822 33.4833 195.476 30.1504 191.349 30.1504C187.221 30.1504 183.875 33.4833 183.875 37.5946C183.875 41.706 187.221 45.0389 191.349 45.0389Z'
          fill='#FAEAC0'
        />
        <path
          d='M214.171 19.8752C219.514 19.8752 223.845 15.5608 223.845 10.2386C223.845 4.91649 219.514 0.602051 214.171 0.602051C208.828 0.602051 204.496 4.91649 204.496 10.2386C204.496 15.5608 208.828 19.8752 214.171 19.8752Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M214.169 17.6829C218.297 17.6829 221.643 14.35 221.643 10.2387C221.643 6.12734 218.297 2.79443 214.169 2.79443C210.041 2.79443 206.695 6.12734 206.695 10.2387C206.695 14.35 210.041 17.6829 214.169 17.6829Z'
          fill='#FAEAC0'
        />
        <path
          d='M152.491 10H79.9307C70.5801 10 63 17.5503 63 26.864V215.982C63 225.296 70.5801 232.846 79.9307 232.846H152.491C161.841 232.846 169.421 225.296 169.421 215.982V26.864C169.421 17.5503 161.841 10 152.491 10Z'
          fill='#D9D9D9'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M152.67 11.9609H80.1097C72.0949 11.9609 65.5977 18.4326 65.5977 26.4158V215.534C65.5977 223.517 72.0949 229.989 80.1097 229.989H152.67C160.685 229.989 167.182 223.517 167.182 215.534V26.4158C167.182 18.4326 160.685 11.9609 152.67 11.9609Z'
          fill='white'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M146.956 90.5205C146.328 90.5205 145.787 90.3718 145.333 90.0745C144.879 89.7749 144.529 89.3453 144.283 88.7858C144.037 88.224 143.916 87.5486 143.918 86.7597C143.92 85.9708 144.043 85.3013 144.287 84.7512C144.533 84.1987 144.881 83.7785 145.333 83.4906C145.787 83.2003 146.328 83.0552 146.956 83.0552C147.583 83.0552 148.124 83.2003 148.578 83.4906C149.034 83.7785 149.386 84.1987 149.631 84.7512C149.877 85.3037 149.999 85.9732 149.997 86.7597C149.997 87.551 149.874 88.2275 149.628 88.7894C149.382 89.3512 149.032 89.7807 148.578 90.0781C148.126 90.373 147.585 90.5205 146.956 90.5205ZM146.956 89.0984C147.33 89.0984 147.633 88.9076 147.865 88.526C148.097 88.1421 148.212 87.5533 148.209 86.7597C148.209 86.2401 148.156 85.8117 148.051 85.4746C147.946 85.1351 147.799 84.8823 147.612 84.7161C147.425 84.5499 147.206 84.4668 146.956 84.4668C146.583 84.4668 146.282 84.6552 146.053 85.0321C145.824 85.4067 145.708 85.9825 145.705 86.7597C145.703 87.2865 145.753 87.723 145.856 88.0695C145.962 88.416 146.109 88.6746 146.299 88.8455C146.488 89.0141 146.707 89.0984 146.956 89.0984Z'
          fill='#424244'
        />
        <path
          d='M140.049 90.5205C139.422 90.5205 138.881 90.3718 138.427 90.0745C137.973 89.7749 137.623 89.3453 137.377 88.7858C137.131 88.224 137.009 87.5486 137.012 86.7597C137.014 85.9708 137.137 85.3013 137.38 84.7512C137.626 84.1987 137.975 83.7785 138.427 83.4906C138.881 83.2003 139.422 83.0552 140.049 83.0552C140.677 83.0552 141.217 83.2003 141.672 83.4906C142.128 83.7785 142.479 84.1987 142.725 84.7512C142.971 85.3037 143.093 85.9732 143.09 86.7597C143.09 87.551 142.967 88.2275 142.722 88.7894C142.476 89.3512 142.126 89.7807 141.672 90.0781C141.22 90.373 140.679 90.5205 140.049 90.5205ZM140.049 89.0984C140.424 89.0984 140.727 88.9076 140.959 88.526C141.191 88.1421 141.305 87.5533 141.303 86.7597C141.303 86.2401 141.25 85.8117 141.145 85.4746C141.04 85.1351 140.893 84.8823 140.706 84.7161C140.519 84.5499 140.3 84.4668 140.049 84.4668C139.677 84.4668 139.376 84.6552 139.147 85.0321C138.917 85.4067 138.801 85.9825 138.799 86.7597C138.797 87.2865 138.847 87.723 138.95 88.0695C139.055 88.416 139.203 88.6746 139.393 88.8455C139.582 89.0141 139.801 89.0984 140.049 89.0984Z'
          fill='#424244'
        />
        <path
          d='M135.444 90.4468C135.191 90.4468 134.975 90.3578 134.795 90.1799C134.617 90.002 134.529 89.7866 134.531 89.5338C134.529 89.2856 134.617 89.0738 134.795 88.8982C134.975 88.7203 135.191 88.6313 135.444 88.6313C135.683 88.6313 135.894 88.7203 136.076 88.8982C136.261 89.0738 136.355 89.2856 136.357 89.5338C136.355 89.7023 136.31 89.8557 136.224 89.9938C136.14 90.1319 136.028 90.2419 135.89 90.3239C135.754 90.4058 135.606 90.4468 135.444 90.4468Z'
          fill='#424244'
        />
        <path
          d='M130.905 90.4432C130.357 90.4432 129.871 90.3496 129.448 90.1623C129.026 89.9727 128.694 89.7117 128.45 89.3793C128.207 89.0469 128.083 88.6641 128.078 88.231H129.827C129.834 88.3879 129.884 88.5272 129.978 88.6489C130.072 88.7683 130.199 88.8619 130.361 88.9298C130.522 88.9977 130.706 89.0316 130.912 89.0316C131.118 89.0316 131.299 88.9954 131.456 88.9228C131.615 88.8479 131.74 88.746 131.828 88.6173C131.917 88.4862 131.961 88.3364 131.958 88.1678C131.961 87.9993 131.913 87.8495 131.814 87.7184C131.716 87.5873 131.577 87.4854 131.397 87.4129C131.219 87.3403 131.008 87.304 130.764 87.304H130.066V86.068H130.764C130.978 86.068 131.165 86.0329 131.326 85.9626C131.49 85.8924 131.618 85.7941 131.709 85.6677C131.8 85.5389 131.845 85.3915 131.843 85.2252C131.845 85.0637 131.806 84.9221 131.727 84.8004C131.649 84.6763 131.541 84.5803 131.4 84.5124C131.262 84.4445 131.102 84.4106 130.919 84.4106C130.727 84.4106 130.553 84.4445 130.396 84.5124C130.241 84.5803 130.118 84.6763 130.027 84.8004C129.936 84.9244 129.888 85.0684 129.883 85.2323H128.222C128.227 84.8039 128.346 84.427 128.58 84.1016C128.814 83.7738 129.133 83.5175 129.535 83.3326C129.94 83.1476 130.402 83.0552 130.919 83.0552C131.434 83.0552 131.887 83.1453 132.278 83.3256C132.669 83.5058 132.973 83.7516 133.191 84.063C133.409 84.372 133.518 84.7219 133.518 85.1129C133.52 85.5179 133.388 85.8515 133.121 86.1136C132.856 86.3758 132.516 86.5374 132.099 86.5982V86.6544C132.656 86.7199 133.076 86.9002 133.36 87.1952C133.645 87.4901 133.787 87.8588 133.784 88.3013C133.784 88.718 133.662 89.0878 133.416 89.4109C133.172 89.7316 132.833 89.9844 132.397 90.1693C131.964 90.3519 131.467 90.4432 130.905 90.4432Z'
          fill='#424244'
        />
        <path
          d='M121.371 89.1518V87.7964L124.317 83.1543H125.522V84.9943H124.823L123.099 87.7262V87.7824H127.365V89.1518H121.371ZM124.844 90.3457V88.7375L124.879 88.1441V83.1543H126.505V90.3457H124.844Z'
          fill='#424244'
        />
        <path
          d='M117.613 83.0552C118.006 83.0552 118.382 83.1196 118.74 83.2483C119.098 83.3748 119.418 83.5772 119.699 83.8558C119.98 84.1321 120.201 84.4961 120.362 84.9479C120.524 85.3997 120.605 85.951 120.605 86.6018C120.607 87.2011 120.537 87.7383 120.394 88.2135C120.251 88.6864 120.046 89.089 119.779 89.4215C119.515 89.7539 119.197 90.0079 118.824 90.1834C118.452 90.3567 118.037 90.4433 117.578 90.4433C117.072 90.4433 116.626 90.3461 116.24 90.1518C115.856 89.9552 115.548 89.6907 115.316 89.3582C115.087 89.0258 114.95 88.6536 114.905 88.2416H116.616C116.669 88.4804 116.783 88.6606 116.956 88.7824C117.129 88.9018 117.337 88.9615 117.578 88.9615C118.018 88.9615 118.347 88.7707 118.564 88.3891C118.782 88.0075 118.891 87.489 118.891 86.8335H118.849C118.748 87.0489 118.605 87.2338 118.421 87.3883C118.238 87.5428 118.026 87.661 117.785 87.743C117.544 87.8249 117.29 87.8659 117.023 87.8659C116.592 87.8659 116.212 87.7676 115.882 87.5709C115.554 87.3743 115.296 87.1039 115.109 86.7598C114.924 86.4157 114.831 86.0224 114.828 85.5799C114.826 85.0837 114.942 84.6459 115.176 84.2667C115.41 83.8851 115.736 83.5878 116.156 83.3748C116.575 83.1594 117.06 83.0529 117.613 83.0552ZM117.623 84.3896C117.408 84.3896 117.216 84.4399 117.047 84.5406C116.881 84.6412 116.75 84.777 116.654 84.9479C116.558 85.1188 116.511 85.3107 116.514 85.5238C116.516 85.7368 116.564 85.9287 116.658 86.0996C116.754 86.2705 116.884 86.4063 117.047 86.507C117.214 86.6076 117.403 86.6579 117.616 86.6579C117.776 86.6579 117.923 86.6287 118.059 86.5702C118.195 86.5116 118.313 86.4309 118.413 86.3279C118.514 86.2225 118.593 86.1008 118.649 85.9627C118.707 85.8246 118.735 85.6771 118.733 85.5202C118.731 85.3119 118.682 85.1223 118.586 84.9514C118.49 84.7805 118.357 84.6447 118.189 84.5441C118.023 84.4411 117.834 84.3896 117.623 84.3896Z'
          fill='#424244'
        />
        <path
          d='M113.854 89.3623L113.823 89.7521C113.794 90.0751 113.737 90.39 113.651 90.6967C113.566 91.0057 113.476 91.2831 113.38 91.5289C113.284 91.7747 113.208 91.9655 113.152 92.1012H112.07C112.108 91.9655 112.158 91.7747 112.221 91.5289C112.287 91.2831 112.348 91.0057 112.404 90.6967C112.46 90.3876 112.492 90.074 112.499 89.7556L112.506 89.3623H113.854Z'
          fill='#424244'
        />
        <path
          d='M105.879 90.3449V89.0948L108.502 86.7984C108.699 86.6205 108.866 86.4578 109.004 86.3103C109.142 86.1605 109.248 86.0106 109.32 85.8608C109.393 85.7087 109.429 85.5436 109.429 85.3657C109.429 85.1667 109.386 84.997 109.299 84.8565C109.212 84.7137 109.093 84.6037 108.941 84.5265C108.789 84.4492 108.614 84.4106 108.418 84.4106C108.219 84.4106 108.044 84.4516 107.894 84.5335C107.745 84.6131 107.628 84.729 107.543 84.8811C107.461 85.0333 107.42 85.2182 107.42 85.4359H105.773C105.773 84.9467 105.883 84.5241 106.104 84.1683C106.324 83.8125 106.633 83.5386 107.031 83.3466C107.431 83.1523 107.896 83.0552 108.425 83.0552C108.97 83.0552 109.444 83.1465 109.847 83.3291C110.25 83.5117 110.561 83.7668 110.781 84.0946C111.003 84.42 111.115 84.798 111.115 85.2288C111.115 85.5026 111.06 85.7742 110.95 86.0434C110.839 86.3126 110.642 86.6099 110.356 86.9353C110.073 87.2607 109.67 87.6505 109.148 88.1046L108.288 88.8982V88.9439H111.202V90.3449H105.879Z'
          fill='#424244'
        />
        <path
          d='M104.385 83.1543V90.3457H102.65V84.7766H102.608L101 85.7598V84.2569L102.773 83.1543H104.385Z'
          fill='#424244'
        />
        <path
          d='M127.878 17.5747H104.901C102.563 17.5747 100.668 19.4623 100.668 21.7907C100.668 24.1191 102.563 26.0067 104.901 26.0067H127.878C130.216 26.0067 132.111 24.1191 132.111 21.7907C132.111 19.4623 130.216 17.5747 127.878 17.5747Z'
          fill='#424244'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M127.876 24.1996C129.211 24.1996 130.294 23.121 130.294 21.7905C130.294 20.46 129.211 19.3813 127.876 19.3813C126.54 19.3813 125.457 20.46 125.457 21.7905C125.457 23.121 126.54 24.1996 127.876 24.1996Z'
          fill='white'
        />
        <path
          d='M63.782 54.3139C63.782 53.6487 63.2405 53.1094 62.5726 53.1094C61.9047 53.1094 61.3633 53.6487 61.3633 54.3139V62.746C61.3633 63.4112 61.9047 63.9505 62.5726 63.9505C63.2405 63.9505 63.782 63.4112 63.782 62.746V54.3139Z'
          fill='#424244'
        />
        <path
          d='M63.782 72.3828C63.782 71.7175 63.2405 71.1782 62.5726 71.1782C61.9047 71.1782 61.3633 71.7175 61.3633 72.3828V86.8377C61.3633 87.5029 61.9047 88.0422 62.5726 88.0422C63.2405 88.0422 63.782 87.5029 63.782 86.8377V72.3828Z'
          fill='#424244'
        />
        <path
          d='M63.782 94.0649C63.782 93.3997 63.2405 92.8604 62.5726 92.8604C61.9047 92.8604 61.3633 93.3997 61.3633 94.0649V108.52C61.3633 109.185 61.9047 109.724 62.5726 109.724C63.2405 109.724 63.782 109.185 63.782 108.52V94.0649Z'
          fill='#424244'
        />
        <path
          d='M171.411 83.2236C171.411 82.5583 170.869 82.019 170.202 82.019C169.534 82.019 168.992 82.5583 168.992 83.2236V109.724C168.992 110.389 169.534 110.929 170.202 110.929C170.869 110.929 171.411 110.389 171.411 109.724V83.2236Z'
          fill='#424244'
        />
        <path
          d='M148.193 108.911H77.3142C76.383 108.911 75.6211 109.67 75.6211 110.598C75.6211 111.525 76.383 112.284 77.3142 112.284H148.193C149.124 112.284 149.886 111.525 149.886 110.598C149.886 109.67 149.124 108.911 148.193 108.911Z'
          fill='#C6C6C7'
        />
        <path
          d='M132.472 116.03H77.3142C76.383 116.03 75.6211 116.789 75.6211 117.717C75.6211 118.644 76.383 119.403 77.3142 119.403H132.472C133.403 119.403 134.165 118.644 134.165 117.717C134.165 116.789 133.403 116.03 132.472 116.03Z'
          fill='#C6C6C7'
        />
        <path
          d='M103.629 123.27H77.3142C76.383 123.27 75.6211 124.028 75.6211 124.956C75.6211 125.883 76.383 126.642 77.3142 126.642H103.629C104.56 126.642 105.322 125.883 105.322 124.956C105.322 124.028 104.56 123.27 103.629 123.27Z'
          fill='#C6C6C7'
        />
        <path
          d='M82.2256 152.356H107.622C111.021 152.356 113.773 155.101 113.773 158.482C113.773 161.863 111.021 164.607 107.622 164.607H82.2256C78.8264 164.607 76.0742 161.863 76.0742 158.482C76.0742 155.101 78.8264 152.356 82.2256 152.356Z'
          stroke='#424244'
          strokeMiterlimit='10'
        />
        <path
          d='M150.551 151.856H125.155C121.482 151.856 118.504 154.823 118.504 158.482C118.504 162.141 121.482 165.107 125.155 165.107H150.551C154.225 165.107 157.203 162.141 157.203 158.482C157.203 154.823 154.225 151.856 150.551 151.856Z'
          fill='#424244'
        />
        <path
          d='M86.2793 97.4777C91.9565 97.4777 96.5587 92.8936 96.5587 87.2389C96.5587 81.5841 91.9565 77 86.2793 77C80.6022 77 76 81.5841 76 87.2389C76 92.8936 80.6022 97.4777 86.2793 97.4777Z'
          fill='#424244'
        />
      </g>
      <defs>
        <clipPath id='clip0_5334_203089'>
          <rect width='242' height='235' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function FundWalletMobileIcon() {
  return (
    <svg
      width='312'
      height='128'
      viewBox='0 0 312 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='312' height='128' rx='12' fill={styles.background} />
      <circle
        cx='63.9987'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M68.9987 57.8623C68.9987 57.8623 68.2209 54.8328 65.1098 54.832C61.9729 54.8313 60.4431 56.3472 60.4431 59.3775V63.1654M68.9987 71.4987H59.2566C59.122 71.4987 59.0476 71.3276 59.133 71.2236C59.5713 70.6892 60.4431 69.4123 60.4431 67.7108V63.1654M60.4431 63.1654H65.8876M60.4431 63.1654H57.332'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='87.9987'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M92.9205 59.0771C92.3964 57.2269 89.9205 56.1081 87.999 56.1227M87.999 56.1227C85.5384 56.1413 82.4647 58.6622 83.0796 61.0468C84.0964 64.99 91.9038 63.0133 92.9205 66.9558C93.5354 69.3404 90.4597 71.8616 87.999 71.8802M87.999 56.1227L87.9204 53.168M87.999 71.8802C86.0776 71.8948 83.6036 70.7757 83.0796 68.9255M87.999 71.8802V74.8346'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='111.999'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M117.832 58.1667C116.678 57.0129 115.208 56.2272 113.608 55.9089C112.008 55.5906 110.349 55.754 108.842 56.3784C107.334 57.0028 106.046 58.0601 105.139 59.4168C104.233 60.7734 103.749 62.3684 103.749 64C103.749 65.6316 104.233 67.2266 105.139 68.5832C106.046 69.9399 107.334 70.9972 108.842 71.6216C110.349 72.246 112.008 72.4094 113.608 72.0911C115.208 71.7728 116.678 70.9871 117.832 69.8333'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M101.102 66.1992H112.988'
        stroke={styles.text}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M101.102 61.2461H112.988'
        stroke={styles.text}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M159.332 56.6654H182.665M182.665 56.6654L176.832 50.832M182.665 56.6654L176.832 62.4987'
        stroke={styles.primary}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M169.332 72.3333H192.665M192.665 72.3333L186.832 66.5M192.665 72.3333L186.832 78.1667'
        stroke={styles.primary}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M232 54H258C260.209 54 262 55.7909 262 58V76C262 78.2091 260.209 80 258 80H230C227.791 80 226 78.2091 226 76V52C226 49.7909 227.791 48 230 48H256'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path d='M255 63V69' stroke={styles.text} strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}
