import { IconProps } from '../IconProps'

export const UKFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 78 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_394_2238)'>
        <path
          d='M39 79C60.5391 79 78 61.3153 78 39.5C78 17.6848 60.5391 0 39 0C17.4609 0 0 17.6848 0 39.5C0 61.3153 17.4609 79 39 79Z'
          fill='#F0F0F0'
        />
        <path
          d='M8.06202 15.4516C4.99854 19.4885 2.68855 24.1413 1.34351 29.1959H21.6323L8.06202 15.4516Z'
          fill='#0052B4'
        />
        <path
          d='M76.6565 29.1959C75.3114 24.1414 73.0013 19.4886 69.9379 15.4517L56.3679 29.1959H76.6565Z'
          fill='#0052B4'
        />
        <path
          d='M1.34351 49.8046C2.6887 54.859 4.99869 59.5118 8.06202 63.5486L21.6319 49.8046H1.34351Z'
          fill='#0052B4'
        />
        <path
          d='M62.744 8.16552C58.7582 5.06276 54.1645 2.72316 49.1738 1.36072V21.9095L62.744 8.16552Z'
          fill='#0052B4'
        />
        <path
          d='M15.2561 70.8344C19.2419 73.9372 23.8358 76.2768 28.8263 77.6392V57.0906L15.2561 70.8344Z'
          fill='#0052B4'
        />
        <path
          d='M28.8261 1.36072C23.8356 2.72316 19.2417 5.06276 15.2561 8.16536L28.8261 21.9094V1.36072Z'
          fill='#0052B4'
        />
        <path
          d='M49.1741 77.6392C54.1645 76.2768 58.7585 73.9372 62.7441 70.8346L49.1741 57.0906V77.6392Z'
          fill='#0052B4'
        />
        <path
          d='M56.3679 49.8046L69.9379 63.5487C73.0013 59.512 75.3114 54.859 76.6565 49.8046H56.3679Z'
          fill='#0052B4'
        />
        <path
          d='M77.6699 34.3479H44.0872H44.0871V0.334361C42.4218 0.114797 40.7241 0 39 0C37.2756 0 35.5782 0.114797 33.9131 0.334361V34.3476V34.3477H0.330129C0.113344 36.0343 0 37.7538 0 39.5C0 41.2465 0.113344 42.9657 0.330129 44.6521H33.9128H33.9129V78.6656C35.5782 78.8852 37.2756 79 39 79C40.7241 79 42.4218 78.8854 44.0869 78.6656V44.6524V44.6523H77.6699C77.8867 42.9657 78 41.2465 78 39.5C78 37.7538 77.8867 36.0343 77.6699 34.3479Z'
          fill='#D80027'
        />
        <path
          d='M49.1741 49.8045L66.5772 67.4308C67.3776 66.6204 68.1412 65.7732 68.8697 64.8949L53.9702 49.8044H49.1741V49.8045Z'
          fill='#D80027'
        />
        <path
          d='M28.8261 49.8046H28.8258L11.4229 67.4307C12.223 68.2413 13.0595 69.0147 13.9266 69.7525L28.8261 54.6617V49.8046Z'
          fill='#D80027'
        />
        <path
          d='M28.826 29.1959V29.1956L11.4228 11.5692C10.6224 12.3795 9.85888 13.2268 9.13037 14.105L24.03 29.1957H28.826V29.1959Z'
          fill='#D80027'
        />
        <path
          d='M49.1741 29.1959L66.5774 11.5693C65.7773 10.7586 64.9407 9.98531 64.0736 9.24762L49.1741 24.3383V29.1959Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_2238'>
          <rect width='78' height='79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
