import { useEffect, useState } from 'react'

export enum ScrollToIds {
  IBSignupHeader = 'ib-signup-header',
  IBProfileHeader = 'ib-profile-header',
  IBSignupContainer = 'ib-signup-container',
  ToolsPlatformsHeader = 'tools-platforms-header',
  ToolsPlatformsOSHeader = 'tools-platforms-os-header',
  IBClientsHeader = 'ib-clients-header',
  IBContestHeader = 'ib-contest-header',
  IBContestResultsHeader = 'ib-contest-results-header',
  IBIncomeHeader = 'ib-income-header',
  IBMultitierNetworkHeader = 'ib-multitier-network-header',
  IBMultitierSubAccountsHeader = 'ib-multitier-sub-accounts-header',
  IBTransactionHistoryHeader = 'ib-transaction-history-header',
  IBLegalDocumentsHeader = 'ib-legal-documents-header',
  IBReferralMaterialsHeader = 'ib-referral-materials-header',
  IBWalletsHeader = 'ib-wallets-header',
  NotificationCenterHeader = 'notification-center-header',
  PaymentAgentTransactionHistoryHeader = 'payment-agent-transaction-history-header',
  UploadedDocumentsHeader = 'uploaded-documents-header',
  SubscriptionHistoryETDHeader = 'subscription-history-etd-header',
  TradingAccountBalancesHeader = 'trading-account-balances-header',
  CampaignResultsHeader = 'campaign-results-header',
  CampaignTotalRebateHeader = 'campaign-total-rebate-header',
  StockDividendsHeader = 'stock-dividends-header',
  LiveTradingAccountsHeader = 'live-trading-accounts-header',
  DemoTradingAccountsHeader = 'demo-trading-accounts-header',
  InactiveLiveTradingAccountsHeader = 'inactive-live-trading-accounts-header',
  InactiveDemoTradingAccountsHeader = 'inactive-demo-trading-accounts-header',
  TradersRoomTransactionHistoryHeader = 'traders-room-transaction-history-header',
  BalancesWalletsHeader = 'balances-wallets-header',
  LiveTradingAccountsItemLast = 'live-trading-accounts-item-last',
  LiveTradingAccountsItemMiddle = 'live-trading-accounts-item-middle',
  DemoTradingAccountsItemLast = 'demo-trading-accounts-item-last',
  DemoTradingAccountsItemMiddle = 'demo-trading-accounts-item-middle',
  InactiveLiveTradingAccountsItemLast = 'inactive-live-trading-accounts-item-last',
  InactiveLiveTradingAccountsItemMiddle = 'inactive-live-trading-accounts-item-middle',
  InactiveDemoTradingAccountsItemLast = 'inactive-demo-trading-accounts-item-last',
  InactiveDemoTradingAccountsItemMiddle = 'inactive-demo-trading-accounts-item-middle',
  IBClientsItemMiddle = 'ib-clients-item-middle',
  IBContestItemMiddle = 'ib-contest-item-middle',
  IBContestResultsItemMiddle = 'ib-contest-results-item-middle',
  IBIncomeItemMiddle = 'ib-income-item-middle',
  IBMultitierNetworkItemMiddle = 'ib-multitier-network-item-middle',
  IBMultitierSubAccountsItemMiddle = 'ib-multitier-sub-accounts-item-middle',
  IBTransactionHistoryItemMiddle = 'ib-transaction-history-item-middle',
  NotificationCenterItemMiddle = 'notification-center-item-middle',
  PaymentAgentTransactionHistoryItemMiddle = 'payment-agent-transaction-history-item-middle',
  UploadedDocumentsItemMiddle = 'uploaded-documents-item-middle',
  SubscriptionHistoryETDItemMiddle = 'subscription-history-etd-item-middle',
  TradingAccountBalancesItemMiddle = 'trading-account-balances-item-middle',
  CampaignResultsItemMiddle = 'campaign-results-item-middle',
  CampaignTotalRebateItemMiddle = 'campaign-total-rebate-item-middle',
  StockDividendsItemMiddle = 'stock-dividends-item-middle',
  TransactionHistoryItemMiddle = 'transaction-history-item-middle',
  DashboardTabs = 'dashboard-tabs',
  MobileTabs = 'mobile-tabs',
  WalletsHeader = 'wallets-header',
  SubscriptionsMarketDataHeader = 'subscriptions-market-data-header',
  SubscriptionsTradingPlatformsHeader = 'subscriptions-trading-platforms-header',
  SubscriptionsSidebarWrapper = 'subscriptions-sidebar-wrapper',
  ProfileBankAccountsHeader = 'profile-bank-accounts-header',
  ProfilePhoneNumbersHeader = 'profile-phone-numbers-header',
  LoyaltyProgramHeader = 'loyalty-program-header',
  TradingInfoExperience = 'trading-info-experience-header',
  ReferralMaterialsHeader = 'referral-materials-header',
  StatementsETDHeader = 'statements-etd-header',
}

export const useScrollToElementIds = (): {
  scrollIntoView: (
    elementIds: ScrollToIds[] | string[],
    scrollOptions?: {
      skipElementsViewportVisibility?: boolean
      waitBeforeScrolling?: boolean
      offset?: number
    }
  ) => void
  useScrollIntoViewEffect: (elementIds: ScrollToIds[] | string[]) => void
} => {
  const scrollIntoView = (
    elementIds: ScrollToIds[] | string[],
    scrollOptions: {
      skipElementsViewportVisibility?: boolean
      waitBeforeScrolling?: boolean
      offset?: number
    } = {
      skipElementsViewportVisibility: false,
      waitBeforeScrolling: true,
      offset: 0,
    }
  ) => {
    return new Promise<void>((resolve) => {
      elementIds.some((elementId) => {
        const element = document.getElementById(elementId)
        if (!element) {
          resolve()
          return false
        }

        setTimeout(
          () => {
            const rect = element.getBoundingClientRect()

            const scrollElement = () => {
              if (scrollOptions.offset) {
                window.scrollBy({
                  top: rect.top - scrollOptions.offset,
                  behavior: 'smooth',
                })
              } else {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }

              // Create an IntersectionObserver to detect when the element is fully in view
              // Use case when scroll completion required first, before doing some next action
              const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    observer.disconnect()
                    resolve()
                  }
                })
              })

              observer.observe(element)

              // Fallback in case IntersectionObserver doesn't fire
              setTimeout(() => {
                observer.disconnect()
                resolve()
              }, 1000)
            }

            if (scrollOptions.skipElementsViewportVisibility) {
              scrollElement()
              return true
            }

            if (rect.top < 0 || rect.bottom > document.documentElement.clientHeight) {
              scrollElement()
            } else {
              resolve()
            }
            return true
          },
          scrollOptions.waitBeforeScrolling ? 300 : 0
        )
      })
    })
  }

  const useScrollIntoViewEffect = (elementIds: ScrollToIds[] | string[]) => {
    const stringifiedIds = JSON.stringify(elementIds)

    useEffect(() => {
      if (stringifiedIds) {
        scrollIntoView(JSON.parse(stringifiedIds) as ScrollToIds[])
      }
    }, [stringifiedIds])
  }

  return { scrollIntoView, useScrollIntoViewEffect }
}

export const useScrollIntoViewOnPagingEntriesChange = (
  id: string,
  isPaginationEntrySelected: boolean,
  isLoading: boolean,
  setIsPaginationEntrySelected: (value: boolean) => void
): void => {
  const { scrollIntoView } = useScrollToElementIds()
  useEffect(() => {
    if (isPaginationEntrySelected && !isLoading) {
      scrollIntoView([id], {
        waitBeforeScrolling: false,
        skipElementsViewportVisibility: true,
      })
      setIsPaginationEntrySelected(false)
    }
  }, [isLoading, scrollIntoView, isPaginationEntrySelected, id, setIsPaginationEntrySelected])
}

// Scroll user to an id on loading completion
// e.g table load on page change in pagination
export const useScrollAfterLoad = (
  scrollToId: string,
  isLoading: boolean,
  pageSize = 10,
  forceScroll?: boolean
): void => {
  const { scrollIntoView } = useScrollToElementIds()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const performScroll = () => {
    scrollIntoView([scrollToId], {
      waitBeforeScrolling: false,
      offset: 15,
      skipElementsViewportVisibility: true,
    })
  }

  useEffect(
    () => {
      if (isLoading) {
        return
      }
      if (forceScroll) {
        performScroll()
        return
      }

      if (isInitialLoad) {
        setIsInitialLoad(false)
        return
      }
      if (pageSize === currentPageSize) {
        performScroll()
      } else {
        setCurrentPageSize(pageSize)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  )
}
