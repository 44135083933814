import { useCallback, useEffect, useState } from 'react'

import { isAccountActivatedStatus } from '../../../../../model/AccountDetailedStatus'
import { PaymentProvider } from '../../../../../model/PaymentProviderDto'
import { useAccountReadContext } from '../../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { Wallet } from '../WalletDepositForm'

export interface GetDepositCurrencyLimitsValue {
  minAmount: number
  maxAmount: number
}

export type GetDepositCurrencyLimits = (
  wallet: Wallet,
  paymentProvider: PaymentProvider
) => Promise<GetDepositCurrencyLimitsValue>

interface UseGetDepositCurrencyLimitsValue {
  getDepositCurrencyLimits: GetDepositCurrencyLimits
  depositCurrencyLimits?: GetDepositCurrencyLimitsValue
}

interface UseGetDepositCurrencyLimitsProps {
  wallet?: Wallet
  paymentProvider?: PaymentProvider
}

const isWalletCurrencyDifferentFromProviderCurrency = (
  wallet: Wallet,
  paymentProvider: PaymentProvider
): boolean => {
  return (
    !!wallet?.currency?.id &&
    !!paymentProvider?.currency?.id &&
    wallet.currency.id !== paymentProvider.currency.id
  )
}

export const useGetDepositCurrencyLimits = (
  props: UseGetDepositCurrencyLimitsProps = {}
): UseGetDepositCurrencyLimitsValue => {
  const { wallet, paymentProvider } = props

  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const [depositCurrencyLimits, setDepositCurrencyLimits] =
    useState<GetDepositCurrencyLimitsValue>()

  const [conversionRate, setConversionRate] = useState(1)

  const currencyLimits = paymentProvider?.parameters?.currenciesLimits?.find(
    (limit) =>
      limit.walletType?.id === wallet?.walletType?.id && limit.id === paymentProvider.currency?.id
  )

  const getDepositCurrencyLimits: GetDepositCurrencyLimits = useCallback(
    async (
      wallet: Wallet,
      paymentProvider: PaymentProvider
    ): Promise<GetDepositCurrencyLimitsValue> => {
      const currencyLimits = paymentProvider?.parameters?.currenciesLimits?.find(
        (limit) =>
          limit.walletType?.id === wallet?.walletType?.id &&
          limit.id === paymentProvider.currency?.id
      )

      const isActivated = isAccountActivatedStatus(account)
      const maxAmount = isActivated
        ? currencyLimits?.maxAmount
        : wallet?.depositAction?.availableToDeposit

      const minAmount = currencyLimits?.minAmount || 0

      if (isWalletCurrencyDifferentFromProviderCurrency(wallet, paymentProvider)) {
        return {
          minAmount,
          maxAmount: (maxAmount || 0) * conversionRate,
        }
      }

      return {
        minAmount,
        maxAmount: maxAmount || 0,
      }
    },
    [account, apiClient]
  )

  useEffect(() => {
    if (!account?.id || !wallet?.currency?.id || !paymentProvider?.currency?.id) {
      return
    }

    const fetchRate = async () => {
      const { rate } = await apiClient.getExchangeRate({
        BaseCcy: wallet?.currency.id,
        QuoteCcy: paymentProvider?.currency.id,
        BaseWalletTypeId: wallet?.walletType?.id,
        QuoteWalletTypeId: currencyLimits?.walletType?.id,
        direction: 'sell',
      })

      return rate
    }
    const updateConversionRate = async () => {
      if (isWalletCurrencyDifferentFromProviderCurrency(wallet, paymentProvider)) {
        try {
          const rate = await fetchRate()
          if (rate !== null) {
            setConversionRate(rate)
          }
        } catch (error: unknown) {
          console.log('Error fetching conversion rate.', error)
        }
      }
    }
    updateConversionRate()

    const fetchDepositCurrencyLimits = async () => {
      try {
        const limits = await getDepositCurrencyLimits(wallet, paymentProvider)
        setDepositCurrencyLimits(limits)
      } catch (error: unknown) {
        console.error('Failed to fetch deposit currency limits:', error)
      }
    }

    fetchDepositCurrencyLimits()
  }, [account?.id, wallet, paymentProvider, getDepositCurrencyLimits])

  return { getDepositCurrencyLimits, depositCurrencyLimits }
}
