import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TMGrayLogo } from '../../../icons/Logos/TMGrayLogo'
import { TransactionHistoryEmptyState } from '../../../icons/TransactionHistoryEmptyState'
import { TextLargeStrong } from '../../../ui/Typography/Typography'

import styles from './EmptyTransactionHistory.module.scss'

export const EmptyTransactionHistory: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.emptyTransactionHistory}>
      <TransactionHistoryEmptyState />

      <div className={styles.infoWrapper}>
        <TMGrayLogo />

        <TextLargeStrong>{t('IB.Dashboard.Your transactions will appear here')}</TextLargeStrong>
      </div>
    </div>
  )
}
