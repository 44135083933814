export function TickmillAppRatingIcon() {
  return (
    <svg width='50' height='10' viewBox='0 0 50 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.40402 0.328219C4.43207 0.232308 4.56793 0.232309 4.59598 0.328219L5.48928 3.38298C5.50175 3.42562 5.54084 3.45492 5.58526 3.45492H8.49668C8.59099 3.45492 8.63287 3.57349 8.55949 3.63273L6.1866 5.54829C6.15519 5.57365 6.1421 5.61541 6.15343 5.65417L7.05543 8.73869C7.08293 8.8327 6.97286 8.90609 6.89664 8.84456L4.56281 6.96054C4.52616 6.93095 4.47384 6.93095 4.43719 6.96054L2.10336 8.84456C2.02714 8.90609 1.91707 8.8327 1.94457 8.73869L2.84657 5.65417C2.8579 5.61541 2.84481 5.57365 2.8134 5.54829L0.440507 3.63273C0.367127 3.57349 0.409014 3.45492 0.503321 3.45492H3.41474C3.45916 3.45492 3.49826 3.42562 3.51072 3.38298L4.40402 0.328219Z'
        fill='#F1A33B'
      />
      <path
        d='M14.404 0.328219C14.4321 0.232308 14.5679 0.232309 14.596 0.328219L15.4893 3.38298C15.5017 3.42562 15.5408 3.45492 15.5853 3.45492H18.4967C18.591 3.45492 18.6329 3.57349 18.5595 3.63273L16.1866 5.54829C16.1552 5.57365 16.1421 5.61541 16.1534 5.65417L17.0554 8.73869C17.0829 8.8327 16.9729 8.90609 16.8966 8.84456L14.5628 6.96054C14.5262 6.93095 14.4738 6.93095 14.4372 6.96054L12.1034 8.84456C12.0271 8.90609 11.9171 8.8327 11.9446 8.73869L12.8466 5.65417C12.8579 5.61541 12.8448 5.57365 12.8134 5.54829L10.4405 3.63273C10.3671 3.57349 10.409 3.45492 10.5033 3.45492H13.4147C13.4592 3.45492 13.4983 3.42562 13.5107 3.38298L14.404 0.328219Z'
        fill='#F1A33B'
      />
      <path
        d='M24.404 0.328219C24.4321 0.232308 24.5679 0.232309 24.596 0.328219L25.4893 3.38298C25.5017 3.42562 25.5408 3.45492 25.5853 3.45492H28.4967C28.591 3.45492 28.6329 3.57349 28.5595 3.63273L26.1866 5.54829C26.1552 5.57365 26.1421 5.61541 26.1534 5.65417L27.0554 8.73869C27.0829 8.8327 26.9729 8.90609 26.8966 8.84456L24.5628 6.96054C24.5262 6.93095 24.4738 6.93095 24.4372 6.96054L22.1034 8.84456C22.0271 8.90609 21.9171 8.8327 21.9446 8.73869L22.8466 5.65417C22.8579 5.61541 22.8448 5.57365 22.8134 5.54829L20.4405 3.63273C20.3671 3.57349 20.409 3.45492 20.5033 3.45492H23.4147C23.4592 3.45492 23.4983 3.42562 23.5107 3.38298L24.404 0.328219Z'
        fill='#F1A33B'
      />
      <path
        d='M34.404 0.328219C34.4321 0.232308 34.5679 0.232309 34.596 0.328219L35.4893 3.38298C35.5017 3.42562 35.5408 3.45492 35.5853 3.45492H38.4967C38.591 3.45492 38.6329 3.57349 38.5595 3.63273L36.1866 5.54829C36.1552 5.57365 36.1421 5.61541 36.1534 5.65417L37.0554 8.73869C37.0829 8.8327 36.9729 8.90609 36.8966 8.84456L34.5628 6.96054C34.5262 6.93095 34.4738 6.93095 34.4372 6.96054L32.1034 8.84456C32.0271 8.90609 31.9171 8.8327 31.9446 8.73869L32.8466 5.65417C32.8579 5.61541 32.8448 5.57365 32.8134 5.54829L30.4405 3.63273C30.3671 3.57349 30.409 3.45492 30.5033 3.45492H33.4147C33.4592 3.45492 33.4983 3.42562 33.5107 3.38298L34.404 0.328219Z'
        fill='#F1A33B'
      />
      <mask
        id='mask0_13503_112190'
        maskUnits='userSpaceOnUse'
        x='40'
        y='0'
        width='9'
        height='9'
        {...{ 'mask-type': 'alpha' }}
      >
        <path
          d='M44.404 0.328219C44.4321 0.232308 44.5679 0.232309 44.596 0.328219L45.4893 3.38298C45.5017 3.42562 45.5408 3.45492 45.5853 3.45492H48.4967C48.591 3.45492 48.6329 3.57349 48.5595 3.63273L46.1866 5.54829C46.1552 5.57365 46.1421 5.61541 46.1534 5.65417L47.0554 8.73869C47.0829 8.8327 46.9729 8.90609 46.8966 8.84456L44.5628 6.96054C44.5262 6.93095 44.4738 6.93095 44.4372 6.96054L42.1034 8.84456C42.0271 8.90609 41.9171 8.8327 41.9446 8.73869L42.8466 5.65417C42.8579 5.61541 42.8448 5.57365 42.8134 5.54829L40.4405 3.63273C40.3671 3.57349 40.409 3.45492 40.5033 3.45492H43.4147C43.4592 3.45492 43.4983 3.42562 43.5107 3.38298L44.404 0.328219Z'
          fill='#C4C4C4'
        />
      </mask>
      <g mask='url(#mask0_13503_112190)'>
        <rect x='39.6641' y='0.5' width='5' height='10' fill='#F1A33B' />
      </g>
      <path
        d='M45.5853 3.70492H48.0721L46.0296 5.35376C45.9196 5.44252 45.8738 5.5887 45.9135 5.72433L46.1534 5.65417L45.9135 5.72433L46.6811 8.34926L44.7198 6.76601C44.5916 6.66246 44.4084 6.66246 44.2801 6.76601L42.3189 8.34926L43.0865 5.72433C43.1262 5.5887 43.0804 5.44253 42.9704 5.35376L40.9279 3.70492H43.4147C43.5702 3.70492 43.707 3.60237 43.7507 3.45315L44.5 0.890716L45.2493 3.45315C45.293 3.60237 45.4298 3.70492 45.5853 3.70492Z'
        stroke='#F1A33B'
        strokeWidth='0.5'
      />
    </svg>
  )
}
