import { IconProps, iconColor } from './IconProps'

export function NavigationArrowRightIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M9 5L16 12L9 19'
        stroke={iconColor(props)}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
