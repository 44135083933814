import React, { ChangeEvent, FormEventHandler, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Field, Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { useReferral } from '../../../../../Referral/Referral'
import { useLinksConfig } from '../../../../../configs/configs'
import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { Chip } from '../../../../../global/chip/Chip'
import { useSessionEntity } from '../../../../../global/context/EntityContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../../../global/formField/FormField'
import IconButton from '../../../../../global/iconButton/IconButton'
import { CountrySelectModal } from '../../../../../global/modal/CountrySelectModal'
import { InfoModal } from '../../../../../global/modal/InfoModal'
import { Modal } from '../../../../../global/modal/Modal'
import { RadioButton } from '../../../../../global/radioButton/RadioButton'
import { useMixedText } from '../../../../../hooks/useMixedText'
import { BackIcon } from '../../../../../icons/BackIcon'
import { ChevronDownIcon } from '../../../../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../../../../icons/ChevronUpIcon'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { InfoIcon } from '../../../../../icons/InfoIcon'
import { TruliooEIdData } from '../../../../../model/CountryDto'
import { LeadDto } from '../../../../../model/LeadDto'
import { NameDto } from '../../../../../model/NameDto'
import { InfoCard } from '../../../../../ui/Popups/InfoCard/InfoCard'
import { Text, TextH3, TextTiny } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import {
  TickmillCompaniesEnum,
  isEitherASTypeOrSCType,
  isTickmillEUType,
  isTickmillUKType,
} from '../../../../../utils/companyName.utils'
import {
  AddressLettersPattern,
  CityStatePattern,
  IBPattern,
  ZipCodePattern,
} from '../../../../../utils/formValidation'

import styles from './PersonalDetailsStep4Form.module.scss'

const FormField = createFormField<PersonalDetailsStep4FormValues>()

export interface PersonalDetailsStep4FormValues {
  nationalities: NameDto<string>[]
  address: string
  primaryAddressPostalCode: string
  primaryAddressCity: string
  primaryAddressState: string
  taxId: string
  mainIbCode: string
  affiliateToken: string
  taxIdNotAvailable: boolean
  // streetHouseNumberNotAvailable: boolean
  // streetHouseNumber: string
  isPoliticallyExposedPerson: boolean
  taxIdUnavailableReasonId?: number
  nationalityId: string
  personalId?: string
  truliooCountryData: TruliooEIdData[]
  selectedTruliooCountry?: number
  personalIdTypeId: string | null
}

interface OuterProps {
  lead?: LeadDto
  initialValues: PersonalDetailsStep4FormValues | undefined
  nationalities: NameDto<string>[]
  taxReasons: NameDto[]
  entity: TickmillCompaniesEnum
  country?: NameDto<string>

  onSubmit(values: PersonalDetailsStep4FormValues): void
  requireTaxId: boolean
}

type PersonalDetailsStep4FormProps = FormikProps<PersonalDetailsStep4FormValues> & OuterProps

const PersonalDetailsStep4FormUI: React.FC<PersonalDetailsStep4FormProps> = (props) => {
  const { isSubmitting, lead } = props
  const {
    errors,
    nationalities,
    initialValues,
    handleSubmit,
    setFieldValue,
    isValid,
    setFieldTouched,
    values,
    validateForm,
    requireTaxId,
    taxReasons,
  } = props
  const { truliooCountryData, selectedTruliooCountry } = values
  const locale = useSessionLanguage()
  const { t } = useTranslation()

  const { parseMixedText } = useMixedText()

  const apiClient = useApiClient(ClientApiClient)

  const { links } = useLinksConfig()

  const entity = useSessionEntity()

  const referral = useReferral()

  const isASOrSC = useMemo(() => isEitherASTypeOrSCType(entity), [entity])
  const isEUOrUK = useMemo(() => isTickmillEUType(entity) || isTickmillUKType(entity), [entity])

  const [isLoading, setIsLoading] = useState(false)
  const [personalIdLoading, setPersonalIdLoading] = useState(false)
  const [ibCodeError, setIBCodeError] = useState<string>()
  const [hasIBCode, setHasIBCode] = useState(false)
  const [isNationalityModalOpen, setIsNationalityModelOpen] = useState(false)

  const [modalVisible, setModalVisible] = useState<{
    type?: 'tin'
    visible: boolean
  }>({ visible: false })
  const { id: leadCountryId } = lead?.country ?? { id: '' }
  const [referralCodeCollapsed, setReferralCodeCollapsed] = useState(false)

  const onRemoveNation = (nation: NameDto<string>) => {
    const newNations = values.nationalities.filter((x) => x.id !== nation.id)
    setFieldValue('nationalities', newNations)
    if (!newNations.length) {
      resetFieldValues()
    } else {
      updateFieldValues(newNations)
    }
  }

  const updateFieldValues = (newNations: NameDto<string>[]) => {
    if (newNations.some((nation) => nation.id === lead?.country.id)) {
      getTruliooCountryData(lead?.country.id)
    } else {
      resetFieldValues()
      const newNationality = newNations[0].id
      setFieldValue('nationalityId', newNationality)
    }
  }

  const resetFieldValues = () => {
    setFieldValue('nationalityId', '')
    setFieldValue('selectedTruliooCountry', undefined)
    setFieldValue('truliooCountryData', [])
    setFieldValue('personalId', '')
    removeHasAdditionalId()
  }

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault()
    validateForm()
    handleSubmit()
  }

  const onChangeTaxAvailability = (checked: boolean) => {
    setFieldValue('taxIdNotAvailable', checked)
    if (checked) {
      setFieldValue('taxId', '')
    }
  }

  const onChangeStreetAvailability = (checked: boolean) => {
    setFieldValue('streetHouseNumberNotAvailable', checked)
    if (checked) {
      setFieldValue('streetHouseNumber', '')
      // setFieldTouched('streetHouseNumber', false, false)
    }
  }

  const removeHasAdditionalId = () => localStorage.removeItem('hasAdditionalId')

  const onAddNationality = async (nationId: string) => {
    removeHasAdditionalId()
    const nationality = nationalities.find((x) => x.id === nationId)

    if (nationality && values.nationalities.length < 3) {
      let nations: NameDto<string>[]

      if (lead?.country.id === nationId) {
        nations = [
          nationality,
          ...new Set(
            ([] as NameDto<string>[]).concat(
              ...values.nationalities.filter((n) => n.id !== nationId)
            )
          ),
        ]
        setFieldValue('personalId', '')
        getTruliooCountryData(lead.country.id)
      } else if (!values.nationalities.length) {
        nations = [nationality]
      } else {
        nations = [
          ...new Set(
            ([] as NameDto<string>[]).concat(
              ...values.nationalities.filter((n) => n.id !== nationId),
              nationality
            )
          ),
        ]
      }

      await setFieldValue('nationalities', nations)
      await setFieldValue('nationalityId', nations[0].id)

      await validateForm()
      // If the selected nationality is not the lead country, fetch truliooCountryData here
      if (leadCountryId !== nationId) {
        getTruliooCountryData(nations[0].id)
      }
    }
    setIsNationalityModelOpen(false)
  }

  useEffect(() => {
    if (!isASOrSC && !isEUOrUK) {
      return
    }

    let delayDebounceFn: NodeJS.Timeout
    if (values.mainIbCode && !errors.mainIbCode) {
      delayDebounceFn = setTimeout(() => {
        setIsLoading(true)
        apiClient
          .validateIntroducingBrokerByCode(values.mainIbCode, entity)
          .then(() => {
            setIBCodeError('')
            setHasIBCode(true)
          })
          .catch(() => {
            setHasIBCode(false)

            if (isEUOrUK) {
              setFieldValue('mainIbCode', '')
              return
            }

            setIBCodeError(t('Referral Code does not exist.'))
            setReferralCodeCollapsed(true)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }, 1000)
    }
    if (!values.mainIbCode) {
      setIBCodeError('')
    }
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.mainIbCode, errors.mainIbCode])

  const getTruliooCountryData = async (nationalityId?: string) => {
    try {
      setPersonalIdLoading(true)

      if (!isASOrSC || !lead?.id) {
        return
      }

      setFieldValue('truliooCountryData', [])
      setFieldValue('selectedTruliooCountry', undefined)

      const data = await apiClient.getTruliooCountries(
        nationalityId || values.nationalityId,
        locale || 'en',
        lead?.id
      )

      if (data.length) {
        const countryToSelect = data[0].id

        setFieldValue('selectedTruliooCountry', countryToSelect)
        setFieldValue('truliooCountryData', data)
        setFieldValue('personalIdTypeId', countryToSelect)

        localStorage.setItem('hasAdditionalId', 'false')
      } else {
        localStorage.removeItem('hasAdditionalId')
      }
    } finally {
      setPersonalIdLoading(false)
    }
  }

  useEffect(() => {
    if (values.mainIbCode && isASOrSC) {
      setHasIBCode(true)
    } else {
      setReferralCodeCollapsed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (values.nationalityId) {
      getTruliooCountryData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const isArabic = useArabicSessionLanguage()

  const handleReferralCodeCollapse = () => setReferralCodeCollapsed((prvState) => !prvState)

  const getCountryName = (countryId?: string) => {
    const country = nationalities?.find((x) => x.id === countryId)
    if (country?.name) {
      return country.name
    }
    return ''
  }

  return (
    <React.Fragment>
      {isNationalityModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsNationalityModelOpen(false)}
          render={() => (
            <CountrySelectModal
              title={t('Sign up.Nationality')}
              options={nationalities}
              onCancel={() => setIsNationalityModelOpen(false)}
              handleOnChangeOption={(nation) => onAddNationality(nation.id)}
              selectedCountry={undefined}
            />
          )}
        />
      )}
      {modalVisible.visible && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setModalVisible({ visible: false })}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Sign up.Tax Identification Number (TIN)')}
              renderBody={() => {
                return (
                  <div className='modal-card-body'>
                    <Text
                      isParagraph
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "Sign up.Here we'll need your individual Tax Identification Number",
                          {
                            eu_link: links.TIN.EuropeanCommission,
                            worldwide_link: links.TIN.OECD,
                          }
                        ),
                      }}
                    />
                  </div>
                )
              }}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => {
                      setModalVisible({ visible: false })
                    }}
                    type='button'
                  >
                    <b>{t('Got It')}</b>
                  </button>
                </React.Fragment>
              )}
              onConfirm={() => setModalVisible({ visible: false })}
            />
          )}
        />
      )}
      <Form className={styles.form} onSubmit={onSubmitForm}>
        <TextH3>
          {t('Sign up.Nationality')}{' '}
          <InfoCard text={t('Sign up.Please confirm your nationality and place of birth.')}>
            <InfoIcon />
          </InfoCard>
        </TextH3>
        <div className='mb-2'>
          <IconButton onClick={() => setIsNationalityModelOpen(true)} fullWidth>
            <FormField
              label={t('Sign up.Nationalities')}
              name='nationalityId'
              placeholder={t('Sign up.Add nationality')}
              value={getCountryName(values.nationalityId).toString()}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              fullWidth
            />
          </IconButton>
        </div>
        <div className='is-flex'>
          {values.nationalities.map((n) => (
            <span key={n.id} className='mr-2'>
              <Chip text={n.name} appearance='dark' onDelete={() => onRemoveNation(n)} />
            </span>
          ))}
        </div>

        {!!truliooCountryData?.length && (
          <div className='mt-3'>
            {truliooCountryData.map((countryData) => (
              <div key={countryData.id}>
                {truliooCountryData?.length > 1 && (
                  <RadioButton
                    onClick={() => {
                      setFieldValue('personalId', undefined)
                      setFieldValue('personalIdTypeId', Number(countryData.id))
                      setFieldValue('selectedTruliooCountry', Number(countryData.id))
                    }}
                    checked={countryData.id === selectedTruliooCountry}
                    label={countryData.fieldName}
                  />
                )}
                {countryData.id === selectedTruliooCountry && (
                  <div className='mt-3 mb-3'>
                    <FormField
                      type='text'
                      required
                      name='personalId'
                      showLabel
                      wrapperClassname='mb-0'
                      placeholder={parseMixedText(countryData.placeholder)}
                      label={countryData.fieldName}
                      className={classNames({ [styles.personalId]: isArabic })}
                    />
                    {/* TODO: Wrap this with parseMixedText once Mobile team catches up (https://tickmill.atlassian.net/browse/WCA-2754)*/}
                    <TextTiny>{countryData.fieldLabel}</TextTiny>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <TextH3>{t('Sign up.Residential address')}</TextH3>
        <FormField
          type='text'
          required
          name='address'
          showLabel
          label={t('Sign up.Address Line 1')}
        />

        {/*<FormField*/}
        {/*  type='text'*/}
        {/*  required*/}
        {/*  name='streetHouseNumber'*/}
        {/*  showLabel*/}
        {/*  disabled={!!values.streetHouseNumberNotAvailable}*/}
        {/*  label={t('Sign up.Street Name, Street Number, Apt. Number')}*/}
        {/*/>*/}
        {/*<div className={'control pb-4 pt-1'}>*/}
        {/*  <Field*/}
        {/*    name='streetHouseNumberNotAvailable'*/}
        {/*    type='checkbox'*/}
        {/*    onChange={(e: ChangeEvent<HTMLInputElement>) =>*/}
        {/*      onChangeStreetAvailability(e.target.checked)*/}
        {/*    }*/}
        {/*  />*/}
        {/*  {t('Sign up.Not Available')}*/}
        {/*</div>*/}
        <FormField
          type='text'
          required
          name='primaryAddressCity'
          showLabel
          label={t('Sign up.City')}
        />
        <FormField
          type='text'
          required
          name='primaryAddressState'
          showLabel
          label={t('Sign up.State')}
        />
        <FormField
          type='text'
          required
          name='primaryAddressPostalCode'
          showLabel
          label={t('Sign up.ZIP / Postal code')}
        />
        {requireTaxId && (
          <>
            <TextH3 className={styles.tin}>
              {t('Sign up.Tax Identification Number (TIN)')}
              <button
                onClick={() => setModalVisible({ visible: true, type: 'tin' })}
                type='button'
                className={styles.plainButton}
              >
                <InfoIcon />
              </button>
            </TextH3>
            <FormField
              name='taxId'
              label={t('Sign up.Tax Identification Number (TIN)')}
              type='text'
              disabled={values.taxIdNotAvailable}
              required
              showLabel
            />
            <div className={classNames(styles.textSmall, 'control pb-2')}>
              <Field
                name='taxIdNotAvailable'
                type='checkbox'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeTaxAvailability(e.target.checked)
                }
              />
              {t('Sign up.Not Available')}
            </div>
            {values.taxIdNotAvailable &&
              taxReasons.map((type) => (
                <div key={type.id} className='field'>
                  <div className={classNames('control')}>
                    <Field
                      key={type.id}
                      className={classNames('radio')}
                      type='radio'
                      name='taxIdUnavailableReasonId'
                      checked={values.taxIdUnavailableReasonId === type.id}
                      required
                      onChange={() => setFieldValue('taxIdUnavailableReasonId', type.id)}
                    />
                    <label className={classNames(styles.textSmall, 'pl-2')}>{type.name}</label>
                  </div>
                </div>
              ))}
          </>
        )}
        <TextH3>
          {t('Sign up.Politically Exposed Person.Title')}{' '}
          <InfoCard
            text={t('Sign up.Please state if you are someone with a high profile political')}
          >
            <InfoIcon />
          </InfoCard>
        </TextH3>
        <div className={classNames(styles.textSmall, 'control')}>
          <Field type='checkbox' name='isPoliticallyExposedPerson' />
          {t("Sign up.I'm Politically Exposed Person")}
        </div>
        {isASOrSC && !referral.isAffiliatesParams() && (
          <React.Fragment>
            <div className='is-flex mb-3'>
              <span className='pt-5 pr-4' onClick={handleReferralCodeCollapse}>
                {referralCodeCollapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </span>
              <TextH3 className='is-unselectable'>
                <span onClick={handleReferralCodeCollapse}>{t('Sign up.Referral Code')}</span>{' '}
                <InfoCard
                  text={
                    <Text
                      isParagraph
                      dangerouslySetInnerHTML={{
                        __html: hasIBCode
                          ? t(
                              'Sign up.Referrals are agents who introduce new clients to Tickmill. The code has already been provided to you by your Referral. No further action is required'
                            )
                          : t(
                              'Sign up.Referrals are agents who introduce new clients to Tickmill.'
                            ) +
                            ' ' +
                            t(
                              'Sign up.Enter the code that was provided to you by your Referral. The code consists of a combination of letters and digits.'
                            ),
                      }}
                    />
                  }
                >
                  <InfoIcon />
                </InfoCard>
              </TextH3>
            </div>

            <FormField
              disabled={!!initialValues.mainIbCode && hasIBCode && !ibCodeError}
              type='text'
              placeholder={hasIBCode ? '' : `${t('Sign up.Enter IB Code')}...`}
              showLabel={false}
              required={false}
              name='mainIbCode'
              className={classNames({
                'is-hidden': !referralCodeCollapsed,
              })}
            />
            <div
              className={classNames('is-flex', {
                'is-justify-content-center': isLoading,
              })}
            >
              <Loading isLoading={isLoading} text={t('Validating')}>
                {!!ibCodeError && referralCodeCollapsed && (
                  <div className='help is-danger has-text-weight-bold'>{ibCodeError}</div>
                )}
              </Loading>
            </div>
          </React.Fragment>
        )}
        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          loading={isSubmitting}
          disabled={!!ibCodeError || personalIdLoading || !isValid}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export const PersonalDetailsStep4Form = withFormik<OuterProps, PersonalDetailsStep4FormValues>({
  mapPropsToValues: ({ initialValues }) => {
    return {
      nationalities: initialValues?.nationalities || [],
      primaryAddressCity: initialValues?.primaryAddressCity || '',
      primaryAddressState: initialValues?.primaryAddressState || '',
      address: initialValues?.address || '',
      primaryAddressPostalCode: initialValues?.primaryAddressPostalCode || '',
      taxId: initialValues?.taxId || '',
      mainIbCode: initialValues?.mainIbCode || '',
      affiliateToken: initialValues?.affiliateToken || '',
      taxIdNotAvailable: initialValues?.taxIdNotAvailable || false,
      isPoliticallyExposedPerson: initialValues?.isPoliticallyExposedPerson || false,
      nationalityId: initialValues?.nationalityId || '',
      personalId: initialValues?.personalId || '',
      truliooCountryData: initialValues?.truliooCountryData || [],
      personalIdTypeId: initialValues?.personalIdTypeId || null,
      // streetHouseNumberNotAvailable: initialValues?.streetHouseNumberNotAvailable ?? false,
      // streetHouseNumber: initialValues?.streetHouseNumber ?? '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { entity, requireTaxId, country }) => {
    const errors: FormikErrors<PersonalDetailsStep4FormValues> = {}
    if (values.truliooCountryData.length) {
      const truliooCountry = values.truliooCountryData.find(
        ({ id }) => id === values.selectedTruliooCountry
      )
      // the same as display, we want to validate personalId only when truliooCountry (nationality) is the same as registration country
      if (truliooCountry && country?.id === truliooCountry.country.id) {
        if (!values.personalId) {
          errors.personalId = t('Validation.Required')
        } else if (!new RegExp(truliooCountry?.validationRule).test(values.personalId)) {
          errors.personalId = t('Validation.Invalid format')
        }
      }
    }

    if (!values.nationalityId.length) {
      errors.nationalityId = t('Validation.Required')
    }

    if (!values.primaryAddressCity.trim()) {
      errors.primaryAddressCity = t('Validation.Required')
    }
    if (values.primaryAddressCity && !CityStatePattern.test(values.primaryAddressCity)) {
      errors.primaryAddressCity = t('Validation.Invalid city')
    }

    if (!values.address.trim()) {
      errors.address = t('Validation.Required')
    }
    if (requireTaxId && !values.taxId.trim() && !values.taxIdNotAvailable) {
      errors.taxId = t('Validation.Required')
    }
    if (values.address && !AddressLettersPattern.test(values.address)) {
      errors.address = t('Validation.Address Line contains unsupported characters')
    }
    if (requireTaxId && !values.taxId.trim() && !values.taxIdNotAvailable) {
      errors.taxId = t('Validation.Required')
    }
    if (!values.primaryAddressState.trim()) {
      errors.primaryAddressState = t('Validation.Required')
    }
    if (values.primaryAddressState && !CityStatePattern.test(values.primaryAddressState)) {
      errors.primaryAddressState = t('Validation.Invalid state')
    }

    if (!values.primaryAddressPostalCode.trim()) {
      errors.primaryAddressPostalCode = t('Validation.Required')
    }

    if (values.primaryAddressPostalCode.length > 32) {
      errors.primaryAddressPostalCode = t('Validation.Maximum character limit is 32')
    }
    if (values.primaryAddressPostalCode && !ZipCodePattern.test(values.primaryAddressPostalCode)) {
      errors.primaryAddressPostalCode = t('Validation.Invalid postal code')
    }

    if (
      entity === TickmillCompaniesEnum.TICKMILL_AS ||
      entity === TickmillCompaniesEnum.TICKMILL_SC
    ) {
      if (values.mainIbCode && values.mainIbCode.length <= 5) {
        errors.mainIbCode = t('Validation.At least characters', {
          length: 6,
        })
      }
      if (values.mainIbCode && !IBPattern.test(values.mainIbCode)) {
        errors.mainIbCode = t('Validation.IB code should begin with')
      }
    }

    if (values.taxIdNotAvailable && !values.taxIdUnavailableReasonId) {
      errors.taxIdUnavailableReasonId = t('Validation.Required')
    }

    // if (!values.streetHouseNumberNotAvailable) {
    //   if (!values.streetHouseNumber) {
    //     errors.streetHouseNumber = t('Validation.Required')
    //   }
    //   if (!EnglishLettersPattern.test(values.streetHouseNumber)) {
    //     errors.streetHouseNumber = t('Validation.Invalid street')
    //   }
    // }
    return errors
  },
  isInitialValid: false,
  enableReinitialize: false,
})(PersonalDetailsStep4FormUI)
