import { IconProps } from '../IconProps'

export const MalaysiaFlagIcon: React.FC<IconProps> = (props) => {
  const { className, size = 78 } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_394_1270)'>
        <path
          d='M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z'
          fill='#F0F0F0'
        />
        <path
          d='M37.3044 39H78C78 35.4798 77.5308 32.07 76.6565 28.826H37.3044V39Z'
          fill='#D80027'
        />
        <path
          d='M37.3044 18.6522H72.2759C69.8885 14.7564 66.8363 11.313 63.2771 8.47821H37.3044V18.6522Z'
          fill='#D80027'
        />
        <path
          d='M38.9999 78C48.1785 78 56.6148 74.8276 63.277 69.5217H14.7229C21.385 74.8276 29.8214 78 38.9999 78Z'
          fill='#D80027'
        />
        <path
          d='M5.72415 59.3478H72.2758C74.1925 56.2205 75.6787 52.8017 76.6565 49.1738H1.34351C2.3211 52.8017 3.80751 56.2205 5.72415 59.3478Z'
          fill='#D80027'
        />
        <path d='M39 39C39 17.461 39 14.9218 39 0C17.461 0 0 17.461 0 39H39Z' fill='#0052B4' />
        <path
          d='M25.934 33.3831C20.6078 33.3831 16.2901 29.0652 16.2901 23.7391C16.2901 18.413 20.608 14.0951 25.934 14.0951C27.5947 14.0951 29.1573 14.5151 30.5216 15.2543C28.3814 13.1616 25.4546 11.8697 22.2248 11.8697C15.6696 11.8697 10.3552 17.1839 10.3552 23.7392C10.3552 30.2946 15.6696 35.6088 22.2248 35.6088C25.4546 35.6088 28.3814 34.3169 30.5216 32.2242C29.1573 32.9631 27.5949 33.3831 25.934 33.3831Z'
          fill='#FFDA44'
        />
        <path
          d='M28.6518 16.9564L30.364 20.5368L34.2306 19.6435L32.4991 23.2142L35.6087 25.6802L31.7371 26.553L31.7479 30.5217L28.6518 28.0388L25.5556 30.5217L25.5662 26.553L21.6946 25.6802L24.8042 23.2142L23.0725 19.6435L26.9395 20.5368L28.6518 16.9564Z'
          fill='#FFDA44'
        />
      </g>
      <defs>
        <clipPath id='clip0_394_1270'>
          <rect width='78' height='78' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
