import { FC } from 'react'
import classNames from 'classnames'

import styles from './ProgressBar.module.scss'

export type Color = 'red' | 'blue'

export interface ProgressBarProps {
  color: Color
  percent: number
}

export const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { percent, color } = props

  return (
    <div className={styles.progressBar}>
      {percent > 0 && (
        <div
          className={classNames(styles.progressBarSection, styles[color])}
          style={{ flex: percent }}
        />
      )}
      {percent < 100 && (
        <div
          className={classNames(styles.progressBarSection, styles.isStroked)}
          style={{ flex: 100 - percent }}
        />
      )}
    </div>
  )
}
